/// Style
import './vendor/bootstrap-select/dist/css/bootstrap-select.min.css';
import './css/style.css';

import React, { Fragment } from 'react';

/// Components
import Markup from './jsx';
import { withResizeDetector } from 'react-resize-detector';

const App = ({ width }) => {
    const body = document.querySelector('body');
    body.setAttribute('data-typography', 'poppins');
    body.setAttribute('data-theme-version', 'light');
    body.setAttribute('data-layout', 'vertical');
    body.setAttribute('data-nav-headerbg', 'color_3');
    body.setAttribute('data-headerbg', 'color_1');
    body.setAttribute('data-sidebar-style', 'full');
    body.setAttribute('data-sibebarbg', 'color_3');
    body.setAttribute('data-primary', 'color_1');
    body.setAttribute('data-sidebar-position', 'fixed');
    body.setAttribute('data-header-position', 'fixed');
    body.setAttribute('data-container', 'wide');
    body.setAttribute('direction', 'ltr');

    width > 767 && width < 1299
        ? body.setAttribute('data-sidebar-style', 'mini')
        : width < 768
        ? body.setAttribute('data-sidebar-style', 'overlay')
        : body.setAttribute('data-sidebar-style', 'full');

    return (
        <Fragment>
            <Markup />
        </Fragment>
    );
};

export default withResizeDetector(App);
