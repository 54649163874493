import { Alert, Badge, Button, Card, Col, Media, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { getLastMonthSpend, getOneMonthSpend, getReview } from '../../api/api';
import { useEffect, useState } from 'react';

import { Crypto } from 'crypto-js';
import Rating from 'react-rating';
// import Gyoza from '../../../images/Gyoza_Gyoza.jpg';
import React from 'react';
import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';
import { useHistory } from 'react-router-dom';

const Anlaytics = () => {
    const [month, setMonth] = useState([]);
    const [lastMonth, setLastMonth] = useState([]);
    const [review, setReview] = useState([]);
    const [today, setToday] = useState([]);
    const user = JSON.parse(localStorage.getItem('current_user'));

    useEffect(() => {
        const getData = async () => {
            const lastMonthData = await getLastMonthSpend(user.access_token, user.resid);
            setLastMonth(lastMonthData.data);
        };
        getData();
    }, []);
    useEffect(() => {
        const getData = async () => {
            const monthData = await getOneMonthSpend(user.access_token, user.resid);
            setMonth(monthData.data);
        };
        getData();
    }, []);
    useEffect(() => {
        const getData = async () => {
            const reviewData = await getReview(user.access_token, user.resid, user.servicetype);
            if (Array.isArray(reviewData.data)) {
                setReview(reviewData.data);
            }
        };
        getData();
    }, []);
    function calculate(data) {
        var amount = 0;
        for (var i = 0; i < data.length; i++) {
            amount += data[i].amount;
        }
        return (amount / 100).toFixed(2);
    }

    function averageRating(item) {
        var average = 0;
        for (var i = 0; i < item.length; i++) {
            average = average + parseInt(item[i].rating);
        }
        return (average / item.length).toFixed();
    }
    const [store, setStore] = useState([]);
    const currentStore = JSON.parse(localStorage.getItem('current_store'));
    // setStore(currentStore);
    // useEffect(() => {
    //     if (user.servicetype === 0) {
    //         async function idGetRes() {
    //             const store = await IdgetRestaurant(user.access_token, user.resid);
    //             setStore(store);
    //         }
    //         idGetRes();
    //     }
    // }, []);

    // useEffect(() => {
    //     if (user.servicetype === 1) {
    //         async function idGetBeauty() {
    //             const store = await IdgetBeauty(user.access_token, user.resid);
    //             setStore(store);
    //         }
    //         idGetBeauty();
    //     }
    // }, []);

    // useEffect(() => {
    //     if (user.servicetype === 2) {
    //         async function idGetEnt() {
    //             const store = await IdgetEntertainment(user.access_token, user.resid);
    //             setStore(store);
    //         }
    //         idGetEnt();
    //     }
    // }, []);

    // useEffect(() => {
    //     if (user.servicetype === 3) {
    //         async function idGetBub() {
    //             const store = await IdgetBabbletea(user.access_token, user.resid);
    //             setStore(store);
    //         }
    //         idGetBub();
    //     }
    // }, []);

    return (
        <>
            {/* <div className="page-titles">
                <ol className="breadcrumb">
                  
                    <li className="breadcrumb-item active">
                        <Link to="/analytics">Statement</Link>
                    </li>
                </ol>
            </div> */}
            <div className="row">
                <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div>
                                    <div className="card-header border-0 pb-0 d-sm-flex d-block">
                                        <h4 className="card-title mb-1">Financial Statement</h4>
                                    </div>

                                    {/* <div className="card-body pt-0 p-0"> */}
                                    <div className="card-body pt-0 p-0 ">
                                        <div
                                            className="align-items-center row mx-0 p-4 border-bottom"
                                            // style={{ height: '350px' }}
                                        >
                                            {/* <div className="col-sm">
                                                <img
                                                    src={Gyoza}
                                                    alt=""
                                                    style={{
                                                        width: '300px',
                                                        height: '300px',
                                                    }}
                                                ></img>
                                            </div> */}

                                            <div className="col-sm">
                                                <h5 className="mt-8 mb-8">
                                                    Bussiness Name:
                                                    <span className="ml-2">
                                                        {currentStore.name}
                                                    </span>
                                                </h5>
                                                <h5 className="mt-8 mb-8">
                                                    Bussiness Address:
                                                    <span className="ml-2">
                                                        {currentStore.address1},{' '}
                                                        {currentStore.address2}
                                                    </span>
                                                </h5>
                                                <div className="mt-8 mb-8 justify-content-center align-items-center">
                                                    <h5>
                                                        Review:
                                                        <Link to="/reviews">
                                                            <Rating
                                                                initialRating={averageRating(
                                                                    review
                                                                )}
                                                                readonly
                                                                style={{
                                                                    marginLeft: '6px',
                                                                    fontSize: '8px',
                                                                }}
                                                                emptySymbol="fa fa-star-o fa-2x"
                                                                fullSymbol="fa fa-star fa-2x text-orange"
                                                                fractions={2}
                                                                // placeholderRating={5}
                                                            />
                                                        </Link>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="align-items-center row mx-0 border-bottom p-4">
                                            Total sales from Outz:
                                        </div> */}
                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                            <h3>This month sales from Outz:</h3>
                                            {Array.isArray(month) ? (
                                                <h3 className="ml-2">
                                                    {month.length === 0
                                                        ? ' $ 0'
                                                        : '$ ' + calculate(month)}
                                                </h3>
                                            ) : (
                                                <h3 className="ml-2">$ 0</h3>
                                            )}
                                        </div>
                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                            <h3>Last month sales from Outz: </h3>
                                            {Array.isArray(lastMonth) ? (
                                                <h3 className="ml-2">
                                                    {lastMonth.length === 0
                                                        ? ' $ 0'
                                                        : calculate(lastMonth)}
                                                </h3>
                                            ) : (
                                                <h3 className="ml-2">$ 0</h3>
                                            )}
                                        </div>
                                        {/* <div className="align-items-center row mx-0 border-bottom p-4">
                                            0 %:
                                        </div>
                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                            20 %
                                        </div>
                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                            40 %
                                        </div>
                                        <div className="align-items-center row mx-0 border-bottom p-4">
                                            40 %
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Anlaytics;
