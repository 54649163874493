import { Button, Form, FormControl, Nav, Row, Tab } from "react-bootstrap";
import { GetdtUser, GetsyUser, UserConsumption } from "../../../../api/api";
import { Link, useHistory } from "react-router-dom";
import React, { Fragment, useCallback, useEffect, useState } from "react";

// import Fuse from "fuse.js";
import FuzzySearch from "fuzzy-search";
// import Consumption from "./Consumption";
import PageTitle from "../../../../layouts/PageTitle";
import avartar1 from "../../../../../images/avatar/1.png";
import avartar5 from "../../../../../images/avatar/5.png";
/// images
import { createBrowserHistory } from "history";

const Customers = () => {
	// const history = createBrowserHistory();

	const [customers, setCustomers] = useState([]);
	const [today, setToday] = useState(false);
	const [customerToday, setCustomerToday] = useState([]);
	const [openCon, setOpenCon] = useState(false);
	const [openPay, setOpenPay] = useState(false);
	const [searchText, setSearchText] = useState("");

	const user = JSON.parse(localStorage.getItem("current_user") || "{}");
	// console.log(user.access_token);
	useEffect(() => {
		const getUsers = async () => {
			const data = await GetsyUser(user.access_token);
			setCustomers(data.data);
			// console.log(data.data);
		};
		getUsers();
	}, []);
	// console.log(customers);

	const handleAllUsers = async () => {
		const data = await GetsyUser(user.access_token);
		setToday(false);
		setCustomers(data.data);
	};

	const handleToday = async () => {
		const today = await GetdtUser(user.access_token);
		console.log(today);
		setToday(true);
		setCustomerToday(today.data);
	};
	const history = useHistory();
	// const handleOnClick = useCallback(() => history.push("/sample"), [history]);

	const handleOnClick = useCallback(
		(item) => {
			history.push({
				pathname: `/consumption-detail/`,
				data: { id: item.id },
			});
			// console.log("aaaa", item);
		},
		[history]
	);
	// console.log("searchText", searchText);

	const fuzzySearch = async () => {
		console.log("search");
		const searcher = new FuzzySearch(
			customers,
			["id", "nickname", "email"],
			{
				caseSensitive: false,
			}
		);
		const result = searcher.search(searchText);
		setCustomers(result);
	};
	console.log(customers);

	return (
		<Fragment>
			{customers.length > 0 && (
				<>
					<div className='col-xl align-self-start mb-4'>
						<div
							// to='app-profile'
							className=' mb-3 mb-xl-0 d-flex align-items-center justify-content-center light btn-lg btn-block rounded shadow px-4'
						>
							{/* <div className='search_bar dropdown show'> */}
							<Form inline>
								<Form.Group>
									<FormControl
										type='text'
										placeholder='Search'
										// className='mr-sm-2'
										onChange={(e) =>
											setSearchText(e.target.value)
										}
									/>
									<Button
										variant='primary'
										onClick={fuzzySearch}
									>
										Search
									</Button>
								</Form.Group>
							</Form>

							{/* <Link
								to='#'
								onClick={handleToday}
								className='btn mb-0 font-w600 float-right'
								style={{ float: "right" }}
								// variant='primary'
							>
								<Button variant='primary'>
									{" "}
									New Users (Today)
								</Button>
							</Link> */}
						</div>
						{/* </Link> */}
					</div>
					<div className='row'>
						<div className='col-lg-12'>
							<div className='card'>
								<div className='card-body'>
									<Tab.Container
									// defaultActiveKey={tabData[0].name.toLowerCase()}
									>
										<Nav as='ul' className='nav-tabs'>
											<Nav.Item as='li'>
												<Nav.Link
													onClick={handleAllUsers}
													// eventKey={data.name.toLowerCase()}
												>
													All Users
												</Nav.Link>
											</Nav.Item>
											<Nav.Item as='li'>
												<Nav.Link
													onClick={handleToday}
													// eventKey={data.name.toLowerCase()}
												>
													New Users (Today)
												</Nav.Link>
											</Nav.Item>
										</Nav>
										<Tab.Content className='pt-4'>
											<div className='table-responsive'>
												<table className='table mb-0 table-hover'>
													<thead>
														<tr className='w-100'>
															<th>ID</th>
															<th className=''>
																Name
															</th>
															<th
																className=''
																style={{
																	width:
																		"10px",
																}}
															>
																Email
															</th>
															<th className='pl-5'>
																Source
															</th>
														</tr>
													</thead>
													<tbody id='customers'>
														{Array.isArray(
															customers
														) &&
														customers.length > 0 &&
														!today
															? customers.map(
																	(
																		item,
																		i
																	) => (
																		<>
																			<tr
																				className='btn-reveal-trigger cursor-pointer'
																				onClick={() =>
																					handleOnClick(
																						item
																					)
																				}
																			>
																				<td>
																					{
																						item.id
																					}
																				</td>
																				<td className='py-3 '>
																					<div className='media d-flex align-items-center'>
																						<div className='avatar avatar-xl mr-2'>
																							<div className=''>
																								<img
																									className='rounded-circle img-fluid'
																									src={
																										item.avatar
																											? item.avatar
																											: avartar1
																									}
																									width='30'
																									alt=''
																								/>
																							</div>
																						</div>
																						<div className='media-body'>
																							<h5 className='mb-0 fs--1'>
																								{
																									item.nickname
																								}
																							</h5>
																						</div>
																					</div>
																				</td>
																				<td
																					className='py-2'
																					style={{
																						width:
																							"10px",
																					}}
																				>
																					{
																						item.email
																					}
																				</td>

																				<td className='py-2 pl-5 wspace-no'>
																					{
																						item.source
																					}
																				</td>
																			</tr>
																		</>
																		// </a>
																	)
															  )
															: Array.isArray(
																	customerToday
															  ) &&
															  customerToday.length >
																	0 &&
															  customerToday.map(
																	(
																		item,
																		i
																	) => (
																		<tr
																			className='btn-reveal-trigger cursor-pointer'
																			onClick={() =>
																				handleOnClick(
																					item
																				)
																			}
																		>
																			<td>
																				{
																					item.id
																				}
																			</td>
																			<td className='py-3 '>
																				<div className='media d-flex align-items-center'>
																					<div className='avatar avatar-xl mr-2'>
																						<div className=''>
																							<img
																								className='rounded-circle img-fluid'
																								src={
																									item.avatar
																										? item.avatar
																										: avartar1
																								}
																								width='30'
																								alt=''
																							/>
																						</div>
																					</div>
																					<div className='media-body'>
																						<h5 className='mb-0 fs--1'>
																							{
																								item.nickname
																							}
																						</h5>
																					</div>
																				</div>
																			</td>
																			<td
																				className='py-2'
																				style={{
																					width:
																						"10px",
																				}}
																			>
																				{
																					item.email
																				}
																			</td>

																			<td className='py-2 pl-5 wspace-no'>
																				{
																					item.source
																				}
																			</td>
																		</tr>
																	)
															  )}
													</tbody>
												</table>
											</div>
										</Tab.Content>
									</Tab.Container>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</Fragment>
	);
};

export default Customers;
