import { Button, Card, Col, Modal, Nav, Row } from "react-bootstrap";
import {
	GetBeauty,
	GetBubbletea,
	GetEntertainment,
	GetUserspendcount,
	IdgetUser,
} from "../../../../api/api";
import { Link, useHistory } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import PageTitle from "../../../../layouts/PageTitle";
import SearchAllSpend from "./components/SearchAllSpend";
import SearchSpend from "./components/SearchSpend";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import productData from "../productData";

const Rank = () => {
	//** Dynamic Key Single Place */
	const user = JSON.parse(localStorage.getItem("current_user") || "{}");
	const history = useHistory();
	const [rank, setRank] = useState([]);
	// const [beauty, setBeauty] = useState([]);
	// const [entertainment, setEntertainment] = useState([]);
	// const [restaurant, setRestaurant] = useState([]);
	// const [value, setValue] = useState(null);
	useEffect(() => {
		async function getData() {
			const data = await GetUserspendcount(user.access_token);

			setRank(data.data);
		}
		getData();
	}, []);

	const columns = [
		{
			dataField: "userid",
			text: "User ID",
			sort: true,
			// formatter: priceFormatter,
			// headerStyle: { width: "50px", color: "white" },
		},
		{
			dataField: "num",
			text: "Times",
			sort: true,
			// order: "desc",
			// formatter: priceFormatter,
			// headerStyle: { width: "50px", color: "white" },
		},
	];
	const defaultSorted = [
		{
			dataField: "num",
			order: "desc",
		},
	];
	const rowEvents = {
		onClick: useCallback(
			(e, row, rowIndex) => {
				console.log("row", row);

				history.push({
					pathname: `/consumption-detail/`,
					data: { id: row.userid },
				});
			},
			[history]
		),
	};

	return (
		<div className='h-80'>
			<div className='row'>
				<div className='col-lg-12'>
					<div className='card'>
						<div className='card-header d-flex justify-content-center align-items-center'>
							<h4>Consumption Rank</h4>
						</div>

						<div className='card-body '>
							<div className='d-flex justify-content-center align-items-center'>
								<h4>Paid User: {rank.length}</h4>
							</div>

							<BootstrapTable
								keyField='id'
								data={rank}
								columns={columns}
								condensed
								hover
								rowEvents={rowEvents}
								defaultSorted={defaultSorted}
								// cursor
								// exportCSV
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Rank;
