import { Button, Card, Col, Modal, Nav, Row, Table } from "react-bootstrap";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useEffect, useState } from "react";

import DateFnsUtils from "@date-io/date-fns";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { SearchUserspend } from "../../../../../api/api";
import moment from "moment";

const SearchAllSpend = ({ show, handleClose }) => {
	// if (show) {
	// console.log(show);
	const user = JSON.parse(localStorage.getItem("current_user") || "{}");

	const [selectedDate1, handleDateChange1] = useState(new Date());
	const [selectedDate2, handleDateChange2] = useState(new Date());
	const date1 = moment(selectedDate1).format("YYYY-MM-DD");
	const date2 = moment(selectedDate2).format("YYYY-MM-DD");
	// const Date = moment(selectedDate).format('YYYY-MM-DD');
	// console.log(date1, date2);
	// console.log(item);
	// console.log(id, types);

	const [value, setValue] = useState([]);

	const [total, setTotal] = useState(null);
	const [msg, setMsg] = useState("");
	const [stripeFee, setStripeFee] = useState(0);

	const handleCheck = async () => {
		const data = await SearchUserspend(user.access_token, date1, date2);
		console.log(data);
		if (data.ret === 200) {
			const d = data.data;

			setValue(d);
			var amount = 0;
			if (d.Babbletea) {
				// console.log("aaaa");
				for (var i = 0; i < d.Babbletea.length; i++) {
					// console.log(amount);
					amount += parseInt(d.Babbletea[i].amount);
				}
				console.log(d);
			}
			if (d.Beauty) {
				for (var i = 0; i < d.Beauty.length; i++) {
					// console.log(amount);
					amount += parseInt(d.Beauty[i].amount);
				}
			}
			if (d.Restaurant) {
				for (var i = 0; i < d.Restaurant.length; i++) {
					// console.log(amount);
					amount += parseInt(d.Restaurant[i].amount);
				}
			}
			if (d.Entertainment) {
				for (var i = 0; i < d.Entertainment.length; i++) {
					// console.log(amount);
					amount += parseInt(d.Entertainment[i].amount);
				}
			}
			if (d.Barber) {
				for (var i = 0; i < d.Barber.length; i++) {
					// console.log(amount);
					amount += parseInt(d.Barber[i].amount);
				}
			}
			if (d.Coffee) {
				for (var i = 0; i < d.Coffee.length; i++) {
					// console.log(amount);
					amount += parseInt(d.Coffee[i].amount);
				}
			}
			if (d.Dessert) {
				for (var i = 0; i < d.Dessert.length; i++) {
					// console.log(amount);
					amount += parseInt(d.Dessert[i].amount);
				}
			}
			if (d.Garage) {
				for (var i = 0; i < d.Garage.length; i++) {
					// console.log(amount);
					amount += parseInt(d.Garage[i].amount);
				}
			}
			if (d.Entertainment) {
				for (var i = 0; i < d.Entertainment.length; i++) {
					// console.log(amount);
					amount += parseInt(d.Entertainment[i].amount);
				}
			}
			if (d.Massage) {
				for (var i = 0; i < d.Massage.length; i++) {
					// console.log(amount);
					amount += parseInt(d.Massage[i].amount);
				}
			}
			if (d.Movie) {
				for (var i = 0; i < d.Movie.length; i++) {
					// console.log(amount);
					amount += parseInt(d.Movie[i].amount);
				}
			}
			if (d.fitness) {
				for (var i = 0; i < d.fitness.length; i++) {
					// console.log(amount);
					amount += parseInt(d.fitness[i].amount);
				}
			}

			setTotal((amount / 100).toFixed(2));
		}
		if (data.ret === 400) {
			setMsg(data.msg);
		}
	};

	const handleExit = () => {
		setValue([]);
	};

	const calStripe = (amount) => {
		const fee = ((amount * 0.0175) / 100 + 0.3).toFixed(2);
		// setStripeFee(fee);
		return fee;
	};

	console.log(value);

	return (
		<Modal
			show={show}
			onHide={handleClose}
			onExit={handleExit}
			backdrop='static'
			keyboard={false}
			centered
			size='lg'
		>
			<Modal.Header closeButton>
				<Modal.Title>Select Date</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='row mb-8 modal-lg'>
					<div className='row d-flex justify-content-center w-100 ml-36'>
						<div className='col-xl col-xxl mb-3'>
							<label>Start Date</label>
							<MuiPickersUtilsProvider
								utils={DateFnsUtils}
								style={{ width: "100%" }}
							>
								<DatePicker
									autoOk
									fullWidth
									// label="date1"
									name='date1'
									clearable
									format='yyyy/MM/dd'
									disableFuture
									value={selectedDate1}
									onChange={handleDateChange1}
								/>
							</MuiPickersUtilsProvider>
						</div>
					</div>
				</div>
				<div className='row mb-8'>
					<div className='row form-material w-100'>
						<div className='col-xl col-xxl mb-3'>
							<label>End Date</label>
							<MuiPickersUtilsProvider
								utils={DateFnsUtils}
								style={{ width: "100%" }}
							>
								<DatePicker
									autoOk
									fullWidth
									// label="date1"
									name='date1'
									clearable
									format='yyyy/MM/dd'
									disableFuture
									value={selectedDate2}
									onChange={handleDateChange2}
								/>
							</MuiPickersUtilsProvider>
						</div>
					</div>
				</div>
				<div className='row  d-flex justify-content-center align-items-center mb-8'>
					<Button onClick={handleCheck}>Search</Button>
				</div>
				{Array.isArray(value.Restaurant) &&
				value.Restaurant.length > 0 ? (
					<div className='row mt-36 mb-4 w-100 '>
						<div className=' mt-36 w-100 '>
							Restaurant
							<Table id='Res-consumed'>
								<tr>
									<th>Name</th>
									<th>Typename</th>
									<th>Paid time</th>
									<th>Amount</th>
									{/* <th>Stripe fee</th> */}
								</tr>
								{Array.isArray(value.Restaurant) &&
									value.Restaurant.length > 0 &&
									value.Restaurant.map((item) => (
										<tr>
											<th style={{ width: "25%" }}>
												{item.name}
											</th>
											<th style={{ width: "35%" }}>
												{item.typename}
											</th>
											<th style={{ width: "20%" }}>
												{moment(item.timestamp).format(
													"DD-MM-YYYY"
												)}
											</th>
											<th style={{ width: "10%" }}>
												$
												{(item.amount / 100).toFixed(2)}
											</th>
											{/* <th
												className='text-color-red'
												style={{ width: "10%" }}
											>
												${calStripe(item.amount)}
											</th> */}
										</tr>
									))}
							</Table>
							<div className='float-right'>
								<ReactHTMLTableToExcel
									className='btn bg-primary border-0  text-white'
									table='Res-consumed'
									filename={"Res-" + date1 + "-" + date2}
									sheet='Res-xls'
									buttonText='Download Restaurant as XLS'
								/>
							</div>
						</div>
					</div>
				) : (
					<div className='mt-4 d-flex justify-content-center'>
						{msg}
					</div>
				)}
				{Array.isArray(value.Babbletea) &&
				value.Babbletea.length > 0 ? (
					<div className='row mt-36 mb-4 w-100 '>
						<div className=' mt-36 w-100 '>
							Bubble Tea
							<Table id='BubbleTea-consumed'>
								<tr>
									<th>Name</th>
									<th>Typename</th>
									<th>Paid time</th>
									<th>Amount</th>
									{/* <th>Stripe fee</th> */}
								</tr>
								{Array.isArray(value.Babbletea) &&
									value.Babbletea.length > 0 &&
									value.Babbletea.map((item) => (
										<tr>
											<th style={{ width: "25%" }}>
												{item.name}
											</th>
											<th style={{ width: "35%" }}>
												{item.typename}
											</th>
											<th style={{ width: "20%" }}>
												{moment(item.timestamp).format(
													"DD-MM-YYYY"
												)}
											</th>
											<th style={{ width: "10%" }}>
												$
												{(item.amount / 100).toFixed(2)}
											</th>
											{/* <th style={{ width: "10%" }}>
												${calStripe(item.amount)}
											</th> */}
										</tr>
									))}
							</Table>
							<div className='float-right'>
								<ReactHTMLTableToExcel
									className='btn bg-primary border-0  text-white'
									table='Bubble-Tea-consumed'
									filename={
										"Bubble-Tea-" + date1 + "-" + date2
									}
									sheet='BubbleTea-xls'
									buttonText='Download Bubble Tea as XLS'
								/>
							</div>
						</div>
					</div>
				) : (
					<div className='mt-4 d-flex justify-content-center'>
						{msg}
					</div>
				)}
				{Array.isArray(value.Beauty) && value.Beauty.length > 0 ? (
					<div className='row mt-36 mb-4 w-100 '>
						<div className=' mt-36 w-100 '>
							Beauty
							<Table id='beauty-consumed'>
								<tr>
									<th>Name</th>
									<th>Typename</th>
									<th>Paid time</th>
									<th>Amount</th>
									{/* <th>Stripe fee</th> */}
								</tr>
								{Array.isArray(value.Beauty) &&
									value.Beauty.length > 0 &&
									value.Beauty.map((item) => (
										<tr>
											<th style={{ width: "25%" }}>
												{item.name}
											</th>
											<th style={{ width: "35%" }}>
												{item.typename}
											</th>
											<th style={{ width: "20%" }}>
												{moment(item.timestamp).format(
													"DD-MM-YYYY"
												)}
											</th>
											<th style={{ width: "10%" }}>
												$
												{(item.amount / 100).toFixed(2)}
											</th>
											{/* <th style={{ width: "10%" }}>
												${calStripe(item.amount)}
											</th> */}
										</tr>
									))}
							</Table>
							<div className='float-right'>
								<ReactHTMLTableToExcel
									className='btn bg-primary border-0  text-white'
									table='Beauty-consumed'
									filename={"Beauty-" + date1 + "-" + date2}
									sheet='Beauty-xls'
									buttonText='Download Beauty as XLS'
								/>
							</div>
						</div>
					</div>
				) : (
					<div className='mt-4 d-flex justify-content-center'>
						{msg}
					</div>
				)}

				{Array.isArray(value.Entertainment) &&
				value.Entertainment.length > 0 ? (
					<div className='row mt-36 mb-4 w-100 '>
						<div className=' mt-36 w-100 '>
							Entertainment
							<Table id='ent-consumed'>
								<tr>
									<th>Name</th>
									<th>Typename</th>
									<th>Paid time</th>
									<th>Amount</th>
									{/* <th>Stripe fee</th> */}
								</tr>
								{Array.isArray(value.Entertainment) &&
									value.Entertainment.length > 0 &&
									value.Entertainment.map((item) => (
										<tr>
											<th style={{ width: "25%" }}>
												{item.name}
											</th>
											<th style={{ width: "35%" }}>
												{item.typename}
											</th>
											<th style={{ width: "20%" }}>
												{moment(item.timestamp).format(
													"DD-MM-YYYY"
												)}
											</th>
											<th style={{ width: "10%" }}>
												$
												{(item.amount / 100).toFixed(2)}
											</th>
											{/* <th style={{ width: "10%" }}>
												${calStripe(item.amount)}
											</th> */}
										</tr>
									))}
							</Table>
							<div className='float-right'>
								<ReactHTMLTableToExcel
									className='btn bg-primary border-0  text-white'
									table='Entertainment-consumed'
									filename={
										"Entertainment-" + date1 + "-" + date2
									}
									sheet='Entertainment-xls'
									buttonText='Download Entertainment as XLS'
								/>
							</div>
						</div>
					</div>
				) : (
					<div className='mt-4 d-flex justify-content-center'>
						{msg}
					</div>
				)}

				{Array.isArray(value.Supermarket) &&
				value.Supermarket.length > 0 ? (
					<div className='row mt-36 mb-4 w-100 '>
						<div className=' mt-36 w-100 '>
							Supermarket
							<Table id='ent-consumed'>
								<tr>
									<th>Name</th>
									<th>Typename</th>
									<th>Paid time</th>
									<th>Amount</th>
									{/* <th>Stripe fee</th> */}
								</tr>
								{Array.isArray(value.Supermarket) &&
									value.Supermarket.length > 0 &&
									value.Supermarket.map((item) => (
										<tr>
											<th style={{ width: "25%" }}>
												{item.name}
											</th>
											<th style={{ width: "35%" }}>
												{item.typename}
											</th>
											<th style={{ width: "20%" }}>
												{moment(item.timestamp).format(
													"DD-MM-YYYY"
												)}
											</th>
											<th style={{ width: "10%" }}>
												$
												{(item.amount / 100).toFixed(2)}
											</th>
											{/* <th style={{ width: "10%" }}>
												${calStripe(item.amount)}
											</th> */}
										</tr>
									))}
							</Table>
							<div className='float-right'>
								<ReactHTMLTableToExcel
									className='btn bg-primary border-0  text-white'
									table='Supermarket-consumed'
									filename={
										"Supermarket-" + date1 + "-" + date2
									}
									sheet='Supermarket-xls'
									buttonText='Download Supermarket as XLS'
								/>
							</div>
						</div>
					</div>
				) : (
					<div className='mt-4 d-flex justify-content-center'>
						{msg}
					</div>
				)}
				{Array.isArray(value.Barber) && value.Barber.length > 0 ? (
					<div className='row mt-36 mb-4 w-100 '>
						<div className=' mt-36 w-100 '>
							Barber
							<Table id='ent-consumed'>
								<tr>
									<th>Name</th>
									<th>Typename</th>
									<th>Paid time</th>
									<th>Amount</th>
									{/* <th>Stripe fee</th> */}
								</tr>
								{Array.isArray(value.Barber) &&
									value.Barber.length > 0 &&
									value.Barber.map((item) => (
										<tr>
											<th style={{ width: "25%" }}>
												{item.name}
											</th>
											<th style={{ width: "35%" }}>
												{item.typename}
											</th>
											<th style={{ width: "20%" }}>
												{moment(item.timestamp).format(
													"DD-MM-YYYY"
												)}
											</th>
											<th style={{ width: "10%" }}>
												$
												{(item.amount / 100).toFixed(2)}
											</th>
											{/* <th style={{ width: "10%" }}>
												${calStripe(item.amount)}
											</th> */}
										</tr>
									))}
								{/* <tr>
									<th></th>
									<th></th>
									<th></th>
									<th className='float-right'>
										Total:${total}
									</th>
								</tr> */}
							</Table>
							<div className='float-right'>
								<ReactHTMLTableToExcel
									className='btn bg-primary border-0  text-white'
									table='Barber-consumed'
									filename={"Barber-" + date1 + "-" + date2}
									sheet='Barber-xls'
									buttonText='Download Barber as XLS'
								/>
							</div>
						</div>
					</div>
				) : (
					<div className='mt-4 d-flex justify-content-center'>
						{msg}
					</div>
				)}
				{Array.isArray(value.Coffee) && value.Coffee.length > 0 ? (
					<div className='row mt-36 mb-4 w-100 '>
						<div className=' mt-36 w-100 '>
							Coffee
							<Table id='ent-consumed'>
								<tr>
									<th>Name</th>
									<th>Typename</th>
									<th>Paid time</th>
									<th>Amount</th>
									{/* <th>Stripe fee</th> */}
								</tr>
								{Array.isArray(value.Coffee) &&
									value.Coffee.length > 0 &&
									value.Coffee.map((item) => (
										<tr>
											<th style={{ width: "25%" }}>
												{item.name}
											</th>
											<th style={{ width: "35%" }}>
												{item.typename}
											</th>
											<th style={{ width: "20%" }}>
												{moment(item.timestamp).format(
													"DD-MM-YYYY"
												)}
											</th>
											<th style={{ width: "10%" }}>
												$
												{(item.amount / 100).toFixed(2)}
											</th>
											{/* <th style={{ width: "10%" }}>
												${calStripe(item.amount)}
											</th> */}
										</tr>
									))}
								{/* <tr>
									<th></th>
									<th></th>
									<th></th>
									<th className='float-right'>
										Total:${total}
									</th>
								</tr> */}
							</Table>
							<div className='float-right'>
								<ReactHTMLTableToExcel
									className='btn bg-primary border-0  text-white'
									table='Coffee-consumed'
									filename={"Coffee-" + date1 + "-" + date2}
									sheet='Coffee-xls'
									buttonText='Download Coffee as XLS'
								/>
							</div>
						</div>
					</div>
				) : (
					<div className='mt-4 d-flex justify-content-center'>
						{msg}
					</div>
				)}
				{Array.isArray(value.Dessert) && value.Dessert.length > 0 ? (
					<div className='row mt-36 mb-4 w-100 '>
						<div className=' mt-36 w-100 '>
							Dessert
							<Table id='ent-consumed'>
								<tr>
									<th>Name</th>
									<th>Typename</th>
									<th>Paid time</th>
									<th>Amount</th>
									{/* <th>Stripe fee</th> */}
								</tr>
								{Array.isArray(value.Dessert) &&
									value.Dessert.length > 0 &&
									value.Dessert.map((item) => (
										<tr>
											<th style={{ width: "25%" }}>
												{item.name}
											</th>
											<th style={{ width: "35%" }}>
												{item.typename}
											</th>
											<th style={{ width: "20%" }}>
												{moment(item.timestamp).format(
													"DD-MM-YYYY"
												)}
											</th>
											<th style={{ width: "10%" }}>
												$
												{(item.amount / 100).toFixed(2)}
											</th>
											{/* <th style={{ width: "10%" }}>
												${calStripe(item.amount)}
											</th> */}
										</tr>
									))}
								{/* <tr>
									<th></th>
									<th></th>
									<th></th>
									<th className='float-right'>
										Total:${total}
									</th>
								</tr> */}
							</Table>
							<div className='float-right'>
								<ReactHTMLTableToExcel
									className='btn bg-primary border-0  text-white'
									table='Dessert-consumed'
									filename={"Dessert-" + date1 + "-" + date2}
									sheet='Dessert-xls'
									buttonText='Download Dessert as XLS'
								/>
							</div>
						</div>
					</div>
				) : (
					<div className='mt-4 d-flex justify-content-center'>
						{msg}
					</div>
				)}
				{Array.isArray(value.Garage) && value.Garage.length > 0 ? (
					<div className='row mt-36 mb-4 w-100 '>
						<div className=' mt-36 w-100 '>
							Garage
							<Table id='ent-consumed'>
								<tr>
									<th>Name</th>
									<th>Typename</th>
									<th>Paid time</th>
									<th>Amount</th>
									{/* <th>Stripe fee</th> */}
								</tr>
								{Array.isArray(value.Garage) &&
									value.Garage.length > 0 &&
									value.Garage.map((item) => (
										<tr>
											<th style={{ width: "25%" }}>
												{item.name}
											</th>
											<th style={{ width: "35%" }}>
												{item.typename}
											</th>
											<th style={{ width: "20%" }}>
												{moment(item.timestamp).format(
													"DD-MM-YYYY"
												)}
											</th>
											<th style={{ width: "10%" }}>
												$
												{(item.amount / 100).toFixed(2)}
											</th>
											{/* <th style={{ width: "10%" }}>
												${calStripe(item.amount)}
											</th> */}
										</tr>
									))}
								{/* <tr>
									<th></th>
									<th></th>
									<th></th>
									<th className='float-right'>
										Total:${total}
									</th>
								</tr> */}
							</Table>
							<div className='float-right'>
								<ReactHTMLTableToExcel
									className='btn bg-primary border-0  text-white'
									table='Garage-consumed'
									filename={"Garage-" + date1 + "-" + date2}
									sheet='Garage-xls'
									buttonText='Download Garage as XLS'
								/>
							</div>
						</div>
					</div>
				) : (
					<div className='mt-4 d-flex justify-content-center'>
						{msg}
					</div>
				)}
				{Array.isArray(value.Massage) && value.Massage.length > 0 ? (
					<div className='row mt-36 mb-4 w-100 '>
						<div className=' mt-36 w-100 '>
							Massage
							<Table id='ent-consumed'>
								<tr>
									<th>Name</th>
									<th>Typename</th>
									<th>Paid time</th>
									<th>Amount</th>
									{/* <th>Stripe fee</th> */}
								</tr>
								{Array.isArray(value.Massage) &&
									value.Massage.length > 0 &&
									value.Massage.map((item) => (
										<tr>
											<th style={{ width: "25%" }}>
												{item.name}
											</th>
											<th style={{ width: "35%" }}>
												{item.typename}
											</th>
											<th style={{ width: "20%" }}>
												{moment(item.timestamp).format(
													"DD-MM-YYYY"
												)}
											</th>
											<th style={{ width: "10%" }}>
												$
												{(item.amount / 100).toFixed(2)}
											</th>
											{/* <th style={{ width: "10%" }}>
												${calStripe(item.amount)}
											</th> */}
										</tr>
									))}
								{/* <tr>
									<th></th>
									<th></th>
									<th></th>
									<th className='float-right'>
										Total:${total}
									</th>
								</tr> */}
							</Table>
							<div className='float-right'>
								<ReactHTMLTableToExcel
									className='btn bg-primary border-0  text-white'
									table='Massage-consumed'
									filename={"Massage-" + date1 + "-" + date2}
									sheet='Massage-xls'
									buttonText='Download Massage as XLS'
								/>
							</div>
						</div>
					</div>
				) : (
					<div className='mt-4 d-flex justify-content-center'>
						{msg}
					</div>
				)}
				{Array.isArray(value.Movie) && value.Movie.length > 0 ? (
					<div className='row mt-36 mb-4 w-100 '>
						<div className=' mt-36 w-100 '>
							Movie
							<Table id='ent-consumed'>
								<tr>
									<th>Name</th>
									<th>Typename</th>
									<th>Paid time</th>
									<th>Amount</th>
									{/* <th>Stripe fee</th> */}
								</tr>
								{Array.isArray(value.Movie) &&
									value.Movie.length > 0 &&
									value.Movie.map((item) => (
										<tr>
											<th style={{ width: "25%" }}>
												{item.name}
											</th>
											<th style={{ width: "35%" }}>
												{item.typename}
											</th>
											<th style={{ width: "20%" }}>
												{moment(item.timestamp).format(
													"DD-MM-YYYY"
												)}
											</th>
											<th style={{ width: "10%" }}>
												$
												{(item.amount / 100).toFixed(2)}
											</th>
											{/* <th style={{ width: "10%" }}>
												${calStripe(item.amount)}
											</th> */}
										</tr>
									))}
								<tr>
									<th></th>
									<th></th>
									<th></th>
									<th className='float-right'>
										Total:${total}
									</th>
								</tr>
							</Table>
							<div className='float-right'>
								<ReactHTMLTableToExcel
									className='btn bg-primary border-0  text-white'
									table='Movie-consumed'
									filename={"Movie-" + date1 + "-" + date2}
									sheet='Movie-xls'
									buttonText='Download Movie as XLS'
								/>
							</div>
						</div>
					</div>
				) : (
					<div className='mt-4 d-flex justify-content-center'>
						{msg}
					</div>
				)}

				{/* {Array.isArray(value.Entertainment) &&
				value.Entertainment.length > 0 ? (
					<div className='row mt-36 mb-4 w-100 '>
						<div className=' mt-36 w-100 '>
							Entertainment
							<Table id='ent-consumed'>
								<tr>
									<th>Name</th>
									<th>Typename</th>
									<th>Paid time</th>
									<th>Amount</th>
									<th>Stripe fee</th>
								</tr>
								{Array.isArray(value.Entertainment) &&
									value.Entertainment.length > 0 &&
									value.Entertainment.map((item) => (
										<tr>
											<th style={{ width: "25%" }}>
												{item.name}
											</th>
											<th style={{ width: "35%" }}>
												{item.typename}
											</th>
											<th style={{ width: "20%" }}>
												{moment(item.timestamp).format(
													"DD-MM-YYYY"
												)}
											</th>
											<th style={{ width: "10%" }}>
												$
												{(item.amount / 100).toFixed(2)}
											</th>
											<th style={{ width: "10%" }}>
												${calStripe(item.amount)}
											</th>
										</tr>
									))}
								<tr>
									<th></th>
									<th></th>
									<th></th>
									<th className='float-right'>
										Total:${total}
									</th>
								</tr>
							</Table>
							<div className='float-right'>
								<ReactHTMLTableToExcel
									className='btn bg-primary border-0  text-white'
									table='Entertainment-consumed'
									filename={
										"Entertainment-" + date1 + "-" + date2
									}
									sheet='Entertainment-xls'
									buttonText='Download Entertainment as XLS'
								/>
							</div>
						</div>
					</div>
				) : (
					<div className='mt-4 d-flex justify-content-center'>
						{msg}
					</div>
				)}
				{Array.isArray(value.Entertainment) &&
				value.Entertainment.length > 0 ? (
					<div className='row mt-36 mb-4 w-100 '>
						<div className=' mt-36 w-100 '>
							Entertainment
							<Table id='ent-consumed'>
								<tr>
									<th>Name</th>
									<th>Typename</th>
									<th>Paid time</th>
									<th>Amount</th>
									<th>Stripe fee</th>
								</tr>
								{Array.isArray(value.Entertainment) &&
									value.Entertainment.length > 0 &&
									value.Entertainment.map((item) => (
										<tr>
											<th style={{ width: "25%" }}>
												{item.name}
											</th>
											<th style={{ width: "35%" }}>
												{item.typename}
											</th>
											<th style={{ width: "20%" }}>
												{moment(item.timestamp).format(
													"DD-MM-YYYY"
												)}
											</th>
											<th style={{ width: "10%" }}>
												$
												{(item.amount / 100).toFixed(2)}
											</th>
											<th style={{ width: "10%" }}>
												${calStripe(item.amount)}
											</th>
										</tr>
									))}
								<tr>
									<th></th>
									<th></th>
									<th></th>
									<th className='float-right'>
										Total:${total}
									</th>
								</tr>
							</Table>
							<div className='float-right'>
								<ReactHTMLTableToExcel
									className='btn bg-primary border-0  text-white'
									table='Entertainment-consumed'
									filename={
										"Entertainment-" + date1 + "-" + date2
									}
									sheet='Entertainment-xls'
									buttonText='Download Entertainment as XLS'
								/>
							</div>
						</div>
					</div>
				) : (
					<div className='mt-4 d-flex justify-content-center'>
						{msg}
					</div>
				)}
				{Array.isArray(value.Entertainment) &&
				value.Entertainment.length > 0 ? (
					<div className='row mt-36 mb-4 w-100 '>
						<div className=' mt-36 w-100 '>
							Entertainment
							<Table id='ent-consumed'>
								<tr>
									<th>Name</th>
									<th>Typename</th>
									<th>Paid time</th>
									<th>Amount</th>
									<th>Stripe fee</th>
								</tr>
								{Array.isArray(value.Entertainment) &&
									value.Entertainment.length > 0 &&
									value.Entertainment.map((item) => (
										<tr>
											<th style={{ width: "25%" }}>
												{item.name}
											</th>
											<th style={{ width: "35%" }}>
												{item.typename}
											</th>
											<th style={{ width: "20%" }}>
												{moment(item.timestamp).format(
													"DD-MM-YYYY"
												)}
											</th>
											<th style={{ width: "10%" }}>
												$
												{(item.amount / 100).toFixed(2)}
											</th>
											<th style={{ width: "10%" }}>
												${calStripe(item.amount)}
											</th>
										</tr>
									))}
								<tr>
									<th></th>
									<th></th>
									<th></th>
									<th className='float-right'>
										Total:${total}
									</th>
								</tr>
							</Table>
							<div className='float-right'>
								<ReactHTMLTableToExcel
									className='btn bg-primary border-0  text-white'
									table='Entertainment-consumed'
									filename={
										"Entertainment-" + date1 + "-" + date2
									}
									sheet='Entertainment-xls'
									buttonText='Download Entertainment as XLS'
								/>
							</div>
						</div>
					</div>
				) : (
					<div className='mt-4 d-flex justify-content-center'>
						{msg}
					</div>
				)} */}
			</Modal.Body>
			{/* <Modal.Footer></Modal.Footer> */}
		</Modal>
	);
};

export default SearchAllSpend;
