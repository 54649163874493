import { Button, Spinner, Table, TableCell } from "react-bootstrap";
import {
	Get2330Userspend,
	Get30Userspend,
	getNotConsumed,
} from "../../../../api/api";
import { Link, useHistory } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";

const NotConsumed = () => {
	const [bubble, setBubble] = useState([]);
	const [res, setRes] = useState([]);
	const [beauty, setBeauty] = useState([]);
	const [ent, setEnt] = useState([]);
	const [notConsumption, setNotConsumption] = useState(false);
	const [searched, setSearched] = useState(false);
	const [orders, setOrders] = useState([]);
	const user = JSON.parse(localStorage.getItem("current_user") || "{}");

	const handle30Days = async () => {
		const data = await Get30Userspend(user.access_token);
		setSearched(true);
		setOrders(data.data);
		setNotConsumption(true);
	};
	useEffect(() => {
		const getData = async () => {
			const data = await getNotConsumed(user.access_token);
			const d = data.data;
			setBubble(d.Babbletea);
			setBeauty(d.Beauty);
			setEnt(d.Entertainment);
			setRes(d.Restaurant);
			setNotConsumption(true);
			// setData(data.data);
		};

		handle30Days();
	}, []);
	const history = useHistory();

	const handleOnClick = useCallback(
		(item) => {
			history.push({
				pathname: `/consumption-detail/`,
				data: { id: item.userid },
			});
			// console.log("aaaa", item);
		},
		[history]
	);
	console.log(user);

	const handlemonth = async () => {
		const data = await Get2330Userspend(user.access_token);
		setSearched(true);
		setOrders(data.data);
		setNotConsumption(false);
	};

	const handleBack = () => {
		handle30Days();
	};
	console.log(orders);

	// if (res.length >= 0) {
	// 	return <Spinner animation='border' variant='primary' />;
	// }

	return (
		<div className='col-xl align-self-start'>
			{/* <div
				to='app-profile'
				className=' mb-3 mb-xl-0 d-flex align-items-center justify-content-center light btn-lg btn-block rounded shadow px-4'
			>
				<h5 className='mb-0 text-primary font-w600 '>
					Not Consumed Order
				</h5>
			</div> */}
			<div className='row ' style={{ marginBottom: "20px" }}>
				<div className='col-lg-12 d-flex justify-content-center align-items-center'>
					{/* <Button
						style={{ marginRight: "8px" }}
						onClick={handle30Days}
					>
						Before 30 days not consumption
					</Button> */}
					<Button
						style={{ marginRight: "8px" }}
						onClick={handlemonth}
					>
						23-30 days not consumption
					</Button>
					{!notConsumption && (
						<Button onClick={handleBack}>Back</Button>
					)}
				</div>
			</div>

			{searched && (
				<div className='row'>
					<div className='col-lg-12'>
						<div className='card'>
							<div className='card-body'>
								{/* <h5 class='card-title'>Restauarnt</h5> */}

								<Table className='table-hover'>
									<tr>
										<th style={{ width: "5%" }}>ID</th>
										<th style={{ width: "10%" }}>
											User ID
										</th>
										<th style={{ width: "60%" }}>
											Voucher
										</th>
										<th style={{ width: "15%" }}>
											Paid Time
										</th>
										<th style={{ width: "10%" }}>Amount</th>
									</tr>
									{Array.isArray(orders) &&
									orders.length > 0 ? (
										orders.map((item, i) => (
											<tr
												className='cursor-pointer '
												onClick={() =>
													handleOnClick(item)
												}
											>
												<td>{i + 1}</td>
												<td>{item.userid}</td>
												<td>
													<p className='card-trext'>
														{item.typename}
													</p>
												</td>
												<td>{item.timestamp}</td>
												<td>
													{(item.money / 100).toFixed(
														2
													)}
												</td>
											</tr>
										))
									) : (
										<p className='card-trext'>No Orders</p>
									)}
								</Table>
							</div>
						</div>
					</div>
				</div>
			)}

			{/* {notConsumption && (
				<>
					<div className='row'>
						<div className='col-lg-12'>
							<div className='card'>
								<div className='card-body'>
									<h5 class='card-title'>Restauarnt</h5>

									{Array.isArray(res) && res.length > 0 ? (
										res.map((item, i) => (
											<Table className='table-hover'>
												<tr>
													<th style={{ width: "5%" }}>
														ID
													</th>
													<th
														style={{ width: "10%" }}
													>
														User ID
													</th>
													<th
														style={{ width: "70%" }}
													>
														Voucher
													</th>
													<th
														style={{ width: "15%" }}
													>
														Paid Time
													</th>
												</tr>

												<tr
													className='cursor-pointer '
													onClick={() =>
														handleOnClick(item)
													}
												>
													<td>{i + 1}</td>
													<td>{item.userid}</td>
													<td>
														<p className='card-trext'>
															{item.typename}
														</p>
													</td>
													<td>{item.paidtime}</td>
												</tr>
											</Table>
										))
									) : (
										<p className='card-trext'>No Orders</p>
									)}
								</div>
							</div>
						</div>
					</div>

					<div className='row'>
						<div className='col-lg-12'>
							<div className='card'>
								<div className='card-body'>
									<h5 class='card-title'>Beauty</h5>

									{Array.isArray(beauty) &&
									beauty.length > 0 ? (
										beauty.map((item, i) => (
											<Table className='table-hover'>
												<tr>
													<th style={{ width: "5%" }}>
														ID
													</th>
													<th
														style={{ width: "10%" }}
													>
														User ID
													</th>
													<th
														style={{ width: "70%" }}
													>
														Voucher
													</th>
													<th
														style={{ width: "15%" }}
													>
														Paid Time
													</th>
												</tr>

												<tr
													className='cursor-pointer '
													onClick={() =>
														handleOnClick(item)
													}
												>
													<td>{i + 1}</td>
													<td>{item.userid}</td>
													<td>
														<p className='card-trext'>
															{item.typename}
														</p>
													</td>
													<td>{item.paidtime}</td>
												</tr>
											</Table>
										))
									) : (
										<p className='card-trext'>No Orders</p>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-lg-12'>
							<div className='card'>
								<div className='card-body'>
									<h5 class='card-title'>Entertainment</h5>

									{Array.isArray(ent) && ent.length > 0 ? (
										ent.map((item, i) => (
											<Table className='table-hover'>
												<tr>
													<th style={{ width: "5%" }}>
														ID
													</th>
													<th
														style={{ width: "10%" }}
													>
														User ID
													</th>
													<th
														style={{ width: "70%" }}
													>
														Voucher
													</th>
													<th
														style={{ width: "15%" }}
													>
														Paid Time
													</th>
												</tr>

												<tr
													className='cursor-pointer '
													onClick={() =>
														handleOnClick(item)
													}
												>
													<td>{i + 1}</td>
													<td>{item.userid}</td>
													<td>
														<p className='card-trext'>
															{item.typename}
														</p>
													</td>
													<td>{item.paidtime}</td>
												</tr>
											</Table>
										))
									) : (
										<p className='card-trext'>No Orders</p>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-lg-12'>
							<div className='card'>
								<div className='card-body'>
									<h5 class='card-title'>Bubble Tea</h5>

									{Array.isArray(bubble) &&
									bubble.length > 0 ? (
										bubble.map((item, i) => (
											<Table className='table-hover'>
												<tr>
													<th style={{ width: "5%" }}>
														ID
													</th>
													<th
														style={{ width: "10%" }}
													>
														User ID
													</th>
													<th
														style={{ width: "70%" }}
													>
														Voucher
													</th>
													<th
														style={{ width: "15%" }}
													>
														Paid Time
													</th>
												</tr>

												<tr
													className='cursor-pointer '
													onClick={() =>
														handleOnClick(item)
													}
												>
													<td>{i + 1}</td>
													<td>{item.userid}</td>
													<td>
														<p className='card-trext'>
															{item.typename}
														</p>
													</td>
													<td>{item.paidtime}</td>
												</tr>
											</Table>
										))
									) : (
										<p className='card-trext'>No Orders</p>
									)}
								</div>
							</div>
						</div>
					</div>
				</>
			)} */}
		</div>
	);
};

export default NotConsumed;
