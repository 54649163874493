import { Link } from "react-router-dom";
import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="copyright">
        <p>
          Copyright © 2021 All Rights Reserved Outz Pty Ltd
        </p>
      </div>
    </div>
  );
};

export default Footer;
