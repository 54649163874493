import { CheckStaff, EmailupUserlrs } from '../../api/api';
import React, { Fragment, useEffect, useState } from 'react';

import { Form } from 'react-bootstrap';

const Setting = () => {
    const user = JSON.parse(localStorage.getItem('current_user'));
    // //console.log(user);

    // const item = [{ Staff: '92', Owner: '91' }];
    const [email, setEmail] = useState('');
    const [level, setLevel] = useState('');
    const [staffList, setStaffList] = useState('');

    const handleClick = async () => {
        // console.log(level);
        // console.log(email);

        if (user.member_level === 91) {
            const click = await EmailupUserlrs(
                user.access_token,
                user.id,
                email,
                level,
                user.resid,
                user.servicetype
            );
            // console.log(click.data);
            if (click.data === 1) {
                window.location.reload();
            }
        }
    };
    useEffect(() => {
        const getStaff = async () => {
            const data = await CheckStaff(user.access_token, user.id, user.resid, user.servicetype);
            // console.log(data);
            setStaffList(data.data);
        };
        getStaff();
    }, []);
    // console.log(staffList);

    return (
        <Fragment>
            <div className="row mb-5 align-items-center">
                <div className="col-xl-12 col-lg-3 mb-4 mb-lg-0">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg order-lg mb-4">
                                    <div className="col-lg order-lg-1 justify-content-center">
                                        <h4 className="mb-3">Set Staff</h4>
                                        {/* <form className="needs-validation" noValidate=""> */}
                                        <div className="mb-3">
                                            <label htmlFor="email">
                                                Email
                                                {/* <span className="text-muted">(Optional)</span> */}
                                            </label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                onChange={(e) => setEmail(e.target.value)}
                                                // placeholder="you@example.com"
                                            />
                                            <div className="invalid-feedback">
                                                Please enter a valid email address for shipping
                                                updates.
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md mb-3">
                                                <label htmlFor="state">Staff/Owner</label>
                                                <Form.Control
                                                    as="select"
                                                    onChange={(e) => setLevel(e.target.value)}
                                                >
                                                    <option
                                                        style={{ color: 'blue' }}
                                                        // onChange={(e) =>
                                                        //     (e.target.value)
                                                        // }
                                                        value={92}
                                                    >
                                                        Staff
                                                    </option>

                                                    <option value={91}>Owner</option>
                                                    <option value={0}>Not a staff</option>
                                                </Form.Control>

                                                <div className="invalid-feedback">
                                                    Please provide a valid state.
                                                </div>
                                            </div>
                                        </div>

                                        <hr className="mb-4" />
                                        <button
                                            className="btn btn-primary btn-lg btn-block"
                                            // type="submit"
                                            onClick={handleClick}
                                        >
                                            Set
                                        </button>
                                        {/* </form> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="table-responsive">
                                <div id="customerList_1" className="dataTables_wrapper no-footer">
                                    <table
                                        className="table display mb-4 dataTablesCard dataTable no-footer"
                                        id="order_list"
                                    >
                                        <thead>
                                            <tr role="row">
                                                {/* <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Order ID: activate to sort column ascending"
                                            style={{ width: 63 }}
                                        >
                                            Order ID
                                        </th> */}
                                                {/* <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Date: activate to sort column ascending"
                                    style={{ width: 71 }}
                                >
                                    Date
                                </th> */}
                                                <th
                                                    className="sorting"
                                                    tabIndex={0}
                                                    aria-controls="example5"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Event NAME: activate to sort column ascending"
                                                    style={{ width: 117 }}
                                                >
                                                    Name
                                                </th>

                                                <th
                                                    className="sorting"
                                                    tabIndex={0}
                                                    aria-controls="example5"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    // aria-label="Location: activate to sort column ascending"
                                                    style={{ width: 71 }}
                                                >
                                                    Email
                                                </th>
                                                {/* <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example5"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Sold Ticket: activate to sort column ascending"
                                    style={{ width: 52 }}
                                >
                                    Status
                                </th>
                               */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(staffList) && staffList.length > 0 ? (
                                                staffList.map((item) => (
                                                    <tr role="row" className="odd">
                                                        <td className>{item.username}</td>
                                                        <td className>{item.email}</td>

                                                        {/* <td>{item.beizhu}</td> */}
                                                        {/* <td>{item.staffid}</td> */}
                                                        {/* <td className="sorting_1">
                                            {(item.amount / 100).toFixed(2)}
                                        </td> */}
                                                    </tr>
                                                ))
                                            ) : (
                                                <div>No Staff.</div>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Setting;
