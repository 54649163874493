import { Button, Spinner, Table, TableCell } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { GetUserfeedback } from "../../api/api";

const Feedback = () => {
	const [feedback, setFeedback] = useState([]);

	const user = JSON.parse(localStorage.getItem("current_user") || "{}");

	useEffect(() => {
		const getFeedback = async () => {
			const data = await GetUserfeedback(user.access_token);
			console.log(data.data);
			setFeedback(data.data);
		};
		getFeedback();
	}, []);

	return (
		<div className='col-xl align-self-start'>
			<div className='row'>
				<div className='col-lg-12'>
					<div className='card'>
						<div className='card-body'>
							{/* <h5 class='card-title'>Restauarnt</h5> */}

							<Table className='table-hover'>
								<tr>
									<th style={{ width: "5%" }}>ID</th>
									<th style={{ width: "10%" }}>User ID</th>
									<th style={{ width: "60%" }}>Content</th>
									<th style={{ width: "15%" }}>Time</th>
									<th style={{ width: "10%" }}>emotion</th>
								</tr>
								{Array.isArray(feedback) &&
								feedback.length > 0 ? (
									feedback.map((item, i) => (
										<tr
											className='cursor-pointer '
											// onClick={() => handleOnClick(item)}
										>
											<td>{i + 1}</td>
											<td>{item.userid}</td>
											<td>
												<p className='card-trext'>
													{item.content}
												</p>
											</td>
											<td>{item.timestamp}</td>
											<td>{item.emotion}</td>
										</tr>
									))
								) : (
									<p className='card-trext'>No Feedback</p>
								)}
							</Table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Feedback;
