import { Button, Dropdown, Modal } from "react-bootstrap";
import { GetMoviecoupons, NewMoviecoupons } from "../../../../api/api";
import React, { Fragment, useEffect, useState } from "react";

import { CSVReader } from "react-papaparse";
import PageTitle from "../../../../layouts/PageTitle";
import Products from "./Products";
import productData from "../productData";

// import { getData } from '../productData/allStoresData';
/// Data

const MovieCoupon = () => {
	const [movie, setMovie] = useState([]);
	const [movieCoupons, setMovieCoupons] = useState([]);
	const [show, setShow] = useState(false);
	const [storeNameEn, setStoreNameEn] = useState("");
	const [storeName, setStoreName] = useState("");
	const [address1, setAddress1] = useState("");
	const [address2, setAddress2] = useState("");
	const [gps, setGps] = useState("");
	const [onlineTime, setOnlineTime] = useState("");
	const [keywords, setKeywords] = useState("");
	const [tel, setTel] = useState("");
	const [accountName, setAccountName] = useState("");
	const [accountBSB, setAccountBSB] = useState("");
	const [accountNum, setAccountNum] = useState("");
	const [accountBank, setAccountBank] = useState("");
	const [telorder, setTelorder] = useState("");
	const [style, setStyle] = useState("");
	const [styleEn, setStyleEn] = useState("");
	const [follow, setFollow] = useState("");
	const [state, setState] = useState("");
	const [pic, setPic] = useState("");
	const [pic2, setPic2] = useState("");
	const [pic3, setPic3] = useState("");
	const [pic4, setPic4] = useState("");
	const [pic5, setPic5] = useState("");
	const [pic6, setPic6] = useState("");
	const [pic7, setPic7] = useState("");
	const [pic8, setPic8] = useState("");
	const [pic9, setPic9] = useState("");
	const [pic10, setPic10] = useState("");
	const [type, setType] = useState("");
	const [menu, setMenu] = useState("");
	const [dinnerMenu, setDinnerMenu] = useState("");
	const [drinkMenu, setDrinkMenu] = useState("");
	const [veganMenu, setVeganMenu] = useState("");
	const [specialMenu, setSpecialMenu] = useState("");
	const [hide_keywords, setHide_keywords] = useState("");
	const [promotion, setPromotion] = useState("");
	const [rules, setRules] = useState("");
	const [showCoupon, setShowCoupon] = useState(false);
	const [coupon, setCoupon] = useState();
	const [userid, setUserId] = useState("");

	console.log("coupon", coupon);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const handleShowCoupon = () => setShowCoupon(true);
	const handleCloseCoupon = () => {
		setShowCoupon(false);
		setCoupon();
	};
	const user = JSON.parse(localStorage.getItem("current_user") || "{}");
	useEffect(() => {
		// async function getBeautyData() {
		// 	const storeData = await GetBeauty(user.access_token);
		// 	setMovie(storeData.data);
		// }
		// getBeautyData();
		async function getMovieCoupons() {
			const coupon = await GetMoviecoupons(user.access_token);
			console.log(coupon);
			setMovieCoupons(coupon.data);
		}
		getMovieCoupons();
	}, []);

	const [chineseRules, setRulesChinese] = useState("");
	const [englishRules, setRulesEnglish] = useState("");

	var combine = JSON.stringify([chineseRules].concat([englishRules]));
	// console.log('combine', combine);

	// async function handleNewMovie() {
	// 	// console.log('add');

	// 	const newStore = await NewMovie(
	// 		user.access_token,
	// 		storeName,
	// 		address1,
	// 		address2,
	// 		gps,
	// 		onlineTime,
	// 		keywords,
	// 		tel,
	// 		accountName,
	// 		accountBSB,
	// 		accountNum,
	// 		accountBank,
	// 		telorder,
	// 		style,
	// 		follow,
	// 		state,
	// 		pic,
	// 		pic2,
	// 		pic3,
	// 		pic4,
	// 		pic5,
	// 		pic6,
	// 		pic7,
	// 		pic8,
	// 		pic9,
	// 		pic10,
	// 		"1",
	// 		menu,
	// 		dinnerMenu,
	// 		drinkMenu,
	// 		veganMenu,
	// 		specialMenu,
	// 		hide_keywords,
	// 		promotion,
	// 		combine,
	// 		storeNameEn,
	// 		styleEn
	// 	);
	// 	// console.log(newStore);
	// 	setShow(false);
	// 	window.location.reload();
	// }
	// console.log(coupon[0].data[3]);

	const handleUploadCSV = async () => {
		if (coupon.length > 0) {
			for (var i = 3; i < coupon.length; i++) {
				const data = await NewMoviecoupons(
					user.access_token,
					coupon[0].data[1],
					coupon[i].data[0],
					coupon[i].data[1],
					coupon[i].data[2],
					coupon[i].data[3],
					coupon[i].data[4],
					coupon[i].data[5],
					coupon[i].data[6],
					coupon[i].data[7],
					coupon[i].data[8],
					coupon[0].data[3],
					userid
				);
			}
		}
	};

	const handleOnDrop = (data) => {
		// console.log("drop", data);
		setCoupon(data);
	};

	const handleOnError = (err, file, inputElem, reason) => {
		console.log(err);
	};

	const handleOnRemoveFile = (data) => {
		console.log(data);
	};

	return (
		<Fragment>
			<div className='row w-100 p-4'>
				{/* <Button onClick={handleShow}>Add New Movie</Button> */}
				<Button onClick={handleShowCoupon}>Add New MovieCoupon</Button>
				{/* handleCloseCoupon*/}
				<Modal
					show={showCoupon}
					onHide={handleCloseCoupon}
					backdrop='static'
					keyboard={false}
					centered
				>
					<Modal.Header closeButton>
						<Modal.Title>New Movie Coupon</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<label
							htmlFor='Coupon'
							className='text-black font-w600'
						>
							Coupon
						</label>
						<CSVReader
							onDrop={handleOnDrop}
							onError={handleOnError}
							addRemoveButton
							onRemoveFile={handleOnRemoveFile}
						>
							<span>Drop CSV file here or click to upload.</span>
						</CSVReader>
						<Modal.Footer>
							<Button onClick={handleUploadCSV}>Upload</Button>
						</Modal.Footer>
					</Modal.Body>
				</Modal>
				<Modal
					show={show}
					onHide={handleClose}
					backdrop='static'
					keyboard={false}
					centered
				>
					<Modal.Header closeButton>
						<Modal.Title>New Movie</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<label htmlFor='name' className='text-black font-w600'>
							Name <span className='required'>*</span>
						</label>
						<input
							type='text'
							label='Name'
							className='form-control'
							// defaultValue="store name"
							name='name'
							placeholder='store name'
							onChange={(e) => setStoreName(e.target.value)}
						/>
						<label htmlFor='name' className='text-black font-w600'>
							English Name <span className='required'>*</span>
						</label>
						<input
							type='text'
							label='Name'
							className='form-control'
							// defaultValue="store name"
							name='name'
							placeholder='store name'
							onChange={(e) => setStoreNameEn(e.target.value)}
						/>
						<label
							htmlFor='address'
							className='text-black font-w600'
						>
							address1
						</label>
						<input
							type='text'
							label='address'
							className='form-control'
							// defaultValue="address"
							name='address'
							placeholder='address'
							onChange={(e) => setAddress1(e.target.value)}
						/>
						<label
							htmlFor='address'
							className='text-black font-w600'
						>
							address2
						</label>
						<input
							type='text'
							label='address'
							className='form-control'
							// defaultValue="address"
							name='address'
							placeholder='address'
							onChange={(e) => setAddress2(e.target.value)}
						/>
						<label htmlFor='gps' className='text-black font-w600'>
							gps
						</label>
						<input
							type='text'
							label='gps'
							className='form-control'
							// defaultValue="gps"
							name='gps'
							onChange={(e) => setGps(e.target.value)}
							placeholder='gps'
						/>

						<label
							htmlFor='keywords'
							className='text-black font-w600'
						>
							keywords
						</label>
						<input
							type='text'
							label='keywords'
							className='form-control'
							defaultValue='keywords'
							name='keywords'
							onChange={(e) => setKeywords(e.target.value)}
							placeholder='keywords'
						/>
						<label
							htmlFor='mobile'
							className='text-black font-w600'
						>
							mobile
						</label>
						<input
							type='text'
							label='mobile'
							className='form-control'
							// defaultValue="mobile"
							name='mobile'
							onChange={(e) => setTel(e.target.value)}
							placeholder='mobile'
						/>
						<label
							htmlFor='accountName'
							className='text-black font-w600'
						>
							accountName
						</label>
						<input
							type='text'
							label='accountName'
							className='form-control'
							// defaultValue="accountName"
							name='accountName'
							onChange={(e) => setAccountName(e.target.value)}
							placeholder='accountName'
						/>
						<label
							htmlFor='accountBSB'
							className='text-black font-w600'
						>
							accountBSB
						</label>
						<input
							type='text'
							label='accountBSB'
							className='form-control'
							// defaultValue="accountBSB"
							onChange={(e) => setAccountBSB(e.target.value)}
							name='accountBSB'
							placeholder='accountBSB'
						/>
						<label
							htmlFor='accountNum'
							className='text-black font-w600'
						>
							accountNum
						</label>
						<input
							type='text'
							label='accountNum'
							onChange={(e) => setAccountNum(e.target.value)}
							className='form-control'
							// defaultValue="accountNum"
							name='accountNum'
							placeholder='accountNum'
						/>
						<label
							htmlFor='accountBank'
							className='text-black font-w600'
						>
							accountBank
						</label>
						<input
							type='text'
							label='accountBank'
							onChange={(e) => setAccountBank(e.target.value)}
							className='form-control'
							// defaultValue="accountBank"
							name='accountBank'
							placeholder='accountBank'
						/>

						<label htmlFor='style' className='text-black font-w600'>
							style
						</label>
						<input
							type='text'
							label='style'
							className='form-control'
							onChange={(e) => setStyle(e.target.value)}
							// defaultValue="style"
							name='style'
							placeholder='style'
						/>
						<label htmlFor='style' className='text-black font-w600'>
							English style
						</label>
						<input
							type='text'
							label='style'
							className='form-control'
							onChange={(e) => setStyleEn(e.target.value)}
							// defaultValue="style"
							name='style'
							placeholder='style'
						/>

						<label htmlFor='pic' className='text-black font-w600'>
							pic
						</label>
						<input
							type='file'
							label='pic'
							className='form-control'
							onChange={(e) => setPic(e.target.files[0])}
							// defaultValue="pic"
							name='pic'
							placeholder='pic'
						/>
						<label htmlFor='pic2' className='text-black font-w600'>
							pic2
						</label>
						<input
							type='file'
							label='pic2'
							className='form-control'
							onChange={(e) => setPic2(e.target.files[0])}
							// defaultValue="pic2"
							name='pic2'
							placeholder='pic2'
						/>
						<label htmlFor='pic3' className='text-black font-w600'>
							pic3
						</label>
						<input
							type='file'
							label='pic3'
							className='form-control'
							onChange={(e) => setPic3(e.target.files[0])}
							// defaultValue="pic3"
							name='pic3'
							placeholder='pic3'
						/>
						<label htmlFor='pic4' className='text-black font-w600'>
							pic4
						</label>
						<input
							type='file'
							label='pic4'
							className='form-control'
							onChange={(e) => setPic4(e.target.files[0])}
							// defaultValue="pic4"
							name='pic4'
							placeholder='pic4'
						/>
						<label htmlFor='pic5' className='text-black font-w600'>
							pic5
						</label>
						<input
							type='file'
							label='pic5'
							className='form-control'
							onChange={(e) => setPic5(e.target.files[0])}
							// defaultValue="pic5"
							name='pic5'
							placeholder='pic5'
						/>
						<label htmlFor='pic6' className='text-black font-w600'>
							pic6
						</label>
						<input
							type='file'
							label='pic6'
							className='form-control'
							onChange={(e) => setPic6(e.target.files[0])}
							// defaultValue="pic6"
							name='pic6'
							placeholder='pic6'
						/>
						<label htmlFor='pic7' className='text-black font-w600'>
							pic7
						</label>
						<input
							type='file'
							label='pic7'
							className='form-control'
							onChange={(e) => setPic7(e.target.files[0])}
							// defaultValue="pic7"
							name='pic7'
							placeholder='pic7'
						/>
						<label htmlFor='pic8' className='text-black font-w600'>
							pic8
						</label>
						<input
							type='file'
							label='pic8'
							className='form-control'
							onChange={(e) => setPic8(e.target.files[0])}
							// defaultValue="pic8"
							name='pic8'
							placeholder='pic8'
						/>
						<label htmlFor='pic9' className='text-black font-w600'>
							pic9
						</label>
						<input
							type='file'
							label='pic9'
							className='form-control'
							onChange={(e) => setPic9(e.target.files[0])}
							// defaultValue="pic9"
							name='pic9'
							placeholder='pic9'
						/>
						<label htmlFor='pic10' className='text-black font-w600'>
							pic10
						</label>
						<input
							type='file'
							label='pic10'
							className='form-control'
							onChange={(e) => setPic10(e.target.files[0])}
							// defaultValue="pic10"
							name='pic10'
							placeholder='pic10'
						/>

						<label htmlFor='menu' className='text-black font-w600'>
							menu
						</label>
						<input
							type='file'
							label='menu'
							className='form-control'
							onChange={(e) => setMenu(e.target.files[0])}
							// defaultValue="menu"
							name='menu'
							placeholder='menu'
						/>

						<label
							htmlFor='hide_keywords'
							className='text-black font-w600'
						>
							hide_keywords
						</label>
						<input
							type='text'
							label='hide_keywords'
							onChange={(e) => setHide_keywords(e.target.value)}
							className='form-control'
							// defaultValue="hide_keywords"
							name='hide_keywords'
							placeholder='hide_keywords'
						/>
						<label
							htmlFor='promotion'
							className='text-black font-w600'
						>
							promotion
						</label>
						<input
							type='text'
							label='promotion'
							className='form-control'
							onChange={(e) => setPromotion(e.target.value)}
							// defaultValue="promotion"
							name='promotion'
							placeholder='promotion'
						/>
						<label htmlFor='rules' className='text-black font-w600'>
							Rules English
						</label>
						<textarea
							// type="text"
							label='rules'
							className='form-control'
							onChange={(e) => setRulesEnglish(e.target.value)}
							defaultValue={englishRules}
							name='rules'
							placeholder='rules'
							// col="40"
							rows='5'
						></textarea>
						<label htmlFor='rules' className='text-black font-w600'>
							Rules Chinese
						</label>
						<textarea
							// type="text"
							label='rules'
							className='form-control'
							onChange={(e) => setRulesChinese(e.target.value)}
							defaultValue={chineseRules}
							name='rules'
							placeholder='rules'
							// col="40"
							rows='5'
						></textarea>
					</Modal.Body>
					<Modal.Footer>
						<Button variant='secondary' onClick={handleClose}>
							Close
						</Button>
						<Button
							variant='primary'
							// onClick={handleNewMovie}
						>
							Add
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
			<div className='row'>
				{Array.isArray(movie) &&
					movie.length > 0 &&
					movie.map((product) => (
						<Products key={product.id} product={product} />
					))}
			</div>
			<div className='row'>
				{Array.isArray(movieCoupons) &&
					movieCoupons.length > 0 &&
					movieCoupons.map((item, index) => (
						<div>
							<div className='col-lg-12'>
								<div className='card'>
									<div className='card-body'>
										<p className='card-trext'>
											id: {"" + item.id}
										</p>
										<p className='card-trext'>
											orderID: {"" + item.orderID}
										</p>
										<p className='card-trext'>
											orderLineID: {"" + item.orderLineID}
										</p>
										<p className='card-trext'>
											parentVoucherType:{" "}
											{"" + item.parentVoucherType}
										</p>
										<p className='card-trext'>
											voucherType: {"" + item.voucherType}
										</p>
										<p className='card-trext'>
											serialNumber:{" "}
											{"" + item.serialNumber}
										</p>
										<p className='card-trext'>
											randomSerial:{" "}
											{"" + item.randomSerial}
										</p>
										<p className='card-trext'>
											randomDigits:{" "}
											{"" + item.randomDigits}
										</p>
										<p className='card-trext'>
											barcode: {"" + item.barcode}
										</p>
										<p className='card-trext'>
											bookletIdentifier:{" "}
											{"" + item.bookletIdentifier}
										</p>
										<p className='card-trext'>
											pin: {"" + item.pin}
										</p>
										<p className='card-trext'>
											exportedDate:{" "}
											{"" + item.exportedDate}
										</p>
										<p className='card-trext'>
											timestamp: {"" + item.timestamp}
										</p>
										<p className='card-trext'>
											sold: {"" + item.sold}
										</p>
										<p className='card-trext'>
											used: {"" + item.used}
										</p>
										<p className='card-trext'>
											userid: {"" + item.userid}
										</p>
									</div>
								</div>
							</div>
						</div>
					))}
			</div>
		</Fragment>
	);
};

export default MovieCoupon;
