import { Button, Card, Col, Modal, Nav, Row } from "react-bootstrap";
import {
	DateGetSpend,
	GetBarber,
	GetBeauty,
	GetBubbletea,
	GetCarRepairs,
	GetEntertainment,
	GetFitness,
	GetMassage,
	GetRestaurant,
	GetSupermarket,
} from "../../../../api/api";
import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import PageTitle from "../../../../layouts/PageTitle";
import SearchAllSpend from "./components/SearchAllSpend";
import SearchSpend from "./components/SearchSpend";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import productData from "../productData";

const Statistics = () => {
	//** Dynamic Key Single Place */
	const user = JSON.parse(localStorage.getItem("current_user") || "{}");
	const [bubble, setBubble] = useState([]);
	const [beauty, setBeauty] = useState([]);
	const [entertainment, setEntertainment] = useState([]);
	const [restaurant, setRestaurant] = useState([]);
	const [sup, setSup] = useState([]);
	const [fitness, setFitness] = useState([]);
	const [massage, setMassage] = useState([]);
	const [barber, setBarber] = useState([]);
	const [car, setCar] = useState([]);
	const [value, setValue] = useState(null);
	useEffect(() => {
		async function getData() {
			const storeData = await GetBubbletea(user.access_token);
			// //console.log('--------', storeData.data);
			setBubble(storeData.data);
			const storeData1 = await GetBeauty(user.access_token);
			// console.log('--------', storeData.data);
			setBeauty(storeData1.data);
			const storeData2 = await GetEntertainment(user.access_token);
			//console.log('--------', storeData.data);
			setEntertainment(storeData2.data);
			const storeData3 = await GetRestaurant(user.access_token);
			////console.log('--------', storeData.data);
			setRestaurant(storeData3.data);
			const storeSup = await GetSupermarket(user.access_token);
			setSup(storeSup.data);

			const storeCar = await GetCarRepairs(user.access_token);
			setCar(storeCar.data);

			//GetFitness
			// GetMassage;
			// GetBarber;
			const storeFitness = await GetFitness(user.access_token);
			setFitness(storeFitness.data);
			const storeMassage = await GetMassage(user.access_token);
			setMassage(storeMassage.data);
			const storeBarber = await GetBarber(user.access_token);
			setBarber(storeBarber.data);
		}
		getData();
	}, []);
	console.log(restaurant);

	const [show, setShow] = useState(false);
	const [activeModal, setActiveModal] = useState(null);

	const handleOpen = ({ item }, index) => {
		setShow(true);
		// setValue(item);
		setActiveModal(index);
		console.log(item);
		// console.log(show);
		// console.log(handleClose);
		// if (item) {

		// }
	};
	const handleClose = () => setActiveModal(null);

	const [showSearch, setShowSearch] = useState(false);
	const handleCloseSearch = () => setShowSearch(false);
	const handleOpenSearch = () => setShowSearch(true);

	return (
		<div className='h-80'>
			<div className='row mb-8'>
				<div className='col-lg-12 '>
					<Row
						lg={12}
						className='float-right '
						style={{ marginRight: "4px", marginBottom: "4px" }}
					>
						<Col>
							<Button
								variant='primary'
								onClick={handleOpenSearch}
							>
								Search
							</Button>
							<SearchAllSpend
								show={showSearch}
								handleClose={handleCloseSearch}
								// value={value}
								// item={item}
							></SearchAllSpend>
						</Col>
					</Row>
				</div>
			</div>
			{/* <PageTitle activeMenu="Blank" motherMenu="Layout" /> */}
			<div className='row'>
				<div className='col-lg-12'>
					<Row>
						<Col lg={12}>
							<Card>
								<Card.Header>
									<Card.Title>Restaurants</Card.Title>
								</Card.Header>
								<Card.Body>
									<Table responsive hover>
										<TableHead>
											<TableRow>
												<TableCell>Name</TableCell>
												<TableCell className='float-right'>
													Expenses record
												</TableCell>
												{/* <th>Date</th>
                                                <th>Price</th> */}
											</TableRow>
										</TableHead>
										<TableBody>
											{Array.isArray(restaurant) &&
												restaurant.length > 0 &&
												restaurant.map(
													(item, index) => (
														<TableRow>
															<TableCell>
																{item.name}
															</TableCell>
															<TableCell>
																<Button
																	className='float-right'
																	variant='primary'
																	onClick={() =>
																		handleOpen(
																			{
																				item,
																			},
																			item.name
																		)
																	}
																>
																	Search Spend
																</Button>
																{activeModal ===
																	item.name && (
																	<SearchSpend
																		show={
																			activeModal ===
																			item.name
																		}
																		handleClose={
																			handleClose
																		}
																		// value={value}
																		item={
																			item
																		}
																	></SearchSpend>
																)}
															</TableCell>

															{/* <td>January 22</td>
                                                <td className="color-primary">$21.56</td> */}
														</TableRow>
													)
												)}
										</TableBody>
									</Table>
								</Card.Body>
							</Card>
							{/* <!-- /# card --> */}
						</Col>
						<Col lg={12}>
							<Card>
								<Card.Header>
									<Card.Title>Bubble Tea</Card.Title>
								</Card.Header>
								<Card.Body>
									<Table responsive hover>
										<TableHead>
											<TableRow>
												<TableCell>Name</TableCell>
												<TableCell className='float-right'>
													Expenses record
												</TableCell>
												{/* <th>Date</th>
                                                <th>Price</th> */}
											</TableRow>
										</TableHead>
										<TableBody>
											{Array.isArray(bubble) &&
												bubble.length > 0 &&
												bubble.map((item, i) => (
													<TableRow>
														<TableCell>
															{item.name}
														</TableCell>
														<TableCell>
															<Button
																className='float-right'
																variant='primary'
																onClick={() =>
																	handleOpen(
																		{
																			item,
																		},
																		item.name
																	)
																}
															>
																Search Spend
															</Button>
															{activeModal ===
																item.name && (
																<SearchSpend
																	show={
																		activeModal ===
																		item.name
																	}
																	handleClose={
																		handleClose
																	}
																	// value={value}
																	item={item}
																></SearchSpend>
															)}
														</TableCell>

														{/* <td>January 22</td>
                                                <td className="color-primary">$21.56</td> */}
													</TableRow>
												))}
										</TableBody>
									</Table>
								</Card.Body>
							</Card>
							{/* <!-- /# card --> */}
						</Col>
						<Col lg={12}>
							<Card>
								<Card.Header>
									<Card.Title>Beauty</Card.Title>
								</Card.Header>
								<Card.Body>
									<Table responsive hover>
										<TableHead>
											<TableRow>
												<TableCell>Name</TableCell>
												<TableCell className='float-right'>
													Expenses record
												</TableCell>
												{/* <th>Date</th>
                                                <th>Price</th> */}
											</TableRow>
										</TableHead>
										<TableBody>
											{Array.isArray(beauty) &&
												beauty.length > 0 &&
												beauty.map((item, i) => (
													<TableRow>
														<TableCell>
															{item.name}
														</TableCell>
														<TableCell>
															<Button
																className='float-right'
																variant='primary'
																onClick={() =>
																	handleOpen(
																		{
																			item,
																		},
																		item.name
																	)
																}
															>
																Search Spend
															</Button>
															{activeModal ===
																item.name && (
																<SearchSpend
																	show={
																		activeModal ===
																		item.name
																	}
																	handleClose={
																		handleClose
																	}
																	// value={value}
																	item={item}
																></SearchSpend>
															)}
														</TableCell>

														{/* <td>January 22</td>
                                                <td className="color-primary">$21.56</td> */}
													</TableRow>
												))}
										</TableBody>
									</Table>
								</Card.Body>
							</Card>
							{/* <!-- /# card --> */}
						</Col>
						<Col lg={12}>
							<Card>
								<Card.Header>
									<Card.Title>Entertainment</Card.Title>
								</Card.Header>
								<Card.Body>
									<Table responsive hover>
										<TableHead>
											<TableRow>
												<TableCell>Name</TableCell>
												<TableCell className='float-right'>
													Expenses record
												</TableCell>
												{/* <th>Date</th>
                                                <th>Price</th> */}
											</TableRow>
										</TableHead>
										<TableBody>
											{Array.isArray(entertainment) &&
												entertainment.length > 0 &&
												entertainment.map((item, i) => (
													<TableRow>
														<TableCell>
															{item.name}
														</TableCell>
														<TableCell>
															<Button
																className=' float-right'
																variant='primary'
																onClick={() =>
																	handleOpen(
																		{
																			item,
																		},
																		item.name
																	)
																}
															>
																Search Spend
															</Button>
															{activeModal ===
																item.name && (
																<SearchSpend
																	show={
																		activeModal ===
																		item.name
																	}
																	handleClose={
																		handleClose
																	}
																	// value={value}
																	item={item}
																></SearchSpend>
															)}
														</TableCell>

														{/* <td>January 22</td>
                                                <td className="color-primary">$21.56</td> */}
													</TableRow>
												))}
										</TableBody>
									</Table>
								</Card.Body>
							</Card>
							{/* <!-- /# card --> */}
						</Col>
						<Col lg={12}>
							<Card>
								<Card.Header>
									<Card.Title>Supermarket</Card.Title>
								</Card.Header>
								<Card.Body>
									<Table responsive hover>
										<TableHead>
											<TableRow>
												<TableCell>Name</TableCell>
												<TableCell className='float-right'>
													Expenses record
												</TableCell>
												{/* <th>Date</th>
                                                <th>Price</th> */}
											</TableRow>
										</TableHead>
										<TableBody>
											{Array.isArray(sup) &&
												sup.length > 0 &&
												sup.map((item, i) => (
													<TableRow>
														<TableCell>
															{item.name}
														</TableCell>
														<TableCell>
															<Button
																className=' float-right'
																variant='primary'
																onClick={() =>
																	handleOpen(
																		{
																			item,
																		},
																		item.name
																	)
																}
															>
																Search Spend
															</Button>
															{activeModal ===
																item.name && (
																<SearchSpend
																	show={
																		activeModal ===
																		item.name
																	}
																	handleClose={
																		handleClose
																	}
																	// value={value}
																	item={item}
																></SearchSpend>
															)}
														</TableCell>

														{/* <td>January 22</td>
                                                <td className="color-primary">$21.56</td> */}
													</TableRow>
												))}
										</TableBody>
									</Table>
								</Card.Body>
							</Card>
							{/* <!-- /# card --> */}
						</Col>
						<Col lg={12}>
							<Card>
								<Card.Header>
									<Card.Title>Car Repair</Card.Title>
								</Card.Header>
								<Card.Body>
									<Table responsive hover>
										<TableHead>
											<TableRow>
												<TableCell>Name</TableCell>
												<TableCell className='float-right'>
													Expenses record
												</TableCell>
												{/* <th>Date</th>
                                                <th>Price</th> */}
											</TableRow>
										</TableHead>
										<TableBody>
											{Array.isArray(car) &&
												car.length > 0 &&
												car.map((item, i) => (
													<TableRow>
														<TableCell>
															{item.name}
														</TableCell>
														<TableCell>
															<Button
																className=' float-right'
																variant='primary'
																onClick={() =>
																	handleOpen(
																		{
																			item,
																		},
																		item.name
																	)
																}
															>
																Search Spend
															</Button>
															{activeModal ===
																item.name && (
																<SearchSpend
																	show={
																		activeModal ===
																		item.name
																	}
																	handleClose={
																		handleClose
																	}
																	// value={value}
																	item={item}
																></SearchSpend>
															)}
														</TableCell>

														{/* <td>January 22</td>
                                                <td className="color-primary">$21.56</td> */}
													</TableRow>
												))}
										</TableBody>
									</Table>
								</Card.Body>
							</Card>
							{/* <!-- /# card --> */}
						</Col>
						<Col lg={12}>
							<Card>
								<Card.Header>
									<Card.Title>Fitness</Card.Title>
								</Card.Header>
								<Card.Body>
									<Table responsive hover>
										<TableHead>
											<TableRow>
												<TableCell>Name</TableCell>
												<TableCell className='float-right'>
													Expenses record
												</TableCell>
												{/* <th>Date</th>
                                                <th>Price</th> */}
											</TableRow>
										</TableHead>
										<TableBody>
											{Array.isArray(fitness) &&
												fitness.length > 0 &&
												fitness.map((item, i) => (
													<TableRow>
														<TableCell>
															{item.name}
														</TableCell>
														<TableCell>
															<Button
																className=' float-right'
																variant='primary'
																onClick={() =>
																	handleOpen(
																		{
																			item,
																		},
																		item.name
																	)
																}
															>
																Search Spend
															</Button>
															{activeModal ===
																item.name && (
																<SearchSpend
																	show={
																		activeModal ===
																		item.name
																	}
																	handleClose={
																		handleClose
																	}
																	// value={value}
																	item={item}
																></SearchSpend>
															)}
														</TableCell>

														{/* <td>January 22</td>
                                                <td className="color-primary">$21.56</td> */}
													</TableRow>
												))}
										</TableBody>
									</Table>
								</Card.Body>
							</Card>
							{/* <!-- /# card --> */}
						</Col>
						<Col lg={12}>
							<Card>
								<Card.Header>
									<Card.Title>Massage</Card.Title>
								</Card.Header>
								<Card.Body>
									<Table responsive hover>
										<TableHead>
											<TableRow>
												<TableCell>Name</TableCell>
												<TableCell className='float-right'>
													Expenses record
												</TableCell>
												{/* <th>Date</th>
                                                <th>Price</th> */}
											</TableRow>
										</TableHead>
										<TableBody>
											{Array.isArray(massage) &&
												massage.length > 0 &&
												massage.map((item, i) => (
													<TableRow>
														<TableCell>
															{item.name}
														</TableCell>
														<TableCell>
															<Button
																className=' float-right'
																variant='primary'
																onClick={() =>
																	handleOpen(
																		{
																			item,
																		},
																		item.name
																	)
																}
															>
																Search Spend
															</Button>
															{activeModal ===
																item.name && (
																<SearchSpend
																	show={
																		activeModal ===
																		item.name
																	}
																	handleClose={
																		handleClose
																	}
																	// value={value}
																	item={item}
																></SearchSpend>
															)}
														</TableCell>

														{/* <td>January 22</td>
                                                <td className="color-primary">$21.56</td> */}
													</TableRow>
												))}
										</TableBody>
									</Table>
								</Card.Body>
							</Card>
							{/* <!-- /# card --> */}
						</Col>
						<Col lg={12}>
							<Card>
								<Card.Header>
									<Card.Title>Barber</Card.Title>
								</Card.Header>
								<Card.Body>
									<Table responsive hover>
										<TableHead>
											<TableRow>
												<TableCell>Name</TableCell>
												<TableCell className='float-right'>
													Expenses record
												</TableCell>
												{/* <th>Date</th>
                                                <th>Price</th> */}
											</TableRow>
										</TableHead>
										<TableBody>
											{Array.isArray(barber) &&
												barber.length > 0 &&
												barber.map((item, i) => (
													<TableRow>
														<TableCell>
															{item.name}
														</TableCell>
														<TableCell>
															<Button
																className=' float-right'
																variant='primary'
																onClick={() =>
																	handleOpen(
																		{
																			item,
																		},
																		item.name
																	)
																}
															>
																Search Spend
															</Button>
															{activeModal ===
																item.name && (
																<SearchSpend
																	show={
																		activeModal ===
																		item.name
																	}
																	handleClose={
																		handleClose
																	}
																	// value={value}
																	item={item}
																></SearchSpend>
															)}
														</TableCell>

														{/* <td>January 22</td>
                                                <td className="color-primary">$21.56</td> */}
													</TableRow>
												))}
										</TableBody>
									</Table>
								</Card.Body>
							</Card>
							{/* <!-- /# card --> */}
						</Col>
					</Row>
				</div>
			</div>
		</div>
	);
};

export default Statistics;
