import { Button, Card, Col, Modal, Nav, Row } from "react-bootstrap";
import {
	DeleteAdvertise,
	GetAdvertise,
	NewAdvertise,
} from "../../../../api/api";
import { Link, useHistory } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import PageTitle from "../../../../layouts/PageTitle";
import SearchAllSpend from "./components/SearchAllSpend";
import SearchSpend from "./components/SearchSpend";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import productData from "../productData";

const Advertisement = () => {
	//** Dynamic Key Single Place */
	const user = JSON.parse(localStorage.getItem("current_user") || "{}");
	const history = useHistory();
	const [picurl, setPicurl] = useState("");
	const [title, setTitle] = useState("");
	const [type, setType] = useState("");
	const [servicetype, setServicetype] = useState("");
	const [resid, setResid] = useState("");
	const [jumpurl, setJumpurl] = useState("");
	const [imageurl, setImageurl] = useState("");
	const [ads, setAds] = useState([]);
	useEffect(() => {
		async function getData() {
			const data = await GetAdvertise(user.access_token);
			console.log(data);
			setAds(data.data);
		}
		getData();
	}, []);

	const handleNewAds = async () => {
		const data = await NewAdvertise(
			user.access_token,
			title,
			picurl,
			type,
			servicetype,
			resid,
			jumpurl,
			imageurl
		);
		console.log(data);
		window.location.reload();
	};

	const handleDeleteAds = async (id) => {
		console.log(id);
		const data = await DeleteAdvertise(user.access_token, id);
		console.log(data);
		window.location.reload();
	};

	return (
		<div className='h-80'>
			<div className='row'>
				<div className='col-lg-12'>
					<div className='card'>
						<div className='card-body '>
							<label
								htmlFor='name'
								className='text-black font-w600'
							>
								Title <span className='required'>*</span>
							</label>
							<input
								type='text'
								label='title'
								className='form-control'
								name='title'
								placeholder='title'
								onChange={(e) => setTitle(e.target.value)}
							/>
							<label
								htmlFor='content'
								className='text-black font-w600'
							>
								type
							</label>
							<input
								type='text'
								label='type'
								className='form-control'
								// defaultValue="type"
								name='type'
								placeholder='type'
								onChange={(e) => setType(e.target.value)}
							/>

							<label
								htmlFor='keywords'
								className='text-black font-w600'
							>
								servicetype
							</label>
							<input
								type='text'
								label='servicetype'
								className='form-control'
								// defaultValue='keywords'
								name='servicetype'
								onChange={(e) => setServicetype(e.target.value)}
								// placeholder='keywords'
							/>
							<label
								htmlFor='keywords'
								className='text-black font-w600'
							>
								resid
							</label>
							<input
								type='text'
								label='resid'
								className='form-control'
								// defaultValue='keywords'
								name='resid'
								onChange={(e) => setResid(e.target.value)}
								// placeholder='keywords'
							/>
							<label
								htmlFor='keywords'
								className='text-black font-w600'
							>
								jumpurl
							</label>
							<input
								type='text'
								label='jumpurl'
								className='form-control'
								// defaultValue='keywords'
								name='jumpurl'
								onChange={(e) => setJumpurl(e.target.value)}
								// placeholder='keywords'
							/>
							<label
								htmlFor='keywords'
								className='text-black font-w600'
							>
								imageurl
							</label>
							<input
								type='text'
								label='imageurl'
								className='form-control'
								// defaultValue='keywords'
								name='imageurl'
								onChange={(e) => setImageurl(e.target.value)}
								// placeholder='keywords'
							/>
							<label
								htmlFor='keywords'
								className='text-black font-w600'
							>
								picurl
							</label>
							<input
								type='text'
								label='picurl'
								className='form-control'
								// defaultValue='keywords'
								name='picurl'
								onChange={(e) => setPicurl(e.target.value)}
								// placeholder='keywords'
							/>
							<div className='d-flex  justify-content-center align-items-center mb-2 mt-2'>
								<Button
									variant='primary'
									onClick={handleNewAds}
								>
									Add
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='col-lg-12'>
				{Array.isArray(ads) &&
					ads.length > 0 &&
					ads.map((item) => (
						<div className='card'>
							<div className='card-body '>
								{item.tittle && (
									<h4 style={{ marginBottom: "20px" }}>
										Title: {item.tittle}
									</h4>
								)}
								{item.type && <p>Type: {item.type}</p>}
								{item.servicetype && (
									<p>Servicetype: {item.servicetype}</p>
								)}
								{item.resid && <p>Resid: {item.resid}</p>}
								{item.jumpurl && <p>Jumpurl: {item.jumpurl}</p>}
								{item.imageurl && (
									<p>Iamgeurl:{item.imageurl}</p>
								)}

								<div className='d-flex  justify-content-center align-items-center mb-2'>
									<Button
										variant='primary'
										onClick={() => handleDeleteAds(item.id)}
									>
										Delete
									</Button>
								</div>
							</div>
						</div>
					))}
			</div>
		</div>
	);
};

export default Advertisement;
