import React, { useEffect, useRef, useState } from 'react';

import { Link } from 'react-router-dom';
import Rating from 'react-rating';
import avatar1 from '../../.././images/avatar/1.jpg';
import avatar2 from '../../.././images/avatar/2.jpg';
import avatar3 from '../../.././images/avatar/3.jpg';
import avatar4 from '../../.././images/avatar/4.jpg';
import avatar5 from '../../.././images/avatar/5.jpg';
import avatar6 from '../../.././images/avatar/6.jpg';
import { getReview } from '../../api/api';

const AllReview = () => {
    const [data, setData] = useState(document.querySelectorAll('#all_review tbody tr'));
    const sort = 5;
    const activePag = useRef(0);
    const [test, settest] = useState(0);
    const [review, setReview] = useState([]);
    const user = JSON.parse(localStorage.getItem('current_user'));

    // Active data
    const chageData = (frist, sec) => {
        for (var i = 0; i < data.length; ++i) {
            if (i >= frist && i < sec) {
                data[i].classList.remove('d-none');
            } else {
                data[i].classList.add('d-none');
            }
        }
    };

    useEffect(() => {
        const getData = async () => {
            const reviewData = await getReview(user.access_token, user.resid, user.servicetype);
            if (Array.isArray(reviewData.data)) {
                setReview(reviewData.data);
            }
        };
        getData();
    }, []);
    // use effect
    useEffect(() => {
        setData(document.querySelectorAll('#all_review tbody tr'));
        chackboxFun();
    }, [test]);

    // Active pagginarion
    activePag.current === 0 && chageData(0, sort);
    // paggination
    let paggination = Array(Math.ceil(data.length / sort))
        .fill()
        .map((_, i) => i + 1);

    // Active paggination & chage data
    const onClick = (i) => {
        activePag.current = i;
        chageData(activePag.current * sort, (activePag.current + 1) * sort);
        settest(i);
    };
    const chackbox = document.querySelectorAll('.all_review_checkbox input');
    const motherChackBox = document.querySelector('.all_review_strg input');
    const chackboxFun = (type) => {
        for (let i = 0; i < chackbox.length; i++) {
            const element = chackbox[i];
            if (type === 'all') {
                if (motherChackBox.checked) {
                    element.checked = true;
                } else {
                    element.checked = false;
                }
            } else {
                if (!element.checked) {
                    motherChackBox.checked = false;
                    break;
                } else {
                    motherChackBox.checked = true;
                }
            }
        }
    };
    return (
        <div className="table-responsive table-hover fs-14">
            <div id="all_review" className="dataTables_wrapper no-footer">
                <table
                    className="table display mb-4 dataTablesCard fs-14  dataTable no-footer"
                    id="example5"
                    role="grid"
                    aria-describedby="example5_info"
                >
                    <thead>
                        <tr role="row" className="all_review_strg">
                            <th style={{ width: '40px' }}>
                                {/* <div className="checkbox  align-self-center">
                                    <div className="custom-control custom-checkbox checkbox-info">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="customCheckBox0001"
                                            required
                                            onClick={() => chackboxFun('all')}
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor="customCheckBox0001"
                                        />
                                    </div>
                                </div> */}
                            </th>
                            {/* <th style={{ width: "250px" }}>Title</th> */}
                            <th style={{ width: '90.1406px' }}> Title</th>
                            <th style={{ width: '376px' }}> Review</th>
                            {/* <th style={{ width: '191px' }}>Action</th> */}
                        </tr>{' '}
                    </thead>
                    <tbody>
                        {review.length > 0 &&
                            review.map((item) => (
                                <tr role="row" className="odd">
                                    <td className="all_review_checkbox">
                                        {/* <div className="checkbox  align-self-center">
                                            <div className="custom-control custom-checkbox checkbox-info">
                                                <input
                                                    type="checkbox"
                                                    onClick={() => chackboxFun()}
                                                    className="custom-control-input"
                                                    id="customCheckBox00002"
                                                    required
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="customCheckBox00002"
                                                />
                                            </div>
                                        </div> */}
                                    </td>
                                    {/* <td>
                               <div className="media align-items-center">
                                  
                                   <div className="media-body">
                                       <h4 className="text-black font-w600 mb-1 wspace-no">
                                           Glee Smiley
                                       </h4>
                                   </div>
                               </div>
                           </td> */}
                                    <td>{item.title}</td>
                                    <td>
                                        <span>
                                            <Rating
                                                initialRating={item.rating}
                                                readonly
                                                style={{
                                                    marginLeft: '6px',
                                                    fontSize: '8px',
                                                }}
                                                emptySymbol="fa fa-star-o fa-2x"
                                                fullSymbol="fa fa-star fa-2x text-orange"
                                                fractions={2}
                                                // placeholderRating={5}
                                            />
                                        </span>
                                        <br />
                                        <p
                                            className="mb-0 d-none d-xl-inline-block"
                                            style={{
                                                marginLeft: '6px',
                                                fontSize: '16px',
                                            }}
                                        >
                                            {item.content}
                                        </p>
                                    </td>
                                    {/* <td>
                               <div className="d-flex">
                                   <Link to="/reviews" className="btn btn-primary btn-sm px-4">
                                       Publish
                                   </Link>
                                   <Link to="/reviews" className="btn btn-danger  btn-sm ml-2 px-4">
                                       Delete
                                   </Link>
                               </div>
                           </td> */}
                                </tr>
                            ))}
                    </tbody>
                </table>

                {/* <div className="d-sm-flex text-center justify-content-between"> */}
                {/* <div
                        className="dataTables_info"
                        id="example5_info"
                        role="status"
                        aria-live="polite"
                    >
                        Showing {activePag.current * sort + 1} to{' '}
                        {data.length > (activePag.current + 1) * sort
                            ? (activePag.current + 1) * sort
                            : data.length}{' '}
                        of {data.length} entries
                    </div> */}
                {/* <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="example5_paginate"
                    >
                        <Link
                            className="paginate_button previous disabled"
                            to="/reviews"
                            onClick={() => activePag.current > 0 && onClick(activePag.current - 1)}
                        >
                            Previous
                        </Link>
                        <span>
                            {paggination.map((number, i) => (
                                <Link
                                    key={i}
                                    to="/reviews"
                                    className={`paginate_button  ${
                                        activePag.current === i ? 'current' : ''
                                    } ${i > 0 ? 'ml-1' : ''}`}
                                    onClick={() => onClick(i)}
                                >
                                    {number}
                                </Link>
                            ))}
                        </span>
                        <Link
                            className="paginate_button next"
                            to="/reviews"
                            onClick={() =>
                                activePag.current + 1 < paggination.length &&
                                onClick(activePag.current + 1)
                            }
                        >
                            Next
                        </Link>
                    </div> */}
                {/* </div> */}
            </div>
        </div>
    );
};

export default AllReview;
