/// Css

import "./index.css";
import "./chart.css";

import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import Advertisement from "./components/AppsMenu/Shop/ProductGrid/Advertisement";
import ApexChart from "./components/charts/apexcharts";
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import Barber from "./components/AppsMenu/Shop/ProductGrid/Barber";
import Beauty from "./components/AppsMenu/Shop/ProductGrid/Beauty";
import BootstrapTable from "./components/table/BootstrapTable";
import BtcChart from "./components/charts/apexcharts/ApexChart";
import BubbleTea from "./components/AppsMenu/Shop/ProductGrid/BubbleTea";
import { CSSTransition } from "react-transition-group";
import Calendar from "./components/AppsMenu/Calendar/Calendar";
import CarRepairs from "./components/AppsMenu/Shop/ProductGrid/CarRepairs";
import ChartJs from "./components/charts/Chartjs";
import Chartist from "./components/charts/chartist";
import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
import Compose from "./components/AppsMenu/Email/Compose/Compose";
import ConfirmList from "./components/Dashboard/ConfirmList";
import ConsumptionDetail from "./components/AppsMenu/Shop/ProductGrid/ConsumptionDetail";
import CustomerList from "./components/Dashboard/CustomerList";
import Customers from "./components/AppsMenu/Shop/Customers/Customers";
import DataTable from "./components/table/DataTable";
import Element from "./components/Forms/Element/Element";
import Entertainment from "./components/AppsMenu/Shop/ProductGrid/Entertainment";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import EventSidebar from "./layouts/EventSidebar";
import Events from "./components/Dashboard/Events";
import Feedback from "./components/User/Feedback";
import Fitness from "./components/AppsMenu/Shop/ProductGrid/Fitness";
import Footer from "./layouts/Footer";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./components/Dashboard/Home";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
import JqvMap from "./components/PluginsMenu/Jqv Map/JqvMap";
import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";
import LockScreen from "./pages/LockScreen";
import Login from "./pages/Login";
import MainNouiSlider from "./components/PluginsMenu/Noui Slider/MainNouiSlider";
import MainSweetAlert from "./components/PluginsMenu/Sweet Alert/SweetAlert";
import Massage from "./components/AppsMenu/Shop/ProductGrid/Massage";
import Movie from "./components/AppsMenu/Shop/ProductGrid/Movie";
import MovieCoupon from "./components/AppsMenu/Shop/ProductGrid/MovieCoupon";
import Nav from "./layouts/nav";
import Nestable from "./components/PluginsMenu/Nestable/Nestable";
import NotConsumed from "./components/AppsMenu/Shop/Customers/NotConsumed";
import OrderList from "./components/Dashboard/OrderList";
import Orders from "./components/AppsMenu/Shop/Customers/Orders";
import Pickers from "./components/Forms/Pickers/Pickers";
import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";
import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";
import Rank from "./components/AppsMenu/Shop/ProductGrid/Rank";
import Read from "./components/AppsMenu/Email/Read/Read";
import RechartJs from "./components/charts/rechart";
import Record from "./components/Dashboard/Record";
import { RefreshAccessToken } from "./api/api";
import Registration from "./pages/Registration";
import Restaurant from "./components/AppsMenu/Shop/ProductGrid/Restaurant";
import Reviews from "./components/Dashboard/Reviews";
import Select2 from "./components/PluginsMenu/Select2/Select2";
import Setting from "./components/Dashboard/Setting";
import SparklineChart from "./components/charts/Sparkline";
import Statement from "./components/Dashboard/Statement";
import Statistics from "./components/AppsMenu/Shop/ProductGrid/Statistics";
import SummerNote from "./components/Forms/Summernote/SummerNote";
import Supermarket from "./components/AppsMenu/Shop/ProductGrid/Supermarket";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";
import TransitionGroup from "react-transition-group/TransitionGroup";
import UiAccordion from "./components/bootstrap/Accordion";
import UiAlert from "./components/bootstrap/Alert";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiGrid from "./components/bootstrap/Grid";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiMediaObject from "./components/bootstrap/MediaObject";
import UiModal from "./components/bootstrap/Modal";
import UiPagination from "./components/bootstrap/Pagination";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiTypography from "./components/bootstrap/Typography";
import Widget from "./pages/Widget";
import Wizard from "./components/Forms/Wizard/Wizard";
import { createBrowserHistory } from "history";
import jQueryValidation from "./components/Forms/jQueryValidation/jQueryValidation";
import { useHistory } from "react-router-dom";

/// React router dom

/// App

/// Table

/// Form

/// Dashboard

/// Layout

/// Product List
// import ProductGrid from './components/AppsMenu/Shop/ProductGrid/ProductGrid';

/// Pages

/// Plugins

/// Charts

/// Bootstrap

/// Widget
const firstChild = (props) => {
	const childrenArray = React.Children.toArray(props.children);
	return childrenArray[0] || null;
};

const Markup = () => {
	const history = createBrowserHistory();
	let path = window.location.pathname;
	path = path.split("/");
	path = path[path.length - 1];
	let pagePath = path.split("-").includes("page");
	const [activeEvent, setActiveEvent] = useState(!path);

	var user = JSON.parse(localStorage.getItem("current_user"));
	// console.log('old', user);
	const loginTime = localStorage.getItem("login_time");

	// console.log(remaining);
	if (user) {
		const halfDay = async () => {
			const userExpireTime =
				(loginTime / (24 * 60 * 60 * 1000) + 0.5) *
				(24 * 60 * 60 * 1000);
			// console.log(userExpireTime);
			// const time = parseInt(userExpireTime + '000');
			const remaining = userExpireTime - new Date().getTime();
			// console.log('相差', remaining);
			if (user && remaining <= 0) {
				clearInterval(time);
				async function refreshToken() {
					const newToken = await RefreshAccessToken(
						user.access_token
					);
					if (newToken.data.member_level !== 44) {
						localStorage.setItem(
							"current_user",
							JSON.stringify(newToken.data)
						);
					}
					if (newToken.data.member_level === 44) {
						history.push("/page-login");
					}

					// console.log('newnewnew', newToken);
				}
				refreshToken();
				user = JSON.parse(localStorage.getItem("current_user"));
				// console.log('🚀 ~ file: index.js ~ line 145 ~ Markup ~ newUser', user);
			}
		};
		const time = setInterval(halfDay, 60000);
	}

	useEffect(() => {
		async function CheckLogin() {
			// console.log(user);
			const a = window.location.href;
			const u = a.split("?");
			const mid = u[u.length - 1];
			const orderinfo = mid.split("&");
			console.log(orderinfo);
			if (orderinfo.length > 5) {
				history.push("/order-list?" + mid);
			} else {
				if (mid.charAt(0) != "o") {
					if (user == null && !path.includes("page-login")) {
						history.push("/page-login");
						window.location.reload(false);
					}
				} else {
					if (user == null && !path.includes("page-login")) {
						history.push("/page-login?" + mid);
						window.location.reload(false);
					}
				}
			}
		}

		CheckLogin();
	}, []);
	const routes = [
		/// Dashboard
		{ url: "", component: Home },
		// { url: 'analytics', component: Analytics },
		{ url: "events", component: Events },
		{ url: "order-list", component: OrderList },
		{ url: "record", component: Record },
		{ url: "confirmed-list", component: ConfirmList },
		{ url: "statement", component: Statement },

		{ url: "reviews", component: Reviews },
		{ url: "setting", component: Setting },

		/// Apps
		{ url: "app-profile", component: AppProfile },
		{ url: "email-compose", component: Compose },
		{ url: "email-inbox", component: Inbox },
		{ url: "email-read", component: Read },
		{ url: "app-calender", component: Calendar },
		{ url: "post-details", component: PostDetails },
		///Users
		{ url: "feedback", component: Feedback },

		/// Chart
		{ url: "chart-sparkline", component: SparklineChart },
		{ url: "chart-chartjs", component: ChartJs },
		{ url: "chart-chartist", component: Chartist },
		{ url: "chart-btc", component: BtcChart },
		{ url: "chart-apexchart", component: ApexChart },
		{ url: "chart-rechart", component: RechartJs },

		/// Bootstrap
		{ url: "ui-alert", component: UiAlert },
		{ url: "ui-badge", component: UiBadge },
		{ url: "ui-button", component: UiButton },
		{ url: "ui-modal", component: UiModal },
		{ url: "ui-button-group", component: UiButtonGroup },
		{ url: "ui-accordion", component: UiAccordion },
		{ url: "ui-list-group", component: UiListGroup },
		{ url: "ui-media-object", component: UiMediaObject },
		{ url: "ui-card", component: UiCards },
		{ url: "ui-carousel", component: UiCarousel },
		{ url: "ui-dropdown", component: UiDropDown },
		{ url: "ui-popover", component: UiPopOver },
		{ url: "ui-progressbar", component: UiProgressBar },
		{ url: "ui-tab", component: UiTab },
		{ url: "ui-pagination", component: UiPagination },
		{ url: "ui-typography", component: UiTypography },
		{ url: "ui-grid", component: UiGrid },

		/// Plugin
		{ url: "uc-select2", component: Select2 },
		{ url: "uc-nestable", component: Nestable },
		{ url: "uc-noui-slider", component: MainNouiSlider },
		{ url: "uc-sweetalert", component: MainSweetAlert },
		{ url: "uc-toastr", component: Toastr },
		{ url: "map-jqvmap", component: JqvMap },
		{ url: "uc-lightgallery", component: Lightgallery },

		/// Widget
		{ url: "widget-basic", component: Widget },

		/// Shop
		// { url: 'ecom-product-grid', component: ProductGrid },
		{ url: "ecom-product-list", component: ProductList },
		{ url: "consumption-detail", component: ConsumptionDetail },
		{ url: "ecom-product-order", component: ProductOrder },
		{ url: "ecom-checkout", component: Checkout },
		{ url: "ecom-invoice", component: Invoice },
		// { url: "consumption-detail", component: ProductDetail },
		{ url: "not-consumed", component: NotConsumed },
		{ url: "orders", component: Orders },
		{ url: "customers", component: Customers },
		{ url: "bubble-tea", component: BubbleTea },
		{ url: "beauty", component: Beauty },
		{ url: "massage", component: Massage },
		{ url: "fitness", component: Fitness },
		{ url: "barber", component: Barber },
		{ url: "entertainment", component: Entertainment },
		{ url: "restaurant", component: Restaurant },
		{ url: "Supermarket", component: Supermarket },
		{ url: "car-repairs", component: CarRepairs },
		{ url: "statistics", component: Statistics },
		{ url: "rank", component: Rank },
		{ url: "advertisement", component: Advertisement },
		{ url: "movie", component: Movie },
		{ url: "movie-coupon", component: MovieCoupon },

		/// Form
		{ url: "form-element", component: Element },
		{ url: "form-wizard", component: Wizard },
		{ url: "form-wizard", component: Wizard },
		{ url: "form-editor-summernote", component: SummerNote },
		{ url: "form-pickers", component: Pickers },
		{ url: "form-validation-jquery", component: jQueryValidation },

		/// table
		{ url: "table-datatable-basic", component: DataTable },
		{ url: "table-bootstrap-basic", component: BootstrapTable },

		/// pages
		{ url: "page-register", component: Registration },
		{ url: "page-lock-screen", component: LockScreen },
		{ url: "page-login", component: Login },
		{ url: "page-forgot-password", component: ForgotPassword },
		{ url: "page-error-400", component: Error400 },
		{ url: "page-error-403", component: Error403 },
		{ url: "page-error-404", component: Error404 },
		{ url: "page-error-500", component: Error500 },
		{ url: "page-error-503", component: Error503 },
	];

	return (
		<Router basename='/'>
			<div
				id={`${!pagePath ? "main-wrapper" : ""}`}
				className={`${!pagePath ? "show" : "mh100vh"}`}
			>
				{!pagePath && (
					<Nav
						onClick={() => setActiveEvent(!activeEvent)}
						activeEvent={activeEvent}
						onClick2={() => setActiveEvent(false)}
						onClick3={() => setActiveEvent(true)}
					/>
				)}
				{/* {!pagePath && <EventSidebar activeEvent={activeEvent} />} */}
				<div
					className={` ${
						!path && activeEvent ? "rightside-event" : ""
					} ${!pagePath ? "content-body" : ""}`}
				>
					<div className={`${!pagePath ? "container-fluid" : ""}`}>
						<Switch>
							{routes.map((data, i) => (
								// <Route
								//     exact
								//     key={i}
								//     path={`/${data.url}`}
								//     children={({ match, ...rest }) => (
								//         <CSSTransition component={data.component}>
								//             {/* {match && <Home {...rest} />} */}
								//         </CSSTransition>
								//     )}
								// />
								<Route
									key={i}
									exact
									path={`/${data.url}`}
									component={data.component}
								/>
							))}
						</Switch>
					</div>
				</div>
				{!pagePath && <Footer />}
			</div>
		</Router>
	);
};

export default Markup;
