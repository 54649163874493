import { Button, Card, Col, Modal, Nav, Row, Table } from "react-bootstrap";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useState } from "react";

import DateFnsUtils from "@date-io/date-fns";
import { DateGetSpend } from "../../../../../api/api";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import moment from "moment";

const SearchSpend = ({ show, handleClose, item }) => {
	// if (show) {
	console.log(show);
	const user = JSON.parse(localStorage.getItem("current_user") || "{}");
	console.log(
		"🚀 ~ file: SearchSpend.js ~ line 13 ~ SearchSpend ~ user",
		user.access_token
	);

	const [selectedDate1, handleDateChange1] = useState(new Date());
	const [selectedDate2, handleDateChange2] = useState(new Date());
	const date1 = moment(selectedDate1).format("YYYY-MM-DD");
	const date2 = moment(selectedDate2).format("YYYY-MM-DD");
	// const Date = moment(selectedDate).format('YYYY-MM-DD');
	console.log(date1, date2);
	console.log(item);
	// console.log(id, types);
	const [value, setValue] = useState([]);
	const [total, setTotal] = useState(null);
	const [msg, setMsg] = useState("");

	const handleCheck = async () => {
		const data = await DateGetSpend(
			user.access_token,
			item.id,
			item.types,
			date1,
			date2
		);
		console.log(data);
		if (data.ret === 200) {
			const d = data.data;

			setValue(d);
			var amount = 0;
			for (var i = 0; i < d.length; i++) {
				console.log(amount);
				amount += d[i].amount;
			}
			// console.log(amount);
			// console.log(d.length);
			// const length = d.length;
			// const profit = amount / length;
			// console.log(profit);
			// setTotal((amount / d.length).toFixed(2));
			setTotal((amount / 100).toFixed(2));
		}
		if (data.ret === 400) {
			setMsg(data.msg);
		}
	};

	const calStripe = (amount) => {
		const fee = ((amount * 0.0175) / 100 + 0.3).toFixed(2);
		// setStripeFee(fee);
		return fee;
	};

	// console.log(total);

	return (
		<Modal
			show={show}
			onHide={handleClose}
			backdrop='static'
			keyboard={false}
			centered
			size='lg'
		>
			<Modal.Header closeButton>
				<Modal.Title>Select Date</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='row mb-8 modal-lg'>
					<div className='row d-flex justify-content-center w-100 ml-36'>
						<div className='col-xl col-xxl mb-3'>
							<label>Start Date</label>
							<MuiPickersUtilsProvider
								utils={DateFnsUtils}
								style={{ width: "100%" }}
							>
								<DatePicker
									autoOk
									fullWidth
									// label="date1"
									name='date1'
									clearable
									format='yyyy/MM/dd'
									disableFuture
									value={selectedDate1}
									onChange={handleDateChange1}
								/>
							</MuiPickersUtilsProvider>
						</div>
					</div>
				</div>
				<div className='row mb-8'>
					<div className='row form-material w-100'>
						<div className='col-xl col-xxl mb-3'>
							<label>End Date</label>
							<MuiPickersUtilsProvider
								utils={DateFnsUtils}
								style={{ width: "100%" }}
							>
								<DatePicker
									autoOk
									fullWidth
									// label="date1"
									name='date1'
									clearable
									format='yyyy/MM/dd'
									disableFuture
									value={selectedDate2}
									onChange={handleDateChange2}
								/>
							</MuiPickersUtilsProvider>
						</div>
					</div>
				</div>
				<div className='row  d-flex justify-content-center align-items-center mb-8'>
					<Button onClick={handleCheck}>Search</Button>
				</div>
				{Array.isArray(value) && value.length > 0 ? (
					<div className='row mt-36 mb-4 w-100 '>
						<div className=' mt-36 w-100 '>
							Records
							<Table id={item.name}>
								<tr>
									<th>name</th>
									<th>date</th>
									<th>amount</th>
									{/* <th>Stripe fee</th> */}
								</tr>
								{Array.isArray(value) &&
									value.length > 0 &&
									value.map((item) => (
										<tr>
											<th style={{ width: "60%" }}>
												{item.name}
											</th>
											<th style={{ width: "20%" }}>
												{moment(item.timestamp).format(
													"DD-MM-YYYY"
												)}
											</th>
											<th style={{ width: "10%" }}>
												$
												{(item.amount / 100).toFixed(2)}
											</th>
											{/* <th style={{ width: "10%" }}>
												${calStripe(item.amount)}
											</th> */}
										</tr>
									))}
								<tr>
									<th></th>
									<th></th>
									<th className='float-right'>
										Total:{total}
									</th>
									<th></th>
								</tr>
							</Table>
							<div className='float-right'>
								<ReactHTMLTableToExcel
									className='btn bg-primary border-0  text-white'
									table={item.name}
									filename={
										item.name + "-" + date1 + "-" + date2
									}
									sheet={date1 + "-" + date2}
									buttonText={
										"Download " +
										item.name +
										" Records as XLS"
									}
								/>
							</div>
						</div>
					</div>
				) : (
					<div className='mt-4 d-flex justify-content-center'>
						{msg}
					</div>
				)}
			</Modal.Body>
			{/* <Modal.Footer></Modal.Footer> */}
		</Modal>
	);
};

export default SearchSpend;
