import { Button, Dropdown, Modal } from 'react-bootstrap';
import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { SRLWrapper } from 'simple-react-lightbox';
import profile from '../../../../images/profile/profile.png';
/// Image
import profile01 from '../../../../images/profile/1.jpg';
import profile02 from '../../../../images/profile/2.jpg';
import profile03 from '../../../../images/profile/3.jpg';
import profile04 from '../../../../images/profile/4.jpg';
import profile05 from '../../../../images/profile/5.jpg';
import profile06 from '../../../../images/profile/6.jpg';
import profile07 from '../../../../images/profile/7.jpg';
import profile08 from '../../../../images/profile/8.jpg';

const PostDetails = () => {
    const [sendMessage, setSendMessage] = useState(false);

    const options = {
        settings: {
            overlayColor: '#000000',
        },
    };
    return (
        <div>
            <div>
                <div className="page-titles">
                    {/* <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                     <Link to="/post-details">App</Link>
                  </li>
                  <li className="breadcrumb-item active">
                     <Link to="/post-details">Profile</Link>
                  </li>
               </ol> */}
                </div>
                {/* row */}
                <div className="row">
                    <div className="col-lg-12">
                        <div className="profile card card-body px-3 pt-3 pb-0">
                            <div className="profile-head">
                                {/* <div className="photo-content">
                                    {/* <div className="cover-photo"></div> 
                                </div> */}
                                <div className="profile-info">
                                    <div className="profile-details">
                                        <div className="profile-name px-3 pt-2 pb-3 row justify-content-between d-flex">
                                            <h4 className="text-primary mb-0">Package</h4>
                                            {/* <Button
                                                as="a"
                                                href="#"
                                                className="btn btn-primary ml-1 mt-4"
                                                // onClick={handleShow}
                                            >
                                                New Package
                                            </Button> */}
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th className="center">#</th>
                                                            <th>Item</th>
                                                            <th>Description</th>
                                                            <th className="right">Unit Cost</th>
                                                            <th className="center">Qty</th>
                                                            <th className="right">Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="center">1</td>
                                                            <td className="left strong">
                                                                Origin License
                                                            </td>
                                                            <td className="left">
                                                                Extended License
                                                            </td>
                                                            <td className="right">$999,00</td>
                                                            <td className="center">1</td>
                                                            <td className="right">$999,00</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="center">2</td>
                                                            <td className="left">
                                                                Custom Services
                                                            </td>
                                                            <td className="left">
                                                                Instalation and Customization (cost
                                                                per hour)
                                                            </td>
                                                            <td className="right">$150,00</td>
                                                            <td className="center">20</td>
                                                            <td className="right">$3.000,00</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="center">3</td>
                                                            <td className="left">Hosting</td>
                                                            <td className="left">
                                                                1 year subcription
                                                            </td>
                                                            <td className="right">$499,00</td>
                                                            <td className="center">1</td>
                                                            <td className="right">$499,00</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="center">4</td>
                                                            <td className="left">
                                                                Platinum Support
                                                            </td>
                                                            <td className="left">
                                                                1 year subcription 24/7
                                                            </td>
                                                            <td className="right">$3.999,00</td>
                                                            <td className="center">1</td>
                                                            <td className="right">$3.999,00</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{' '}
            </div>
        </div>
    );
};

export default PostDetails;
