import axios from "axios";

const baseURL = "https://ios.outz.com.au/api/";

export var requestLogin = async (email, password) => {
	let formData = new FormData();
	formData.append("service", "App.Auth.UserEmailLogin");
	formData.append("app_key", "GPv3GwnVpusx");
	formData.append("email", email);
	formData.append("password", password);

	let result = await axios({
		method: "post",
		url: baseURL + "app.php?s=App.Auth.UserEmailLogin",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

export var getOrderDetail = async (access_token, id) => {
	let formData = new FormData();
	formData.append("service", "App.Resuser.IdgetResuser");
	formData.append("access_token", access_token);
	formData.append("id", id);

	let result = await axios({
		method: "post",
		url: baseURL + "app.php?s=App.Resuser.IdgetResuser",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//comfirm order
//App.Userspend.NewUserspend
export var confirmOrder = async (
	access_token,
	resuserid,
	resid,
	userid,
	type,
	typeid,
	staffid,
	usednum,
	shengyunum,
	beizhu,
	officialuse,
	servicetype,
	amount,
	pre_business,
	name
) => {
	let formData = new FormData();
	formData.append("service", "App.Userspend.NewUserspend");
	formData.append("access_token", access_token);
	formData.append("resuserid", resuserid);
	formData.append("resid", resid);
	formData.append("userid", userid);
	formData.append("type", type);
	formData.append("typeid", typeid);
	formData.append("staffid", staffid);
	formData.append("usednum", usednum);
	formData.append("shengyunum", shengyunum);
	formData.append("beizhu", beizhu);
	formData.append("officialuse", officialuse);
	formData.append("servicetype", servicetype);
	formData.append("amount", amount);
	formData.append("pre_business", pre_business);
	formData.append("name", name);

	let result = await axios({
		method: "post",
		url: baseURL + "app.php?s=App.Userspend.NewUserspend",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//fetch 7 days data
//App.Userspend.Residget7Userspend

export var get7DaysSpend = async (access_token, resid) => {
	let formData = new FormData();
	formData.append("service", "App.Userspend.Residget7Userspend");
	formData.append("access_token", access_token);
	formData.append("resid", resid);

	let result = await axios({
		method: "post",
		url: baseURL + "app.php?s=App.Userspend.Residget7Userspend",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//get 30 days data
//App.Userspend.Residget30Userspend
export var getMonthSpend = async (access_token, resid) => {
	let formData = new FormData();
	formData.append("service", "App.Userspend.Residget30Userspend");
	formData.append("access_token", access_token);
	formData.append("resid", resid);

	let result = await axios({
		method: "post",
		url: baseURL + "app.php?s=App.Userspend.Residget30Userspend",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//App.Babbletea.GetBabbletea
export var GetBubbletea = async (access_token) => {
	let formData = new FormData();
	formData.append("service", "Admin.Babbletea.GetBabbletea");
	formData.append("access_token", access_token);
	// formData.append('resid', resid);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Babbletea.GetBabbletea",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//DeleteBabbleTea
//Admin.Babbletea.DeleteBabbletea
export var DeleteBubbletea = async (access_token, id) => {
	let formData = new FormData();
	formData.append("service", "Admin.Babbletea.DeleteBabbletea");
	formData.append("access_token", access_token);
	formData.append("id", id);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Babbletea.DeleteBabbletea",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//Admin.Babbletea.NewBabbletea
export var NewBubbletea = async (
	access_token,
	name,
	address1,
	address2,
	gps,
	onlineTime,
	keywords,
	tel,
	accountName,
	accountBSB,
	accountNum,
	accountBank,
	telorder,
	style,
	follow,
	state,
	pic,
	pic2,
	pic3,
	pic4,
	pic5,
	pic6,
	pic7,
	pic8,
	pic9,
	pic10,
	type,
	menu,
	dinnerMenu,
	drinkMenu,
	veganMenu,
	specialMenu,
	hide_keywords,
	promotion,
	rules,
	nameen,
	styleen
) => {
	let formData = new FormData();
	formData.append("service", "Admin.Babbletea.NewBabbletea");
	formData.append("access_token", access_token);
	formData.append("name", name);
	formData.append("address1", address1);
	formData.append("address2", address2);
	formData.append("gps", gps);
	formData.append("onlineTime", onlineTime);
	formData.append("keywords", keywords);
	formData.append("tel", tel);
	formData.append("accountName", accountName);
	formData.append("accountBSB", accountBSB);
	formData.append("accountNum", accountNum);
	formData.append("accountBank", accountBank);
	formData.append("telorder", telorder);
	formData.append("style", style);
	formData.append("follow", follow);
	formData.append("state", state);
	formData.append("pic", pic);
	formData.append("pic2", pic2);
	formData.append("pic3", pic3);
	formData.append("pic4", pic4);
	formData.append("pic5", pic5);
	formData.append("pic6", pic6);
	formData.append("pic7", pic7);
	formData.append("pic8", pic8);
	formData.append("pic9", pic9);
	formData.append("pic10", pic10);
	formData.append("types", type);
	formData.append("menu", menu);
	formData.append("dinnerMenu", dinnerMenu);
	formData.append("drinkMenu", drinkMenu);
	formData.append("veganMenu", veganMenu);
	formData.append("specialMenu", specialMenu);
	formData.append("hide_keywords", hide_keywords);
	formData.append("promotion", promotion);
	formData.append("rules", rules);
	formData.append("nameen", nameen);
	formData.append("styleen", styleen);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Babbletea.NewBabbletea",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//Admin.Babbletea.IdupBabbletea
export var IdupBubbletea = async (
	access_token,
	id,
	name,
	address1,
	address2,
	gps,
	onlineTime,
	keywords,
	tel,
	accountName,
	accountBSB,
	accountNum,
	accountBank,
	telorder,
	style,
	follow,
	state,
	pic,
	pic2,
	pic3,
	pic4,
	pic5,
	pic6,
	pic7,
	pic8,
	pic9,
	pic10,
	type,
	menu,
	dinnerMenu,
	drinkMenu,
	veganMenu,
	specialMenu,
	hide_keywords,
	promotion,
	rules,
	nameen,
	styleen
) => {
	let formData = new FormData();
	formData.append("service", "Admin.Babbletea.IdupBabbletea");
	formData.append("access_token", access_token);
	formData.append("id", id);

	formData.append("name", name);
	formData.append("address1", address1);
	formData.append("address2", address2);
	formData.append("gps", gps);
	formData.append("onlineTime", onlineTime);
	formData.append("keywords", keywords);
	formData.append("tel", tel);
	formData.append("accountName", accountName);
	formData.append("accountBSB", accountBSB);
	formData.append("accountNum", accountNum);
	formData.append("accountBank", accountBank);
	formData.append("telorder", telorder);
	formData.append("style", style);
	formData.append("follow", follow);
	formData.append("state", state);
	formData.append("pic", pic);
	formData.append("pic2", pic2);
	formData.append("pic3", pic3);
	formData.append("pic4", pic4);
	formData.append("pic5", pic5);
	formData.append("pic6", pic6);
	formData.append("pic7", pic7);
	formData.append("pic8", pic8);
	formData.append("pic9", pic9);
	formData.append("pic10", pic10);
	formData.append("types", type);
	formData.append("menu", menu);
	formData.append("dinnerMenu", dinnerMenu);
	formData.append("drinkMenu", drinkMenu);
	formData.append("veganMenu", veganMenu);
	formData.append("specialMenu", specialMenu);
	formData.append("hide_keywords", hide_keywords);
	formData.append("promotion", promotion);
	formData.append("rules", rules);
	formData.append("nameen", nameen);
	formData.append("styleen", styleen);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Babbletea.IdupBabbletea",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//Admin.Beauty.GetBeauty
export var GetBeauty = async (access_token) => {
	let formData = new FormData();
	formData.append("service", "Admin.Beauty.GetBeauty");
	formData.append("access_token", access_token);
	// formData.append('resid', resid);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Beauty.GetBeauty",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//Admin.Entertainment.GetEntertainment
export var GetEntertainment = async (access_token) => {
	let formData = new FormData();
	formData.append("service", "Admin.Entertainment.GetEntertainment");
	formData.append("access_token", access_token);
	// formData.append('resid', resid);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Entertainment.GetEntertainment",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//GetRestaurant
//Admin.Restaurant.GetRestaurant
export var GetRestaurant = async (access_token) => {
	let formData = new FormData();
	formData.append("service", "Admin.Restaurant.GetRestaurant");
	formData.append("access_token", access_token);
	// formData.append('resid', resid);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Restaurant.GetRestaurant",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

export var DeleteEntertainment = async (access_token, id) => {
	let formData = new FormData();
	formData.append("service", "Admin.Entertainment.DeleteEntertainment");
	formData.append("access_token", access_token);
	formData.append("id", id);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Entertainment.DeleteEntertainment",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

export var DeleteBeauty = async (access_token, id) => {
	let formData = new FormData();
	formData.append("service", "Admin.Beauty.DeleteBeauty");
	formData.append("access_token", access_token);
	formData.append("id", id);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Beauty.DeleteBeauty",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

export var DeleteRestaurant = async (access_token, id) => {
	let formData = new FormData();
	formData.append("service", "Admin.Restaurant.DeleteRestaurant");
	formData.append("access_token", access_token);
	formData.append("id", id);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Restaurant.DeleteRestaurant",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//Admin.Beauty.NewBeauty
export var NewBeauty = async (
	access_token,
	name,
	address1,
	address2,
	gps,
	onlineTime,
	keywords,
	tel,
	accountName,
	accountBSB,
	accountNum,
	accountBank,
	telorder,
	style,
	follow,
	state,
	pic,
	pic2,
	pic3,
	pic4,
	pic5,
	pic6,
	pic7,
	pic8,
	pic9,
	pic10,
	type,
	menu,
	dinnerMenu,
	drinkMenu,
	veganMenu,
	specialMenu,
	hide_keywords,
	promotion,
	rules,
	nameen,
	styleen
) => {
	let formData = new FormData();
	formData.append("service", "Admin.Beauty.NewBeauty");
	formData.append("access_token", access_token);
	formData.append("name", name);
	formData.append("address1", address1);
	formData.append("address2", address2);
	formData.append("gps", gps);
	formData.append("onlineTime", onlineTime);
	formData.append("keywords", keywords);
	formData.append("tel", tel);
	formData.append("accountName", accountName);
	formData.append("accountBSB", accountBSB);
	formData.append("accountNum", accountNum);
	formData.append("accountBank", accountBank);
	formData.append("telorder", telorder);
	formData.append("style", style);
	formData.append("follow", follow);
	formData.append("state", state);
	formData.append("pic", pic);
	formData.append("pic2", pic2);
	formData.append("pic3", pic3);
	formData.append("pic4", pic4);
	formData.append("pic5", pic5);
	formData.append("pic6", pic6);
	formData.append("pic7", pic7);
	formData.append("pic8", pic8);
	formData.append("pic9", pic9);
	formData.append("pic10", pic10);
	formData.append("types", type);
	formData.append("menu", menu);
	formData.append("dinnerMenu", dinnerMenu);
	formData.append("drinkMenu", drinkMenu);
	formData.append("veganMenu", veganMenu);
	formData.append("specialMenu", specialMenu);
	formData.append("hide_keywords", hide_keywords);
	formData.append("promotion", promotion);
	formData.append("rules", rules);
	formData.append("nameen", nameen);
	formData.append("styleen", styleen);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Beauty.NewBeauty",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//Admin.Restaurant.NewRestaurant

export var NewRestaurant = async (
	access_token,
	name,
	address1,
	address2,
	gps,
	onlineTime,
	keywords,
	tel,
	accountName,
	accountBSB,
	accountNum,
	accountBank,
	telorder,
	style,
	follow,
	state,
	pic,
	pic2,
	pic3,
	pic4,
	pic5,
	pic6,
	pic7,
	pic8,
	pic9,
	pic10,
	type,
	menu,
	dinnerMenu,
	drinkMenu,
	veganMenu,
	specialMenu,
	hide_keywords,
	price_level,
	google_rate,
	rate_number,
	promotion,
	rules,
	nameen,
	styleen
) => {
	let formData = new FormData();
	formData.append("service", "Admin.Restaurant.NewRestaurant");
	formData.append("access_token", access_token);
	formData.append("name", name);
	formData.append("address1", address1);
	formData.append("address2", address2);
	formData.append("gps", gps);
	formData.append("onlineTime", onlineTime);
	formData.append("keywords", keywords);
	formData.append("tel", tel);
	formData.append("accountName", accountName);
	formData.append("accountBSB", accountBSB);
	formData.append("accountNum", accountNum);
	formData.append("accountBank", accountBank);
	formData.append("telorder", telorder);
	formData.append("style", style);
	formData.append("follow", follow);
	formData.append("state", state);
	formData.append("pic", pic);
	formData.append("pic2", pic2);
	formData.append("pic3", pic3);
	formData.append("pic4", pic4);
	formData.append("pic5", pic5);
	formData.append("pic6", pic6);
	formData.append("pic7", pic7);
	formData.append("pic8", pic8);
	formData.append("pic9", pic9);
	formData.append("pic10", pic10);
	formData.append("types", type);
	formData.append("menu", menu);
	formData.append("dinnerMenu", dinnerMenu);
	formData.append("drinkMenu", drinkMenu);
	formData.append("veganMenu", veganMenu);
	formData.append("specialMenu", specialMenu);
	formData.append("hide_keywords", hide_keywords);
	formData.append("promotion", promotion);
	formData.append("rules", rules);
	formData.append("google_rate", google_rate);
	formData.append("rate_number", rate_number);
	formData.append("price_level", price_level);
	formData.append("nameen", nameen);
	formData.append("styleen", styleen);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Restaurant.NewRestaurant",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//Admin.Entertainment.NewEntertainment
export var NewEntertainment = async (
	access_token,
	name,
	address1,
	address2,
	gps,
	onlineTime,
	keywords,
	tel,
	accountName,
	accountBSB,
	accountNum,
	accountBank,
	telorder,
	style,
	follow,
	state,
	pic,
	pic2,
	pic3,
	pic4,
	pic5,
	pic6,
	pic7,
	pic8,
	pic9,
	pic10,
	type,
	menu,
	dinnerMenu,
	drinkMenu,
	veganMenu,
	specialMenu,
	hide_keywords,
	promotion,
	rules,
	nameen,
	styleen
) => {
	let formData = new FormData();
	formData.append("service", "Admin.Entertainment.NewEntertainment");
	formData.append("access_token", access_token);
	formData.append("name", name);
	formData.append("address1", address1);
	formData.append("address2", address2);
	formData.append("gps", gps);
	formData.append("onlineTime", onlineTime);
	formData.append("keywords", keywords);
	formData.append("tel", tel);
	formData.append("accountName", accountName);
	formData.append("accountBSB", accountBSB);
	formData.append("accountNum", accountNum);
	formData.append("accountBank", accountBank);
	formData.append("telorder", telorder);
	formData.append("style", style);
	formData.append("follow", follow);
	formData.append("state", state);
	formData.append("pic", pic);
	formData.append("pic2", pic2);
	formData.append("pic3", pic3);
	formData.append("pic4", pic4);
	formData.append("pic5", pic5);
	formData.append("pic6", pic6);
	formData.append("pic7", pic7);
	formData.append("pic8", pic8);
	formData.append("pic9", pic9);
	formData.append("pic10", pic10);
	formData.append("types", type);
	formData.append("menu", menu);
	formData.append("dinnerMenu", dinnerMenu);
	formData.append("drinkMenu", drinkMenu);
	formData.append("veganMenu", veganMenu);
	formData.append("specialMenu", specialMenu);
	formData.append("hide_keywords", hide_keywords);
	formData.append("promotion", promotion);
	formData.append("rules", rules);
	formData.append("nameen", nameen);
	formData.append("styleen", styleen);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Entertainment.NewEntertainment",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//Admin.Package.NewPackage
export var NewPackage = async (
	access_token,
	resid,
	name,
	content,
	keywords,
	price,
	yprice,
	startTime,
	endTime,
	numberLimit,
	buyNumber,
	beizhu,
	//0为餐厅,1为娱乐,2为美容，3为奶茶
	servicetype,
	pre_business,
	pre1,
	pre2,
	pre3,
	pre4,
	usedate,
	picurl
) => {
	let formData = new FormData();
	formData.append("service", "Admin.Package.NewPackage");
	formData.append("access_token", access_token);
	formData.append("resid", resid);
	formData.append("name", name);
	formData.append("content", content);
	formData.append("keywords", keywords);
	formData.append("price", price);
	formData.append("yprice", yprice);
	formData.append("startTime", startTime);
	formData.append("endTime", endTime);
	formData.append("numberLimit", numberLimit);
	formData.append("buyNumber", buyNumber);
	formData.append("beizhu", beizhu);
	formData.append("servicetype", servicetype);
	formData.append("pre_business", pre_business);
	formData.append("pre1", pre1);
	formData.append("pre2", pre2);
	formData.append("pre3", pre3);
	formData.append("pre4", pre4);
	formData.append("usedate", usedate);
	formData.append("picurl", picurl);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Package.NewPackage",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//Admin.Credit.NewCredit
export var NewCredit = async (
	access_token,
	resid,
	name,
	content,
	keywords,
	price,
	credit,
	startTime,
	endTime,
	numberLimit,
	buyNumber,
	beizhu,
	//0为餐厅,1为娱乐,2为美容，3为奶茶
	servicetype,
	pre_business,
	pre1,
	pre2,
	pre3,
	pre4,
	usedate,
	picurl
) => {
	let formData = new FormData();
	formData.append("service", "Admin.Credit.NewCredit");
	formData.append("access_token", access_token);
	formData.append("resid", resid);
	formData.append("name", name);
	formData.append("content", content);
	formData.append("keywords", keywords);
	formData.append("price", price);
	formData.append("credit", credit);
	formData.append("startTime", startTime);
	formData.append("endTime", endTime);
	formData.append("numberLimit", numberLimit);
	formData.append("buyNumber", buyNumber);
	formData.append("beizhu", beizhu);
	formData.append("servicetype", servicetype);
	formData.append("pre_business", pre_business);
	formData.append("pre1", pre1);
	formData.append("pre2", pre2);
	formData.append("pre3", pre3);
	formData.append("pre4", pre4);
	formData.append("usedate", usedate);
	formData.append("picurl", picurl);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Credit.NewCredit",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//Admin.Beauty.IdupBeauty
export var IdupBeauty = async (
	access_token,
	id,
	name,
	address1,
	address2,
	gps,
	onlineTime,
	keywords,
	tel,
	accountName,
	accountBSB,
	accountNum,
	accountBank,
	telorder,
	style,
	follow,
	state,
	pic,
	pic2,
	pic3,
	pic4,
	pic5,
	pic6,
	pic7,
	pic8,
	pic9,
	pic10,
	type,
	menu,
	dinnerMenu,
	drinkMenu,
	veganMenu,
	specialMenu,
	hide_keywords,
	promotion,
	rules,
	nameen,
	styleen
) => {
	let formData = new FormData();
	formData.append("service", "Admin.Beauty.IdupBeauty");
	formData.append("access_token", access_token);
	formData.append("id", id);

	formData.append("name", name);
	formData.append("address1", address1);
	formData.append("address2", address2);
	formData.append("gps", gps);
	formData.append("onlineTime", onlineTime);
	formData.append("keywords", keywords);
	formData.append("tel", tel);
	formData.append("accountName", accountName);
	formData.append("accountBSB", accountBSB);
	formData.append("accountNum", accountNum);
	formData.append("accountBank", accountBank);
	formData.append("telorder", telorder);
	formData.append("style", style);
	formData.append("follow", follow);
	formData.append("state", state);
	formData.append("pic", pic);
	formData.append("pic2", pic2);
	formData.append("pic3", pic3);
	formData.append("pic4", pic4);
	formData.append("pic5", pic5);
	formData.append("pic6", pic6);
	formData.append("pic7", pic7);
	formData.append("pic8", pic8);
	formData.append("pic9", pic9);
	formData.append("pic10", pic10);
	formData.append("types", type);
	formData.append("menu", menu);
	formData.append("dinnerMenu", dinnerMenu);
	formData.append("drinkMenu", drinkMenu);
	formData.append("veganMenu", veganMenu);
	formData.append("specialMenu", specialMenu);
	formData.append("hide_keywords", hide_keywords);
	formData.append("promotion", promotion);
	formData.append("rules", rules);
	formData.append("nameen", nameen);
	formData.append("styleen", styleen);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Beauty.IdupBeauty",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//Admin.Entertainment.IdupEntertainment
export var IdupEntertainment = async (
	access_token,
	id,
	name,
	address1,
	address2,
	gps,
	onlineTime,
	keywords,
	tel,
	accountName,
	accountBSB,
	accountNum,
	accountBank,
	telorder,
	style,
	follow,
	state,
	pic,
	pic2,
	pic3,
	pic4,
	pic5,
	pic6,
	pic7,
	pic8,
	pic9,
	pic10,
	type,
	menu,
	dinnerMenu,
	drinkMenu,
	veganMenu,
	specialMenu,
	hide_keywords,
	promotion,
	rules,
	nameen,
	styleen
) => {
	let formData = new FormData();
	formData.append("service", "Admin.Entertainment.IdupEntertainment");
	formData.append("access_token", access_token);
	formData.append("id", id);

	formData.append("name", name);
	formData.append("address1", address1);
	formData.append("address2", address2);
	formData.append("gps", gps);
	formData.append("onlineTime", onlineTime);
	formData.append("keywords", keywords);
	formData.append("tel", tel);
	formData.append("accountName", accountName);
	formData.append("accountBSB", accountBSB);
	formData.append("accountNum", accountNum);
	formData.append("accountBank", accountBank);
	formData.append("telorder", telorder);
	formData.append("style", style);
	formData.append("follow", follow);
	formData.append("state", state);
	formData.append("pic", pic);
	formData.append("pic2", pic2);
	formData.append("pic3", pic3);
	formData.append("pic4", pic4);
	formData.append("pic5", pic5);
	formData.append("pic6", pic6);
	formData.append("pic7", pic7);
	formData.append("pic8", pic8);
	formData.append("pic9", pic9);
	formData.append("pic10", pic10);
	formData.append("types", type);
	formData.append("menu", menu);
	formData.append("dinnerMenu", dinnerMenu);
	formData.append("drinkMenu", drinkMenu);
	formData.append("veganMenu", veganMenu);
	formData.append("specialMenu", specialMenu);
	formData.append("hide_keywords", hide_keywords);
	formData.append("promotion", promotion);
	formData.append("rules", rules);
	formData.append("nameen", nameen);
	formData.append("styleen", styleen);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Entertainment.IdupEntertainment",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//Admin.Restaurant.IdupRestaurant
export var IdupRestaurant = async (
	access_token,
	id,
	name,
	address1,
	address2,
	gps,
	onlineTime,
	keywords,
	tel,
	accountName,
	accountBSB,
	accountNum,
	accountBank,
	telorder,
	style,
	follow,
	state,
	pic,
	pic2,
	pic3,
	pic4,
	pic5,
	pic6,
	pic7,
	pic8,
	pic9,
	pic10,
	type,
	menu,
	dinnerMenu,
	drinkMenu,
	veganMenu,
	specialMenu,
	hide_keywords,
	price_level,
	google_rate,
	rate_number,
	promotion,
	rules,
	nameen,
	styleen
) => {
	let formData = new FormData();
	formData.append("service", "Admin.Restaurant.IdupRestaurant");
	formData.append("access_token", access_token);
	formData.append("name", name);
	formData.append("id", id);

	formData.append("address1", address1);
	formData.append("address2", address2);
	formData.append("gps", gps);
	formData.append("onlineTime", onlineTime);
	formData.append("keywords", keywords);
	formData.append("tel", tel);
	formData.append("accountName", accountName);
	formData.append("accountBSB", accountBSB);
	formData.append("accountNum", accountNum);
	formData.append("accountBank", accountBank);
	formData.append("telorder", telorder);
	formData.append("style", style);
	formData.append("follow", follow);
	formData.append("state", state);
	formData.append("pic", pic);
	formData.append("pic2", pic2);
	formData.append("pic3", pic3);
	formData.append("pic4", pic4);
	formData.append("pic5", pic5);
	formData.append("pic6", pic6);
	formData.append("pic7", pic7);
	formData.append("pic8", pic8);
	formData.append("pic9", pic9);
	formData.append("pic10", pic10);
	formData.append("types", type);
	formData.append("menu", menu);
	formData.append("dinnerMenu", dinnerMenu);
	formData.append("drinkMenu", drinkMenu);
	formData.append("veganMenu", veganMenu);
	formData.append("specialMenu", specialMenu);
	formData.append("hide_keywords", hide_keywords);
	formData.append("promotion", promotion);
	formData.append("rules", rules);
	formData.append("google_rate", google_rate);
	formData.append("rate_number", rate_number);
	formData.append("price_level", price_level);
	formData.append("nameen", nameen);
	formData.append("styleen", styleen);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Restaurant.IdupRestaurant",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//Admin.Package.IdupPackage

//admin.php?s=Admin.Openhour.NewOpenhour

export var NewOpenhour = async (
	access_token,
	resid,
	mon,
	tue,
	wed,
	thu,
	fri,
	sat,
	sun,
	other,
	servicetype
	//0为餐厅,1为娱乐,2为美容，3为奶茶
) => {
	let formData = new FormData();
	formData.append("service", "Admin.Openhour.NewOpenhour");
	formData.append("access_token", access_token);
	formData.append("resid", resid);
	formData.append("mon", mon);
	formData.append("tue", tue);
	formData.append("wed", wed);
	formData.append("thu", thu);
	formData.append("fri", fri);
	formData.append("sat", sat);
	formData.append("sun", sun);
	formData.append("other", other);
	formData.append("servicetype", servicetype);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Openhour.NewOpenhour",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//app.php?s=App.Credit.RsgetCredit
export var RsgetCredit = async (access_token, resid, servicetype) => {
	let formData = new FormData();
	formData.append("service", "App.Credit.RsgetCredit");
	formData.append("access_token", access_token);
	formData.append("resid", resid);
	formData.append("servicetype", servicetype);

	let result = await axios({
		method: "post",
		url: baseURL + "app.php?s=App.Credit.RsgetCredit",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//App.Package.RsgetPackage
export var RsgetPackage = async (access_token, resid, servicetype) => {
	let formData = new FormData();
	formData.append("service", "App.Package.RsgetPackage");
	formData.append("access_token", access_token);
	formData.append("resid", resid);
	formData.append("servicetype", servicetype);

	let result = await axios({
		method: "post",
		url: baseURL + "app.php?s=App.Package.RsgetPackage",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//admin.php?s=Admin.Credit.IdupCredit
export var IdupCredit = async (
	access_token,
	id,
	resid,
	name,
	content,
	keywords,
	price,
	credit,
	startTime,
	endTime,
	numberLimit,
	buyNumber,
	beizhu,
	//0为餐厅,1为娱乐,2为美容，3为奶茶
	servicetype,
	pre_business,
	pre1,
	pre2,
	pre3,
	pre4,
	usedate,
	picurl
) => {
	let formData = new FormData();
	formData.append("service", "Admin.Credit.IdupCredit");
	formData.append("access_token", access_token);
	formData.append("id", id);
	formData.append("resid", resid);
	formData.append("name", name);
	formData.append("content", content);
	formData.append("keywords", keywords);
	formData.append("price", price);
	formData.append("credit", credit);
	formData.append("startTime", startTime);
	formData.append("endTime", endTime);
	formData.append("numberLimit", numberLimit);
	formData.append("buyNumber", buyNumber);
	formData.append("beizhu", beizhu);
	formData.append("servicetype", servicetype);
	formData.append("pre_business", pre_business);
	formData.append("pre1", pre1);
	formData.append("pre2", pre2);
	formData.append("pre3", pre3);
	formData.append("pre4", pre4);
	formData.append("usedate", usedate);
	formData.append("picurl", picurl);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Credit.IdupCredit",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//Admin.Package.IdupPackage
export var IdupPackage = async (
	access_token,
	id,
	resid,
	name,
	content,
	keywords,
	price,
	yprice,
	startTime,
	endTime,
	numberLimit,
	buyNumber,
	beizhu,
	//0为餐厅,1为娱乐,2为美容，3为奶茶
	servicetype,
	pre_business,
	pre1,
	pre2,
	pre3,
	pre4,
	usedate,
	picurl
) => {
	let formData = new FormData();
	formData.append("service", "Admin.Package.IdupPackage");
	formData.append("access_token", access_token);
	formData.append("id", id);
	formData.append("resid", resid);
	formData.append("name", name);
	formData.append("content", content);
	formData.append("keywords", keywords);
	formData.append("price", price);
	formData.append("yprice", yprice);
	formData.append("startTime", startTime);
	formData.append("endTime", endTime);
	formData.append("numberLimit", numberLimit);
	formData.append("buyNumber", buyNumber);
	formData.append("beizhu", beizhu);
	formData.append("servicetype", servicetype);
	formData.append("pre_business", pre_business);
	formData.append("pre1", pre1);
	formData.append("pre2", pre2);
	formData.append("pre3", pre3);
	formData.append("pre4", pre4);
	formData.append("usedate", usedate);
	formData.append("picurl", picurl);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Package.IdupPackage",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//Admin.Package.DeletePackage
export var DeletePackage = async (access_token, id) => {
	let formData = new FormData();
	formData.append("service", "Admin.Package.DeletePackage");
	formData.append("access_token", access_token);
	formData.append("id", id);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Package.DeletePackage",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//Admin.Credit.DeleteCredit
export var DeleteCredit = async (access_token, id) => {
	let formData = new FormData();
	formData.append("service", "Admin.Credit.DeleteCredit");
	formData.append("access_token", access_token);
	formData.append("id", id);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Credit.DeleteCredit",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//App.Userspend.ResidgetUserspend
// business owner
export var ResidgetUserspend = async (access_token, resid) => {
	let formData = new FormData();
	formData.append("service", "App.Userspend.ResidgetUserspend");
	formData.append("access_token", access_token);
	formData.append("resid", resid);

	let result = await axios({
		method: "post",
		url: baseURL + "app.php?s=App.Userspend.ResidgetUserspend",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//App.Userspend.RsidgetUserspend
export var RsidgetUserspend = async (access_token, resid, staffid) => {
	let formData = new FormData();
	formData.append("service", "App.Userspend.RsidgetUserspend");
	formData.append("access_token", access_token);
	formData.append("resid", resid);
	formData.append("staffid", staffid);

	let result = await axios({
		method: "post",
		url: baseURL + "app.php?s=App.Userspend.RsidgetUserspend",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//App.Userspend.SjchUserspend
export var CancelOrder = async (
	access_token,
	resuserid,
	resid,
	userid,
	staffid,
	officialuse
) => {
	let formData = new FormData();
	formData.append("service", "App.Userspend.SjchUserspend");
	formData.append("access_token", access_token);
	formData.append("resuserid", resuserid);
	formData.append("resid", resid);
	formData.append("userid", userid);
	formData.append("staffid", staffid);
	formData.append("officialuse", officialuse);

	let result = await axios({
		method: "post",
		url: baseURL + "app.php?s=App.Userspend.SjchUserspend",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

// App.Userspend.Residget30Userspend
export var getOneMonthSpend = async (access_token, resid) => {
	let formData = new FormData();
	formData.append("service", "App.Userspend.Residget30Userspend");
	formData.append("access_token", access_token);
	formData.append("resid", resid);

	let result = await axios({
		method: "post",
		url: baseURL + "app.php?s=App.Userspend.Residget30Userspend",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//App.Userspend.GetbzUserspend
export var getLastMonthSpend = async (access_token, resid) => {
	let formData = new FormData();
	formData.append("service", "App.Userspend.GetlastmonthUserspend");
	formData.append("access_token", access_token);
	formData.append("resid", resid);

	let result = await axios({
		method: "post",
		url: baseURL + "app.php?s=App.Userspend.GetlastmonthUserspend",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//App.Userspend.GetbzUserspend
export var getWeekSpend = async (access_token, resid) => {
	let formData = new FormData();
	formData.append("service", "App.Userspend.GetbzUserspend");
	formData.append("access_token", access_token);
	formData.append("resid", resid);

	let result = await axios({
		method: "post",
		url: baseURL + "app.php?s=App.Userspend.GetbzUserspend",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//App.Userspend.GetdtUserspend
export var getTodaySpend = async (access_token, resid) => {
	let formData = new FormData();
	formData.append("service", "App.Userspend.GetdtUserspend");
	formData.append("access_token", access_token);
	formData.append("resid", resid);

	let result = await axios({
		method: "post",
		url: baseURL + "app.php?s=App.Userspend.GetdtUserspend",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//App.Review.ResidgetReview
export var getReview = async (access_token, resid, type) => {
	let formData = new FormData();
	formData.append("service", "App.Review.ResidgetReview");
	formData.append("access_token", access_token);
	formData.append("resid", resid);
	formData.append("servicetype", type);

	let result = await axios({
		method: "post",
		url: baseURL + "app.php?s=App.Review.ResidgetReview",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//App.Userspend.OfficialusegetUserspend
export var matchVoucher = async (access_token, officialuse) => {
	let formData = new FormData();
	formData.append("service", "App.Userspend.OfficialusegetUserspend");
	formData.append("access_token", access_token);
	formData.append("officialuse", officialuse);

	let result = await axios({
		method: "post",
		url: baseURL + "app.php?s=App.Userspend.OfficialusegetUserspend",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//admin.php?s=Admin.Openhour.IdupOpenhour
export var UpdateOpenhour = async (
	access_token,
	id,
	resid,
	mon,
	tue,
	wed,
	thu,
	fri,
	sat,
	sun,
	other,
	servicetype
	//0为餐厅,1为娱乐,2为美容，3为奶茶
) => {
	let formData = new FormData();
	formData.append("service", "Admin.Openhour.IdupOpenhour");
	formData.append("access_token", access_token);
	formData.append("id", id);

	formData.append("resid", resid);
	formData.append("mon", mon);
	formData.append("tue", tue);
	formData.append("wed", wed);
	formData.append("thu", thu);
	formData.append("fri", fri);
	formData.append("sat", sat);
	formData.append("sun", sun);
	formData.append("other", other);
	formData.append("servicetype", servicetype);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Openhour.IdupOpenhour",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//GetOpenHours
//App.Openhour.ResidgetOpenhour
export var GetOpenHours = async (access_token, resid, servicetype) => {
	let formData = new FormData();
	formData.append("service", "App.Openhour.ResidgetOpenhour");
	formData.append("access_token", access_token);
	formData.append("resid", resid);
	formData.append("servicetype", servicetype);

	let result = await axios({
		method: "post",
		url: baseURL + "app.php?s=App.Openhour.ResidgetOpenhour",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//Admin.Openhour.DeleteOpenhour
export var DeleteOpenhour = async (access_token, id) => {
	let formData = new FormData();
	formData.append("service", "Admin.Openhour.DeleteOpenhour");
	formData.append("access_token", access_token);
	formData.append("id", id);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Openhour.DeleteOpenhour",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//update credit pre
// admin.php?s=Admin.Credit.IdupCreditpre
export var UpdateCreditPre = async (
	access_token,
	id,
	pre1,
	pre2,
	pre3,
	pre4
) => {
	let formData = new FormData();
	formData.append("service", "Admin.Credit.IdupCreditpre");
	formData.append("access_token", access_token);
	formData.append("id", id);
	formData.append("pre1", pre1);
	formData.append("pre2", pre2);
	formData.append("pre3", pre3);
	formData.append("pre4", pre4);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Credit.IdupCreditpre",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//update package pre
//admin.php?s=Admin.Package.IdupPackagepre
export var UpdatePackagePre = async (
	access_token,
	id,
	pre1,
	pre2,
	pre3,
	pre4
) => {
	let formData = new FormData();
	formData.append("service", "Admin.Package.IdupPackagepre");
	formData.append("access_token", access_token);
	formData.append("id", id);
	formData.append("pre1", pre1);
	formData.append("pre2", pre2);
	formData.append("pre3", pre3);
	formData.append("pre4", pre4);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Package.IdupPackagepre",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//App.Beauty.IdgetBeauty
export var IdgetBeauty = async (access_token, id) => {
	let formData = new FormData();
	formData.append("service", "App.Beauty.IdgetBeauty");
	formData.append("access_token", access_token);
	formData.append("id", id);

	let result = await axios({
		method: "post",
		url: baseURL + "app.php?s=App.Beauty.IdgetBeauty",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//App.Babbletea.IdgetBabbletea
export var IdgetBabbletea = async (access_token, id) => {
	let formData = new FormData();
	formData.append("service", "App.Babbletea.IdgetBabbletea");
	formData.append("access_token", access_token);
	formData.append("id", id);

	let result = await axios({
		method: "post",
		url: baseURL + "app.php?s=App.Babbletea.IdgetBabbletea",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//App.Restaurant.IdgetRestaurant
export var IdgetRestaurant = async (access_token, id) => {
	let formData = new FormData();
	formData.append("service", "App.Restaurant.IdgetRestaurant");
	formData.append("access_token", access_token);
	formData.append("id", id);

	let result = await axios({
		method: "post",
		url: baseURL + "app.php?s=App.Restaurant.IdgetRestaurant",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//App.Entertainment.IdgetEntertainment
export var IdgetEntertainment = async (access_token, id) => {
	let formData = new FormData();
	formData.append("service", "App.Entertainment.IdgetEntertainment");
	formData.append("access_token", access_token);
	formData.append("id", id);

	let result = await axios({
		method: "post",
		url: baseURL + "app.php?s=App.Entertainment.IdgetEntertainment",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	// //console.log(result);
	return result;
};

//查询email对应的userid
//App.User.EmailupUserlrs
export var EmailupUserlrs = async (
	access_token,
	id,
	email,
	member_level,
	resid,
	servicetype
) => {
	let formData = new FormData();
	formData.append("service", "App.User.EmailupUserlrs");
	formData.append("access_token", access_token);
	formData.append("id", id);
	formData.append("email", email);
	formData.append("member_level", member_level);
	formData.append("resid", resid);
	formData.append("servicetype", servicetype);

	let result = await axios({
		method: "post",
		url: baseURL + "app.php?s=App.User.EmailupUserlrs",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	// console.log(result);
	return result;
};

//admin.php?s=Admin.User.IdupUserlrs
export var IdupUserlrs = async (
	access_token,
	email,
	member_level,
	resid,
	servicetype
) => {
	let formData = new FormData();
	formData.append("service", "Admin.User.IdupUserlrs");
	formData.append("access_token", access_token);
	// formData.append('id', id);
	formData.append("email", email);
	formData.append("member_level", member_level);
	formData.append("resid", resid);
	formData.append("servicetype", servicetype);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.User.IdupUserlrs",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	// //console.log(result);
	return result;
};

//App.Auth.RefreshAccessToken
export var RefreshAccessToken = async (access_token) => {
	let formData = new FormData();
	formData.append("service", "App.Auth.RefreshAccessToken");
	formData.append("access_token", access_token);

	let result = await axios({
		method: "post",
		url: baseURL + "app.php?s=App.Auth.RefreshAccessToken",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	// //console.log(result);
	return result;
};

//店主查看自己店员接口
//App.User.Userget92

export var CheckStaff = async (access_token, id, resid, servicetype) => {
	let formData = new FormData();
	formData.append("service", "App.User.Userget92");
	formData.append("access_token", access_token);
	formData.append("id", id);
	formData.append("resid", resid);
	formData.append("servicetype", servicetype);

	let result = await axios({
		method: "post",
		url: baseURL + "app.php?s=App.User.Userget92",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	// //console.log(result);
	return result;
};

//店铺ID和类型自定义的时间段查询消费记录接口
//admin.php?s=Admin.Userspend.Date1or2getUserspend
export var DateGetSpend = async (
	access_token,
	resid,
	servicetype,
	date1,
	date2
) => {
	let formData = new FormData();
	formData.append("service", "Admin.Userspend.Date1or2getUserspend");
	formData.append("access_token", access_token);
	// formData.append('id', id);
	formData.append("resid", resid);
	formData.append("servicetype", servicetype);
	formData.append("date1", date1);
	formData.append("date2", date2);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Userspend.Date1or2getUserspend",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	// //console.log(result);
	return result;
};

// fetch all users
//Admin.User.GetsyUser
export var GetsyUser = async (access_token) => {
	let formData = new FormData();
	formData.append("service", "Admin.User.GetsyUser");
	formData.append("access_token", access_token);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.User.GetsyUser",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	// //console.log(result);
	return result;
};

//user register today
//Admin.User.GetdtUser
export var GetdtUser = async (access_token) => {
	let formData = new FormData();
	formData.append("service", "Admin.User.GetdtUser");
	formData.append("access_token", access_token);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.User.GetdtUser",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	// //console.log(result);
	return result;
};

// 用户ID获取消费信息信息
//Admin.Userspend.UseridgetUserspend
export var UserConsumption = async (access_token, userid) => {
	let formData = new FormData();
	formData.append("service", "Admin.Userspend.UseridgetUserspend");
	formData.append("access_token", access_token);
	formData.append("userid", userid);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Userspend.UseridgetUserspend",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	// //console.log(result);
	return result;
};

//管理员查看所有未消费订单接口
//Admin.Resuser.AdmingetResuser0
export var getNotConsumed = async (access_token) => {
	let formData = new FormData();
	// formData.append("service", "Admin.Userspend.UseridgetUserspend");
	formData.append("access_token", access_token);
	// formData.append("userid", userid);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Resuser.AdmingetResuser0",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	// //console.log(result);
	return result;
};

//getOrders
//Admin.Resuser.GetdtResuser

export var getOrders = async (access_token) => {
	let formData = new FormData();
	formData.append("access_token", access_token);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Resuser.GetdtResuser",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	// //console.log(result);
	return result;
};

//管理员获取当天所有消费记录
//Admin.Userspend.GetdtUserspend
export var GetdtUserspend = async (access_token) => {
	let formData = new FormData();
	formData.append("access_token", access_token);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Userspend.GetdtUserspend",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	// //console.log(result);
	return result;
};

//用户ID获取关联信息
//Admin.Resuser.UseridgetResuser
export var UseridgetResuser = async (access_token, userid) => {
	let formData = new FormData();
	formData.append("access_token", access_token);
	formData.append("userid", userid);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Resuser.UseridgetResuser",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	// //console.log(result);
	return result;
};

//ID获取用户信息
//Admin.User.IdgetUser
export var IdgetUser = async (access_token, id) => {
	let formData = new FormData();
	formData.append("access_token", access_token);
	formData.append("id", id);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.User.IdgetUser",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	// //console.log(result);
	return result;
};

//date1和date2查询resuser的订单和商家名称接口
//Admin.Resuser.Date1or2getResuser2
export var SearchResUsers = async (access_token, date1, date2) => {
	let formData = new FormData();
	formData.append("access_token", access_token);
	formData.append("date1", date1);
	formData.append("date2", date2);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Resuser.Date1or2getResuser2",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	// //console.log(result);
	return result;
};

//date1和date2查询userspend的订单和商家名称接口
//Admin.Resuser.Date1or2getResuser2
export var SearchUserspend = async (access_token, date1, date2) => {
	let formData = new FormData();
	formData.append("access_token", access_token);
	formData.append("date1", date1);
	formData.append("date2", date2);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Userspend.DategetUserspend",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	// //console.log(result);
	return result;
};

//获取超市信息
//Admin.Supermarket.GetSupermarket
export var GetSupermarket = async (access_token) => {
	let formData = new FormData();
	formData.append("service", "Admin.Supermarket.GetSupermarket");
	formData.append("access_token", access_token);
	// formData.append('resid', resid);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Supermarket.GetSupermarket",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//Delete Supermarket
//Admin.Supermarket.DeleteSupermarket
export var DeleteSupermarket = async (access_token, id) => {
	let formData = new FormData();
	formData.append("service", "Admin.Supermarket.DeleteSupermarket");
	formData.append("access_token", access_token);
	formData.append("id", id);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Supermarket.DeleteSupermarket",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//添加超市+超市图片接口
//Admin.Supermarket.NewSupermarket
export var NewSupermarket = async (
	access_token,
	name,
	address1,
	address2,
	gps,
	onlineTime,
	keywords,
	tel,
	accountName,
	accountBSB,
	accountNum,
	accountBank,
	telorder,
	style,
	follow,
	state,
	pic,
	pic2,
	pic3,
	pic4,
	pic5,
	pic6,
	pic7,
	pic8,
	pic9,
	pic10,
	type,
	menu,
	dinnerMenu,
	drinkMenu,
	veganMenu,
	specialMenu,
	hide_keywords,
	promotion,
	rules,
	nameen,
	styleen
) => {
	let formData = new FormData();
	formData.append("service", "Admin.Supermarket.NewSupermarket");
	formData.append("access_token", access_token);
	formData.append("name", name);
	formData.append("address1", address1);
	formData.append("address2", address2);
	formData.append("gps", gps);
	formData.append("onlineTime", onlineTime);
	formData.append("keywords", keywords);
	formData.append("tel", tel);
	formData.append("accountName", accountName);
	formData.append("accountBSB", accountBSB);
	formData.append("accountNum", accountNum);
	formData.append("accountBank", accountBank);
	formData.append("telorder", telorder);
	formData.append("style", style);
	formData.append("follow", follow);
	formData.append("state", state);
	formData.append("pic", pic);
	formData.append("pic2", pic2);
	formData.append("pic3", pic3);
	formData.append("pic4", pic4);
	formData.append("pic5", pic5);
	formData.append("pic6", pic6);
	formData.append("pic7", pic7);
	formData.append("pic8", pic8);
	formData.append("pic9", pic9);
	formData.append("pic10", pic10);
	formData.append("types", type);
	formData.append("menu", menu);
	formData.append("dinnerMenu", dinnerMenu);
	formData.append("drinkMenu", drinkMenu);
	formData.append("veganMenu", veganMenu);
	formData.append("specialMenu", specialMenu);
	formData.append("hide_keywords", hide_keywords);
	formData.append("promotion", promotion);
	formData.append("rules", rules);
	formData.append("nameen", nameen);
	formData.append("styleen", styleen);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Supermarket.NewSupermarket",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//修改超市信息接口
//Admin.Supermarket.IdupSupermarket
export var IdupSupermarket = async (
	access_token,
	id,
	name,
	address1,
	address2,
	gps,
	onlineTime,
	keywords,
	tel,
	accountName,
	accountBSB,
	accountNum,
	accountBank,
	telorder,
	style,
	follow,
	state,
	pic,
	pic2,
	pic3,
	pic4,
	pic5,
	pic6,
	pic7,
	pic8,
	pic9,
	pic10,
	types,
	menu,
	dinnerMenu,
	drinkMenu,
	veganMenu,
	specialMenu,
	hide_keywords,
	promotion,
	rules,
	nameen,
	styleen
) => {
	let formData = new FormData();
	formData.append("service", "Admin.Supermarket.IdupSupermarket");
	formData.append("access_token", access_token);
	formData.append("id", id);

	formData.append("name", name);
	formData.append("address1", address1);
	formData.append("address2", address2);
	formData.append("gps", gps);
	formData.append("onlineTime", onlineTime);
	formData.append("keywords", keywords);
	formData.append("tel", tel);
	formData.append("accountName", accountName);
	formData.append("accountBSB", accountBSB);
	formData.append("accountNum", accountNum);
	formData.append("accountBank", accountBank);
	formData.append("telorder", telorder);
	formData.append("style", style);
	formData.append("follow", follow);
	formData.append("state", state);
	formData.append("pic", pic);
	formData.append("pic2", pic2);
	formData.append("pic3", pic3);
	formData.append("pic4", pic4);
	formData.append("pic5", pic5);
	formData.append("pic6", pic6);
	formData.append("pic7", pic7);
	formData.append("pic8", pic8);
	formData.append("pic9", pic9);
	formData.append("pic10", pic10);
	formData.append("typess", types);
	formData.append("menu", menu);
	formData.append("dinnerMenu", dinnerMenu);
	formData.append("drinkMenu", drinkMenu);
	formData.append("veganMenu", veganMenu);
	formData.append("specialMenu", specialMenu);
	formData.append("hide_keywords", hide_keywords);
	formData.append("promotion", promotion);
	formData.append("rules", rules);
	formData.append("nameen", nameen);
	formData.append("styleen", styleen);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Supermarket.IdupSupermarket",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//Admin.Userspend.GetUserspendcount
//统计不同userid的消费次数接口
export var GetUserspendcount = async (access_token) => {
	let formData = new FormData();
	formData.append("access_token", access_token);
	// formData.append("date1", date1);
	// formData.append("date2", date2);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Userspend.GetUserspendcount",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	// //console.log(result);
	return result;
};

//查看30天以外未消费的订单接口
//Admin.Resuser.Get30Resuser
export var Get30Userspend = async (access_token) => {
	let formData = new FormData();
	formData.append("access_token", access_token);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Resuser.Get30Resuser",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	// //console.log(result);
	return result;
};

//查看23天到30天的订单接口
//Admin.Resuser.Get2330Resuser
export var Get2330Userspend = async (access_token) => {
	let formData = new FormData();
	formData.append("access_token", access_token);
	// formData.append("date1", date1);
	// formData.append("date2", date2);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Resuser.Get2330Resuser",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	// //console.log(result);
	return result;
};

//获取修车厂信息
//Admin.Garage.GetGarage
export var GetCarRepairs = async (access_token) => {
	let formData = new FormData();
	formData.append("service", "Admin.Garage.GetGarage");
	formData.append("access_token", access_token);
	// formData.append('resid', resid);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Garage.GetGarage",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//删除修车厂信息
//Admin.Garage.DeleteGarage
export var DeleteCarRepairs = async (access_token, id) => {
	let formData = new FormData();
	formData.append("service", "Admin.Garage.DeleteGarage");
	formData.append("access_token", access_token);
	formData.append("id", id);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Garage.DeleteGarage",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//添加修车厂+修车厂图片接口
//admin.php?s=Admin.Garage.NewGarage
export var NewCarRepairs = async (
	access_token,
	name,
	address1,
	address2,
	gps,
	onlineTime,
	keywords,
	tel,
	accountName,
	accountBSB,
	accountNum,
	accountBank,
	telorder,
	style,
	follow,
	state,
	pic,
	pic2,
	pic3,
	pic4,
	pic5,
	pic6,
	pic7,
	pic8,
	pic9,
	pic10,
	type,
	menu,
	dinnerMenu,
	drinkMenu,
	veganMenu,
	specialMenu,
	hide_keywords,
	promotion,
	rules,
	nameen,
	styleen
) => {
	let formData = new FormData();
	// formData.append("service", "admin.php?s=Admin.Garage.NewGarage");
	formData.append("access_token", access_token);
	formData.append("name", name);
	formData.append("address1", address1);
	formData.append("address2", address2);
	formData.append("gps", gps);
	formData.append("onlineTime", onlineTime);
	formData.append("keywords", keywords);
	formData.append("tel", tel);
	formData.append("accountName", accountName);
	formData.append("accountBSB", accountBSB);
	formData.append("accountNum", accountNum);
	formData.append("accountBank", accountBank);
	formData.append("telorder", telorder);
	formData.append("style", style);
	formData.append("follow", follow);
	formData.append("state", state);
	formData.append("pic", pic);
	formData.append("pic2", pic2);
	formData.append("pic3", pic3);
	formData.append("pic4", pic4);
	formData.append("pic5", pic5);
	formData.append("pic6", pic6);
	formData.append("pic7", pic7);
	formData.append("pic8", pic8);
	formData.append("pic9", pic9);
	formData.append("pic10", pic10);
	formData.append("types", type);
	formData.append("menu", menu);
	formData.append("dinnerMenu", dinnerMenu);
	formData.append("drinkMenu", drinkMenu);
	formData.append("veganMenu", veganMenu);
	formData.append("specialMenu", specialMenu);
	formData.append("hide_keywords", hide_keywords);
	formData.append("promotion", promotion);
	formData.append("rules", rules);
	formData.append("nameen", nameen);
	formData.append("styleen", styleen);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Garage.NewGarage",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//修改修车厂信息接口
//Admin.Garage.IdupGarage
export var IdupCarRepairs = async (
	access_token,
	id,
	name,
	address1,
	address2,
	gps,
	onlineTime,
	keywords,
	tel,
	accountName,
	accountBSB,
	accountNum,
	accountBank,
	telorder,
	style,
	follow,
	state,
	pic,
	pic2,
	pic3,
	pic4,
	pic5,
	pic6,
	pic7,
	pic8,
	pic9,
	pic10,
	types,
	menu,
	dinnerMenu,
	drinkMenu,
	veganMenu,
	specialMenu,
	hide_keywords,
	promotion,
	rules,
	nameen,
	styleen
) => {
	let formData = new FormData();
	formData.append("service", "Admin.Garage.IdupGarage");
	formData.append("access_token", access_token);
	formData.append("id", id);

	formData.append("name", name);
	formData.append("address1", address1);
	formData.append("address2", address2);
	formData.append("gps", gps);
	formData.append("onlineTime", onlineTime);
	formData.append("keywords", keywords);
	formData.append("tel", tel);
	formData.append("accountName", accountName);
	formData.append("accountBSB", accountBSB);
	formData.append("accountNum", accountNum);
	formData.append("accountBank", accountBank);
	formData.append("telorder", telorder);
	formData.append("style", style);
	formData.append("follow", follow);
	formData.append("state", state);
	formData.append("pic", pic);
	formData.append("pic2", pic2);
	formData.append("pic3", pic3);
	formData.append("pic4", pic4);
	formData.append("pic5", pic5);
	formData.append("pic6", pic6);
	formData.append("pic7", pic7);
	formData.append("pic8", pic8);
	formData.append("pic9", pic9);
	formData.append("pic10", pic10);
	formData.append("typess", types);
	formData.append("menu", menu);
	formData.append("dinnerMenu", dinnerMenu);
	formData.append("drinkMenu", drinkMenu);
	formData.append("veganMenu", veganMenu);
	formData.append("specialMenu", specialMenu);
	formData.append("hide_keywords", hide_keywords);
	formData.append("promotion", promotion);
	formData.append("rules", rules);
	formData.append("nameen", nameen);
	formData.append("styleen", styleen);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Garage.IdupGarage",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

// 添加广告栏信息接口;
//Admin.Advertise.NewAdvertise
export var NewAdvertise = async (
	access_token,
	title,
	picurl,
	type,
	servicetype,
	resid,
	jumpurl,
	imageurl
) => {
	let formData = new FormData();
	formData.append("service", "Admin.Advertise.NewAdvertise");
	formData.append("access_token", access_token);
	formData.append("tittle", title);
	formData.append("picurl", picurl);
	formData.append("type", type);
	formData.append("servicetype", servicetype);
	formData.append("resid", resid);
	formData.append("jumpurl", jumpurl);
	formData.append("imageurl", imageurl);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Advertise.NewAdvertise",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//删除广告栏信息
//Admin.Advertise.DeleteAdvertise

export var DeleteAdvertise = async (access_token, id) => {
	let formData = new FormData();
	formData.append("service", "Admin.Advertise.DeleteAdvertise");
	formData.append("access_token", access_token);
	formData.append("id", id);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Advertise.DeleteAdvertise",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//获取广告栏信息
//Admin.Advertise.GetAdvertise
export var GetAdvertise = async (access_token) => {
	let formData = new FormData();
	formData.append("service", "Admin.Advertise.GetAdvertise");
	formData.append("access_token", access_token);
	// formData.append('resid', resid);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Advertise.GetAdvertise",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//admin.php?s=Admin.File.Upload
export var Upload = async (access_token, file) => {
	let formData = new FormData();
	formData.append("service", "Admin.File.Upload");
	formData.append("access_token", access_token);
	formData.append("file", file);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.File.Upload",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//获取理发店信息
//Admin.Barber.GetBarber
export var GetBarber = async (access_token) => {
	let formData = new FormData();
	formData.append("service", "Admin.Barber.GetBarber");
	formData.append("access_token", access_token);
	// formData.append('resid', resid);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Barber.GetBarber",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//删除理发店信息
//Admin.Barber.DeleteBarber
export var DeleteBarber = async (access_token, id) => {
	let formData = new FormData();
	formData.append("service", "Admin.Barber.DeleteBarber");
	formData.append("access_token", access_token);
	formData.append("id", id);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Barber.DeleteBarber",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//添加理发店+理发店图片接口
//Admin.Barber.NewBarber
export var NewBarber = async (
	access_token,
	name,
	address1,
	address2,
	gps,
	onlineTime,
	keywords,
	tel,
	accountName,
	accountBSB,
	accountNum,
	accountBank,
	telorder,
	style,
	follow,
	state,
	pic,
	pic2,
	pic3,
	pic4,
	pic5,
	pic6,
	pic7,
	pic8,
	pic9,
	pic10,
	type,
	menu,
	dinnerMenu,
	drinkMenu,
	veganMenu,
	specialMenu,
	hide_keywords,
	promotion,
	rules,
	nameen,
	styleen
) => {
	let formData = new FormData();
	// formData.append("service", "admin.php?s=Admin.Garage.NewGarage");
	formData.append("access_token", access_token);
	formData.append("name", name);
	formData.append("address1", address1);
	formData.append("address2", address2);
	formData.append("gps", gps);
	formData.append("onlineTime", onlineTime);
	formData.append("keywords", keywords);
	formData.append("tel", tel);
	formData.append("accountName", accountName);
	formData.append("accountBSB", accountBSB);
	formData.append("accountNum", accountNum);
	formData.append("accountBank", accountBank);
	formData.append("telorder", telorder);
	formData.append("style", style);
	formData.append("follow", follow);
	formData.append("state", state);
	formData.append("pic", pic);
	formData.append("pic2", pic2);
	formData.append("pic3", pic3);
	formData.append("pic4", pic4);
	formData.append("pic5", pic5);
	formData.append("pic6", pic6);
	formData.append("pic7", pic7);
	formData.append("pic8", pic8);
	formData.append("pic9", pic9);
	formData.append("pic10", pic10);
	formData.append("types", type);
	formData.append("menu", menu);
	formData.append("dinnerMenu", dinnerMenu);
	formData.append("drinkMenu", drinkMenu);
	formData.append("veganMenu", veganMenu);
	formData.append("specialMenu", specialMenu);
	formData.append("hide_keywords", hide_keywords);
	formData.append("promotion", promotion);
	formData.append("rules", rules);
	formData.append("nameen", nameen);
	formData.append("styleen", styleen);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Barber.NewBarber",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//修改理发店信息接口
//Admin.Barber.IdupBarber
export var IdupBarber = async (
	access_token,
	id,
	name,
	address1,
	address2,
	gps,
	onlineTime,
	keywords,
	tel,
	accountName,
	accountBSB,
	accountNum,
	accountBank,
	telorder,
	style,
	follow,
	state,
	pic,
	pic2,
	pic3,
	pic4,
	pic5,
	pic6,
	pic7,
	pic8,
	pic9,
	pic10,
	types,
	menu,
	dinnerMenu,
	drinkMenu,
	veganMenu,
	specialMenu,
	hide_keywords,
	promotion,
	rules,
	nameen,
	styleen
) => {
	let formData = new FormData();
	formData.append("service", "Admin.Barber.IdupBarber");
	formData.append("access_token", access_token);
	formData.append("id", id);

	formData.append("name", name);
	formData.append("address1", address1);
	formData.append("address2", address2);
	formData.append("gps", gps);
	formData.append("onlineTime", onlineTime);
	formData.append("keywords", keywords);
	formData.append("tel", tel);
	formData.append("accountName", accountName);
	formData.append("accountBSB", accountBSB);
	formData.append("accountNum", accountNum);
	formData.append("accountBank", accountBank);
	formData.append("telorder", telorder);
	formData.append("style", style);
	formData.append("follow", follow);
	formData.append("state", state);
	formData.append("pic", pic);
	formData.append("pic2", pic2);
	formData.append("pic3", pic3);
	formData.append("pic4", pic4);
	formData.append("pic5", pic5);
	formData.append("pic6", pic6);
	formData.append("pic7", pic7);
	formData.append("pic8", pic8);
	formData.append("pic9", pic9);
	formData.append("pic10", pic10);
	formData.append("typess", types);
	formData.append("menu", menu);
	formData.append("dinnerMenu", dinnerMenu);
	formData.append("drinkMenu", drinkMenu);
	formData.append("veganMenu", veganMenu);
	formData.append("specialMenu", specialMenu);
	formData.append("hide_keywords", hide_keywords);
	formData.append("promotion", promotion);
	formData.append("rules", rules);
	formData.append("nameen", nameen);
	formData.append("styleen", styleen);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Barber.IdupBarber",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//获取按摩院信息
//Admin.Massage.GetMassage
export var GetMassage = async (access_token) => {
	let formData = new FormData();
	formData.append("service", "Admin.Massage.GetMassage");
	formData.append("access_token", access_token);
	// formData.append('resid', resid);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Massage.GetMassage",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//删除按摩院信息
//Admin.Massage.DeleteMassage
export var DeleteMassage = async (access_token, id) => {
	let formData = new FormData();
	formData.append("service", "Admin.Massage.DeleteMassage");
	formData.append("access_token", access_token);
	formData.append("id", id);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Massage.DeleteMassage",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//添加按摩院+按摩院图片接口
//Admin.Massage.NewMassage
export var NewMassage = async (
	access_token,
	name,
	address1,
	address2,
	gps,
	onlineTime,
	keywords,
	tel,
	accountName,
	accountBSB,
	accountNum,
	accountBank,
	telorder,
	style,
	follow,
	state,
	pic,
	pic2,
	pic3,
	pic4,
	pic5,
	pic6,
	pic7,
	pic8,
	pic9,
	pic10,
	type,
	menu,
	dinnerMenu,
	drinkMenu,
	veganMenu,
	specialMenu,
	hide_keywords,
	promotion,
	rules,
	nameen,
	styleen
) => {
	let formData = new FormData();
	// formData.append("service", "admin.php?s=Admin.Garage.NewGarage");
	formData.append("access_token", access_token);
	formData.append("name", name);
	formData.append("address1", address1);
	formData.append("address2", address2);
	formData.append("gps", gps);
	formData.append("onlineTime", onlineTime);
	formData.append("keywords", keywords);
	formData.append("tel", tel);
	formData.append("accountName", accountName);
	formData.append("accountBSB", accountBSB);
	formData.append("accountNum", accountNum);
	formData.append("accountBank", accountBank);
	formData.append("telorder", telorder);
	formData.append("style", style);
	formData.append("follow", follow);
	formData.append("state", state);
	formData.append("pic", pic);
	formData.append("pic2", pic2);
	formData.append("pic3", pic3);
	formData.append("pic4", pic4);
	formData.append("pic5", pic5);
	formData.append("pic6", pic6);
	formData.append("pic7", pic7);
	formData.append("pic8", pic8);
	formData.append("pic9", pic9);
	formData.append("pic10", pic10);
	formData.append("types", type);
	formData.append("menu", menu);
	formData.append("dinnerMenu", dinnerMenu);
	formData.append("drinkMenu", drinkMenu);
	formData.append("veganMenu", veganMenu);
	formData.append("specialMenu", specialMenu);
	formData.append("hide_keywords", hide_keywords);
	formData.append("promotion", promotion);
	formData.append("rules", rules);
	formData.append("nameen", nameen);
	formData.append("styleen", styleen);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Massage.NewMassage",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//修改按摩院信息接口
//Admin.Massage.IdupMassage
export var IdupMassage = async (
	access_token,
	id,
	name,
	address1,
	address2,
	gps,
	onlineTime,
	keywords,
	tel,
	accountName,
	accountBSB,
	accountNum,
	accountBank,
	telorder,
	style,
	follow,
	state,
	pic,
	pic2,
	pic3,
	pic4,
	pic5,
	pic6,
	pic7,
	pic8,
	pic9,
	pic10,
	types,
	menu,
	dinnerMenu,
	drinkMenu,
	veganMenu,
	specialMenu,
	hide_keywords,
	promotion,
	rules,
	nameen,
	styleen
) => {
	let formData = new FormData();
	formData.append("service", "Admin.Massage.IdupMassage");
	formData.append("access_token", access_token);
	formData.append("id", id);

	formData.append("name", name);
	formData.append("address1", address1);
	formData.append("address2", address2);
	formData.append("gps", gps);
	formData.append("onlineTime", onlineTime);
	formData.append("keywords", keywords);
	formData.append("tel", tel);
	formData.append("accountName", accountName);
	formData.append("accountBSB", accountBSB);
	formData.append("accountNum", accountNum);
	formData.append("accountBank", accountBank);
	formData.append("telorder", telorder);
	formData.append("style", style);
	formData.append("follow", follow);
	formData.append("state", state);
	formData.append("pic", pic);
	formData.append("pic2", pic2);
	formData.append("pic3", pic3);
	formData.append("pic4", pic4);
	formData.append("pic5", pic5);
	formData.append("pic6", pic6);
	formData.append("pic7", pic7);
	formData.append("pic8", pic8);
	formData.append("pic9", pic9);
	formData.append("pic10", pic10);
	formData.append("typess", types);
	formData.append("menu", menu);
	formData.append("dinnerMenu", dinnerMenu);
	formData.append("drinkMenu", drinkMenu);
	formData.append("veganMenu", veganMenu);
	formData.append("specialMenu", specialMenu);
	formData.append("hide_keywords", hide_keywords);
	formData.append("promotion", promotion);
	formData.append("rules", rules);
	formData.append("nameen", nameen);
	formData.append("styleen", styleen);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Massage.IdupMassage",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//获取健身房信息
//Admin.Fitness.GetFitness
export var GetFitness = async (access_token) => {
	let formData = new FormData();
	formData.append("service", "Admin.Fitness.GetFitness");
	formData.append("access_token", access_token);
	// formData.append('resid', resid);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Fitness.GetFitness",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//删除健身房信息
//Admin.Fitness.DeleteFitness
export var DeleteFitness = async (access_token, id) => {
	let formData = new FormData();
	formData.append("service", "Admin.Fitness.DeleteFitness");
	formData.append("access_token", access_token);
	formData.append("id", id);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Fitness.DeleteFitness",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//添加健身房+健身房图片接口
//Admin.Fitness.NewFitness
export var NewFitness = async (
	access_token,
	name,
	address1,
	address2,
	gps,
	onlineTime,
	keywords,
	tel,
	accountName,
	accountBSB,
	accountNum,
	accountBank,
	telorder,
	style,
	follow,
	state,
	pic,
	pic2,
	pic3,
	pic4,
	pic5,
	pic6,
	pic7,
	pic8,
	pic9,
	pic10,
	type,
	menu,
	dinnerMenu,
	drinkMenu,
	veganMenu,
	specialMenu,
	hide_keywords,
	promotion,
	rules,
	nameen,
	styleen
) => {
	let formData = new FormData();
	// formData.append("service", "admin.php?s=Admin.Garage.NewGarage");
	formData.append("access_token", access_token);
	formData.append("name", name);
	formData.append("address1", address1);
	formData.append("address2", address2);
	formData.append("gps", gps);
	formData.append("onlineTime", onlineTime);
	formData.append("keywords", keywords);
	formData.append("tel", tel);
	formData.append("accountName", accountName);
	formData.append("accountBSB", accountBSB);
	formData.append("accountNum", accountNum);
	formData.append("accountBank", accountBank);
	formData.append("telorder", telorder);
	formData.append("style", style);
	formData.append("follow", follow);
	formData.append("state", state);
	formData.append("pic", pic);
	formData.append("pic2", pic2);
	formData.append("pic3", pic3);
	formData.append("pic4", pic4);
	formData.append("pic5", pic5);
	formData.append("pic6", pic6);
	formData.append("pic7", pic7);
	formData.append("pic8", pic8);
	formData.append("pic9", pic9);
	formData.append("pic10", pic10);
	formData.append("types", type);
	formData.append("menu", menu);
	formData.append("dinnerMenu", dinnerMenu);
	formData.append("drinkMenu", drinkMenu);
	formData.append("veganMenu", veganMenu);
	formData.append("specialMenu", specialMenu);
	formData.append("hide_keywords", hide_keywords);
	formData.append("promotion", promotion);
	formData.append("rules", rules);
	formData.append("nameen", nameen);
	formData.append("styleen", styleen);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Fitness.NewFitness",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//修改健身房信息接口
//Admin.Fitness.IdupFitness
export var IdupFitness = async (
	access_token,
	id,
	name,
	address1,
	address2,
	gps,
	onlineTime,
	keywords,
	tel,
	accountName,
	accountBSB,
	accountNum,
	accountBank,
	telorder,
	style,
	follow,
	state,
	pic,
	pic2,
	pic3,
	pic4,
	pic5,
	pic6,
	pic7,
	pic8,
	pic9,
	pic10,
	types,
	menu,
	dinnerMenu,
	drinkMenu,
	veganMenu,
	specialMenu,
	hide_keywords,
	promotion,
	rules,
	nameen,
	styleen
) => {
	let formData = new FormData();
	formData.append("service", "Admin.Fitness.IdupFitness");
	formData.append("access_token", access_token);
	formData.append("id", id);

	formData.append("name", name);
	formData.append("address1", address1);
	formData.append("address2", address2);
	formData.append("gps", gps);
	formData.append("onlineTime", onlineTime);
	formData.append("keywords", keywords);
	formData.append("tel", tel);
	formData.append("accountName", accountName);
	formData.append("accountBSB", accountBSB);
	formData.append("accountNum", accountNum);
	formData.append("accountBank", accountBank);
	formData.append("telorder", telorder);
	formData.append("style", style);
	formData.append("follow", follow);
	formData.append("state", state);
	formData.append("pic", pic);
	formData.append("pic2", pic2);
	formData.append("pic3", pic3);
	formData.append("pic4", pic4);
	formData.append("pic5", pic5);
	formData.append("pic6", pic6);
	formData.append("pic7", pic7);
	formData.append("pic8", pic8);
	formData.append("pic9", pic9);
	formData.append("pic10", pic10);
	formData.append("typess", types);
	formData.append("menu", menu);
	formData.append("dinnerMenu", dinnerMenu);
	formData.append("drinkMenu", drinkMenu);
	formData.append("veganMenu", veganMenu);
	formData.append("specialMenu", specialMenu);
	formData.append("hide_keywords", hide_keywords);
	formData.append("promotion", promotion);
	formData.append("rules", rules);
	formData.append("nameen", nameen);
	formData.append("styleen", styleen);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Fitness.IdupFitness",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//添加电影优惠券接口
//Admin.Moviecoupons.NewMoviecoupons
export var NewMoviecoupons = async (
	access_token,
	orderID,
	orderLineID,
	parentVoucherType,
	voucherType,
	serialNumber,
	randomSerial,
	randomDigits,
	barcode,
	bookletIdentifier,
	pin,
	exportedDate,
	userid
) => {
	let formData = new FormData();
	formData.append("service", "Admin.Moviecoupons.NewMoviecoupons");
	formData.append("access_token", access_token);
	formData.append("orderID", orderID);
	formData.append("orderLineID", orderLineID);
	formData.append("parentVoucherType", parentVoucherType);
	formData.append("voucherType", voucherType);
	formData.append("serialNumber", serialNumber);
	formData.append("randomSerial", randomSerial);
	formData.append("randomDigits", randomDigits);
	formData.append("barcode", barcode);
	formData.append("bookletIdentifier", bookletIdentifier);
	formData.append("pin", pin);
	formData.append("exportedDate", exportedDate);
	formData.append("userid", userid);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Moviecoupons.NewMoviecoupons",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			console.log(response.data);
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//获取电影优惠券信息
//Admin.Moviecoupons.GetMoviecoupons
export var GetMoviecoupons = async (access_token) => {
	let formData = new FormData();
	formData.append("service", "Admin.Moviecoupons.GetMoviecoupons");
	formData.append("access_token", access_token);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Moviecoupons.GetMoviecoupons",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//获取电影院信息
//Admin.Movie.GetMovie
export var GetMovie = async (access_token) => {
	let formData = new FormData();
	formData.append("service", "Admin.Movie.GetMovie");
	formData.append("access_token", access_token);
	// formData.append('resid', resid);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Movie.GetMovie",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//删除电影院信息
//Admin.Movie.DeleteMovie
export var DeleteMovie = async (access_token, id) => {
	let formData = new FormData();
	formData.append("service", "Admin.Movie.DeleteMovie");
	formData.append("access_token", access_token);
	formData.append("id", id);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Movie.DeleteMovie",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//添加电影院+电影院图片接口
//Admin.Movie.NewMovie
export var NewMovie = async (
	access_token,
	name,
	address1,
	address2,
	gps,
	onlineTime,
	keywords,
	tel,
	accountName,
	accountBSB,
	accountNum,
	accountBank,
	telorder,
	style,
	follow,
	state,
	pic,
	pic2,
	pic3,
	pic4,
	pic5,
	pic6,
	pic7,
	pic8,
	pic9,
	pic10,
	type,
	menu,
	dinnerMenu,
	drinkMenu,
	veganMenu,
	specialMenu,
	hide_keywords,
	promotion,
	rules,
	nameen,
	styleen
) => {
	let formData = new FormData();
	// formData.append("service", "admin.php?s=Admin.Garage.NewGarage");
	formData.append("access_token", access_token);
	formData.append("name", name);
	formData.append("address1", address1);
	formData.append("address2", address2);
	formData.append("gps", gps);
	formData.append("onlineTime", onlineTime);
	formData.append("keywords", keywords);
	formData.append("tel", tel);
	formData.append("accountName", accountName);
	formData.append("accountBSB", accountBSB);
	formData.append("accountNum", accountNum);
	formData.append("accountBank", accountBank);
	formData.append("telorder", telorder);
	formData.append("style", style);
	formData.append("follow", follow);
	formData.append("state", state);
	formData.append("pic", pic);
	formData.append("pic2", pic2);
	formData.append("pic3", pic3);
	formData.append("pic4", pic4);
	formData.append("pic5", pic5);
	formData.append("pic6", pic6);
	formData.append("pic7", pic7);
	formData.append("pic8", pic8);
	formData.append("pic9", pic9);
	formData.append("pic10", pic10);
	formData.append("types", type);
	formData.append("menu", menu);
	formData.append("dinnerMenu", dinnerMenu);
	formData.append("drinkMenu", drinkMenu);
	formData.append("veganMenu", veganMenu);
	formData.append("specialMenu", specialMenu);
	formData.append("hide_keywords", hide_keywords);
	formData.append("promotion", promotion);
	formData.append("rules", rules);
	formData.append("nameen", nameen);
	formData.append("styleen", styleen);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Movie.NewMovie",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//修改电影院信息接口
//Admin.Movie.IdupMovie
export var IdupMovie = async (
	access_token,
	id,
	name,
	address1,
	address2,
	gps,
	onlineTime,
	keywords,
	tel,
	accountName,
	accountBSB,
	accountNum,
	accountBank,
	telorder,
	style,
	follow,
	state,
	pic,
	pic2,
	pic3,
	pic4,
	pic5,
	pic6,
	pic7,
	pic8,
	pic9,
	pic10,
	types,
	menu,
	dinnerMenu,
	drinkMenu,
	veganMenu,
	specialMenu,
	hide_keywords,
	promotion,
	rules,
	nameen,
	styleen
) => {
	let formData = new FormData();
	formData.append("service", "Admin.Movie.IdupMovie");
	formData.append("access_token", access_token);
	formData.append("id", id);

	formData.append("name", name);
	formData.append("address1", address1);
	formData.append("address2", address2);
	formData.append("gps", gps);
	formData.append("onlineTime", onlineTime);
	formData.append("keywords", keywords);
	formData.append("tel", tel);
	formData.append("accountName", accountName);
	formData.append("accountBSB", accountBSB);
	formData.append("accountNum", accountNum);
	formData.append("accountBank", accountBank);
	formData.append("telorder", telorder);
	formData.append("style", style);
	formData.append("follow", follow);
	formData.append("state", state);
	formData.append("pic", pic);
	formData.append("pic2", pic2);
	formData.append("pic3", pic3);
	formData.append("pic4", pic4);
	formData.append("pic5", pic5);
	formData.append("pic6", pic6);
	formData.append("pic7", pic7);
	formData.append("pic8", pic8);
	formData.append("pic9", pic9);
	formData.append("pic10", pic10);
	formData.append("typess", types);
	formData.append("menu", menu);
	formData.append("dinnerMenu", dinnerMenu);
	formData.append("drinkMenu", drinkMenu);
	formData.append("veganMenu", veganMenu);
	formData.append("specialMenu", specialMenu);
	formData.append("hide_keywords", hide_keywords);
	formData.append("promotion", promotion);
	formData.append("rules", rules);
	formData.append("nameen", nameen);
	formData.append("styleen", styleen);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Movie.IdupMovie",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//查询所有用户反馈接口
//Admin.Userfeedback.GetUserfeedback
export var GetUserfeedback = async (access_token) => {
	let formData = new FormData();
	formData.append("service", "Admin.Userfeedback.GetUserfeedback");
	formData.append("access_token", access_token);
	// formData.append("id", id);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Userfeedback.GetUserfeedback",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

// 新建用户商家关联信息
//Admin.Resuser.AdminnewwResuser
export var AdminnewwResuser = async (
	access_token,
	userid,
	resid,
	money,
	creditid,
	packageid,
	typename,
	piid,
	buynumber,
	servicetype
) => {
	let formData = new FormData();
	formData.append("service", "Admin.Resuser.AdminnewwResuser");
	formData.append("accessToken", access_token);
	formData.append("userid", userid);
	formData.append("resid", resid);
	formData.append("money", money);
	formData.append("creditid", creditid);
	formData.append("packageid", packageid);
	formData.append("typename", typename);
	formData.append("piid", piid);
	formData.append("buynumber", buynumber);
	formData.append("servicetype", servicetype);

	let result = await axios({
		method: "post",
		url: baseURL + "admin.php?s=Admin.Resuser.AdminnewwResuser",
		data: formData,
		config: { headers: { "Content-Type": "multipart/form-data" } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};
