import React, { useState } from 'react';

import { Redirect } from 'react-router-dom';
import blueLogo from '../../images/blue-logo.jpg';
import { createBrowserHistory } from 'history';
import { requestLogin } from '../api/api';

const Login = () => {
    // const [loginData, setLoginData] = useState({});
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLogin, setIsLogin] = useState(false);
    // const [loading, setLoading] =
    const history = createBrowserHistory();

    const handleInputEmail = (e) => {
        const inputEmail = e.target.value;
        setEmail(inputEmail);
    };

    const handleInputPassword = (e) => {
        const inputPassword = e.target.value;
        setPassword(inputPassword);
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        const loginUser = await requestLogin(email, password);
        setIsLogin(true);
        // console.log(loginUser);
        if (
            loginUser.ret == 200 &&
            loginUser.data.resid != null &&
            loginUser.data.member_level !== 44
        ) {
            console.log('correct user');
            localStorage.setItem('current_user', JSON.stringify(loginUser.data));
            localStorage.setItem('login_time', new Date().getTime());
            const a = window.location.href;
            const u = a.split('?');
            const mid = u[u.length - 1]; //Metric id from the URL
            // console.log(mid);
            if (mid.charAt(0) != 'o') {
                history.push('/');
                window.location.reload();
            } else {
                history.push('/order-list?' + mid);
                window.location.reload();
            }
        }
    };

    // console.log(email, password);
    // console.log('login', isLogin);
    return (
        <div>
            {!isLogin && (
                <div className="authincation h-100 p-meddle" id="root">
                    <div className="container h-100">
                        <div className="row justify-content-center h-100 align-items-center">
                            <div className="col-md-6">
                                <div className="authincation-content">
                                    <div className="row no-gutters">
                                        <div className="col-xl-12">
                                            <div className="auth-form">
                                                <img
                                                    src={blueLogo}
                                                    alt=""
                                                    style={{ width: '100%', height: '250px' }}
                                                />
                                                <h4 className="text-center mb-4">
                                                    Sign in your account
                                                </h4>
                                                <form action="" onSubmit={(e) => submitHandler(e)}>
                                                    <div className="form-group">
                                                        <label className="mb-1">
                                                            <strong>Email</strong>
                                                        </label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            // defaultValue="hello@example.com"
                                                            value={email}
                                                            name="Email"
                                                            onChange={handleInputEmail}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="mb-1">
                                                            <strong>Password</strong>
                                                        </label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            value={password}
                                                            // defaultValue="Password"
                                                            name="password"
                                                            onChange={handleInputPassword}
                                                        />
                                                    </div>
                                                    <div className="form-row d-flex justify-content-between mt-4 mb-2">
                                                        <div className="form-group">
                                                            <div className="custom-control custom-checkbox ml-1">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="basic_checkbox_1"
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor="basic_checkbox_1"
                                                                >
                                                                    Remember my preference
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {/* <div className="form-group">
                                       <Link to="/page-forgot-password">
                                          忘记密码
                                       </Link>
                                    </div> */}
                                                    </div>
                                                    <div className="text-center">
                                                        <input
                                                            type="submit"
                                                            value="Sign Me In"
                                                            className="btn btn-primary btn-block"
                                                        />
                                                    </div>
                                                </form>
                                                {/* <div className="new-account mt-3">
                                 <p>
                                    Don't have an account?{" "}
                                    <Link
                                       className="text-primary"
                                       to="/page-register"
                                    >
                                       Sign up
                                    </Link>
                                 </p>
                              </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isLogin && (
                <div className="authincation h-100 p-meddle" id="root">
                    <div className="container h-100">
                        <div className="row justify-content-center h-100 align-items-center">
                            <div className="col-md-6 ml-50">
                                <div className="col-xl-12">
                                    <div
                                        class="spinner-border"
                                        style={{ width: '3rem', height: '3rem' }}
                                        role="status"
                                    >
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

// const mapState = (state) => ({
//     login: state.getIn(['login', 'login']),
// });

// const mapDispatch = (dispatch) => ({
//     handleLogin(account, password) {
//         dispatch(actionCreators.handleLogin(account.value, password.value));
//         // console.log(account.value, password.value);
//     },
// });
export default Login;

// export default Login;
