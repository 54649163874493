import { Button, Dropdown, Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { getOrders } from "../../../../api/api";

const Orders = () => {
	const [data, setData] = useState([]);

	const user = JSON.parse(localStorage.getItem("current_user") || "{}");

	useEffect(() => {
		const getData = async () => {
			const data = await getOrders(user.access_token);
			const d = data.data;
			setData(d);
		};
		getData();
	}, []);
	console.log(data);

	return (
		<div className='col-xl align-self-start'>
			{/* <div
				to='app-profile'
				className=' mb-3 mb-xl-0 d-flex align-items-center justify-content-center light btn-lg btn-block rounded shadow px-4'
			>
				<h5 className='mb-0 text-primary font-w600 '>
					Not Consumed Order
				</h5>
			</div> */}

			<div className='row'>
				<div className='col-lg-12'>
					<div className='card'>
						<div className='card-body'>
							<h5 class='card-title'>Orders (Today)</h5>
							{Array.isArray(data) && data.length > 0 ? (
								data.map((item, i) => (
									<p className='card-trext'>
										{i + 1}. {item.typename}
									</p>
								))
							) : (
								<p className='card-trext'>No Orders</p>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Orders;
